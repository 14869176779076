import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Base64 } from "js-base64";
import { agency_api, profile_update_api } from "../../utils/apis";
import { toast } from "react-toastify";
import { FiArrowLeft } from "react-icons/fi";
import Input from "../../components/inputs/Input";
import AuthBg from "../../components/authbg/AuthBg";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import Button from "../../components/btn/Button";
import { passwordRegex } from "./Signup";

export const LoginNew = () => {
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const aid = searchParams.get("aid");
  const acode = searchParams.get("acode");

  useEffect(() => {
    fetchData();
  }, [aid]);
  const fetchData = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "updatestatus");
      body.append("agencyid", aid);
      body.append("status", 1);
      body.append("acode", acode);
      const responce = await agency_api(body);
      setShowLoader(false);
      if (responce.success) {
        toast.success(responce.message);
        localStorage.clear();
        navigate("/login");
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return <div>{showLoader && <Loader />}</div>;
};

export const EmailUpdate = () => {
  const [showLoader, setShowLoader] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const aid = searchParams.get("aid");
  const oldemail = searchParams.get("oldemail");
  const newemail = searchParams.get("newemail");
  useEffect(() => {
    fetchData();
  }, [aid]);
  const fetchData = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "emailupdate");
      body.append("agencyid", Base64.decode(aid));
      body.append("email", Base64.decode(newemail));
      const responce = await profile_update_api(body);
      setShowLoader(false);
      if (responce.success) {
        toast.success(responce.message);
        localStorage.clear();
        navigate("/login");
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>{showLoader && <Loader />}</div>
    </>
  );
};

export const ForgotPassword = () => {
  const [userData, setUserData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPassConfirm, setShowPassConfirm] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const aid = searchParams.get("aid");
  const eid = searchParams.get("eid");
  const fdate = searchParams.get("fdate");
  const currentDate = new Date();
  const unixTimestamp = Math.floor(currentDate.getTime() / 1000);

  console.log("eid ==", eid);

  const forgot_pass = async () => {
    if (
      !userData?.password ||
      !passwordRegex.test(userData?.password) ||
      !userData.conf_password ||
      !passwordRegex.test(userData?.conf_password) ||
      userData?.conf_password != userData?.password
    ) {
      setUserData({
        ...userData,
        passwordmess: !userData?.password ? "Password is required" : "",
        passwordval: !userData?.password ? true : false,
        passwordval:
          !passwordRegex.test(userData?.password) && userData?.password != ""
            ? true
            : false,
        passwordmess:
          !passwordRegex.test(userData?.password) && userData?.password != ""
            ? "Password must be a combination of @,A,a,1."
            : "",
        conf_passwordmess: !userData?.conf_password
          ? "Confirm Password is required"
          : "",
        conf_passwordval: !userData?.conf_password ? true : false,
        conf_passwordval:
          !passwordRegex.test(userData?.conf_password) &&
          userData?.conf_password != ""
            ? true
            : false,
        conf_passwordmess:
          !passwordRegex.test(userData?.conf_password) &&
          userData?.conf_password != ""
            ? "c Password must be a combination of @,A,a,1."
            : "",
        conf_passwordval:
          userData?.conf_password != userData?.pass ? true : false,
        conf_passwordmess:
          userData?.conf_password != userData?.pasword
            ? "Password not Matched"
            : "",
      });
      return;
    }
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", eid ? "empforgotpwd" : "forgotpassword");
      body.append("agencyid", Base64?.decode(aid));
      if (eid) {
        body.append("emp_code", Base64?.decode(eid));
      }
      body.append("newpassword", userData?.password);
      body.append("fdate", fdate);
      body.append("conformedpassword", userData?.conf_password);
      const responce = await profile_update_api(body);
      setShowLoader(false);
      if (responce.success) {
        toast.success(responce.message);
        localStorage.clear();
        navigate("/login");
      } else if (!responce.success) {
        toast.error(responce.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthBg>
      <div className="login-inner-div" style={{ maxWidth: "450px" }}>
        <div className="sidebar-logo aic jcc ">
          <img src={logoicon} className="s_logo" />
          <img src={logotxt} className="s_logotxt" />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "1rem",
            margin: " 1rem 0",
            padding: " 0 2rem",
          }}
        >
          <div style={{ fontWeight: "bold" }}>Reset Password</div>
          <Input
            placeholder={"Enter Password"}
            value={userData.password}
            type={showPass ? "text" : "password"}
            onChange={(e) => {
              if (e.target.value.length <= 12) {
                setUserData({
                  ...userData,
                  password: e.target.value,
                  passwordmess: "",
                  passwordval: false,
                });
              }
            }}
            onBlur={() => {
              if (
                !passwordRegex.test(userData.password) &&
                userData.password != ""
              ) {
                setUserData({
                  ...userData,
                  passwordmess: "Password must be a combination of @,A,a,1.",
                  passwordval: true,
                });
              }
            }}
            w={"100%"}
            error={userData.passwordmess}
            bcolor={userData.passwordval}
            pass={true}
            setShowPass={setShowPass}
            showPass={showPass}
          />
          <Input
            placeholder={"Enter Confirm Password"}
            value={userData.conf_password}
            type={showPassConfirm ? "text" : "password"}
            onChange={(e) => {
              if (e.target.value.length <= 12) {
                setUserData({
                  ...userData,
                  conf_password: e.target.value,
                  conf_passwordmess: "",
                  conf_passwordval: false,
                });
              }
            }}
            onBlur={() => {
              if (
                !passwordRegex.test(userData.conf_password) &&
                userData.conf_password != ""
              ) {
                setUserData({
                  ...userData,
                  conf_passwordmess:
                    "Confirm Password must be a combination of @,A,a,1.",
                  conf_passwordval: true,
                });
              }
            }}
            w={"100%"}
            error={userData.conf_passwordmess}
            bcolor={userData.conf_passwordval}
            pass={true}
            setShowPass={setShowPassConfirm}
            showPass={showPassConfirm}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0 20px 0",
          }}
        >
          <Button title={"SUBMIT"} onClick={forgot_pass} />
        </div>
        <hr />
      </div>
      {showLoader && <Loader loading={showLoader} />}
    </AuthBg>
  );
};
