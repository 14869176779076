import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import CustomList14 from "./CustomList14";
import { FiChevronRight } from "react-icons/fi";
import { RxFile } from "react-icons/rx";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import { BiBookAlt, BiWallet } from "react-icons/bi";
import { PiSidebarSimpleDuotone } from "react-icons/pi";
import { LuBookOpen } from "react-icons/lu";
import { useSelector } from "react-redux";
import { get_purchase_report_list } from "../../screens/transactionReport/transactionReportSlice";
import store from "../../redux/store";

const TransReportList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const { emp_data, user_type } = useSelector((state) => state.other);

  const transactionReportsMenu = [
    {
      title: "Purchase Report",
      icon: <RxFile color="#6e82a5" size={15} />,
      route: "/transactionReport/PurchaseReport",
      activePath: "/transactionReport/PurchaseReport",
      permission: "PURCHASE_REPORT_PERMISSION",
    },
    {
      title: "Sales Report",
      icon: <RxFile color="#6e82a5" size={15} />,
      route: "/transactionReport/SalesReport",
      activePath: "/transactionReport/SalesReport",
      permission: "SALES_REPORT_PERMISSION",
    },
    {
      title: "NC/DBC/RC/TV Report",
      icon: <RxFile color="#6e82a5" size={15} />,
      route: "/transactionReport/NDRTReport",
      activePath: "/transactionReport/NDRTReport",
      permission: "NCDBC_REPORT_PERMISSION",
    },
    {
      title: "Stock Report",
      icon: <RxFile color="#6e82a5" size={15} />,
      route: "/transactionReport/StockReport",
      activePath: "/transactionReport/StockReport",
      permission: "STOCK_REPORT_PERMISSION",
    },
    {
      title: "Bank Book",
      icon: <BiBookAlt color="#6e82a5" size={15} />,
      route: "/transactionReport/BankBook",
      activePath: "/transactionReport/BankBook",
      permission: "BANK_BOOK_PERMISSION",
    },
    {
      title: "Ledger",
      icon: <PiSidebarSimpleDuotone color="#6e82a5" size={15} />,
      route: "/transactionReport/Ledger",
      activePath: "/transactionReport/Ledger",
      permission: "LEDGER_REPORT_PERMISSION",
    },
    {
      title: "Receivables",
      icon: <BsFileEarmarkArrowDown color="#6e82a5" size={15} />,
      route: "/transactionReport/Receivables",
      activePath: "/transactionReport/Receivables",
      permission: "RECEIVABLE_PERMISSION",
    },
    {
      title: "Payables",
      icon: <BiWallet color="#6e82a5" size={15} />,
      route: "/transactionReport/Payables",
      activePath: "/transactionReport/Payables",
      permission: "PAYABLE_PERMISSION",
    },
    {
      title: "Payment & Receipts",
      icon: <BiWallet color="#6e82a5" size={15} />,
      route: "/transactionReport/PaymentReceipts",
      activePath: "/transactionReport/PaymentReceipts",
      permission: "PAYMENT_RECEIPT_REPORT_PERMISSION",
    },
  ];

  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };

  return (
    <>
      {permission_handle(transactionReportsMenu?.map((v) => v?.permission)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "Transactions")
                ? pre.filter((v) => v != "Transactions")
                : ["Transactions"]
            )
          }
        >
          <div className="list-icon">
            <LuBookOpen color="#6e82a5" size={18} />
          </div>
          <div className="list-title fs15">Transaction Reports</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused.includes("Transactions")
                  ? "rotate-down"
                  : "rotate-right"
              }
            />
          </div>
        </div>
      )}

      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {transactionReportsMenu?.map((v, i) => (
              <>
                {permission_handle([v?.permission]) && (
                  <CustomList14
                    key={i}
                    title={v?.title}
                    style={{
                      backgroundColor: path == v?.route ? "white" : "",
                    }}
                    type={v?.permission}
                    onClick={() => {
                      navigate(v?.route);
                      close();
                    }}
                    icon={v?.icon}
                  />
                )}
              </>
            ))}
            {/* <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/PurchaseReport" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/PurchaseReport");
                close();
              }}
              title={"Purchase Report"}
              icon={<RxFile color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/SalesReport" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/SalesReport");
                close();
              }}
              title={"Sales Report"}
              icon={<RxFile color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/NDRTReport" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/NDRTReport");
                close();
              }}
              title={"NC/DBC/RC/TV Report"}
              icon={<RxFile color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/StockReport" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/StockReport");
                close();
              }}
              title={"Stock Report"}
              icon={<RxFile color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/BankBook" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/BankBook");
                close();
              }}
              title={"Bank Book"}
              icon={<BiBookAlt color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/Ledger" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/Ledger");
                close();
              }}
              title={"Ledger"}
              icon={<PiSidebarSimpleDuotone color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/Receivables" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/Receivables");
                close();
              }}
              title={"Receivables"}
              icon={<BsFileEarmarkArrowDown color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/Payables" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/Payables");
                close();
              }}
              title={"Payables"}
              icon={<BiWallet color="#6e82a5" size={15} />}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/transactionReport/PaymentReceipts" ? "white" : "",
              }}
              onClick={() => {
                navigate("/transactionReport/PaymentReceipts");
                close();
              }}
              title={"Payment & Receipts"}
              icon={<BiWallet color="#6e82a5" size={15} />}
            /> */}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default TransReportList;
