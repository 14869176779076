import React, { useState } from "react";
import { FiChevronRight, FiShoppingBag } from "react-icons/fi";
import { LuCalculator } from "react-icons/lu";
import { VscFiles } from "react-icons/vsc";
import { AnimatePresence, motion } from "framer-motion";
import CustomList from "./CustomList";
import { useNavigate } from "react-router-dom";
import { BsGraphUp } from "react-icons/bs";
import { MdOutlineDesktopWindows } from "react-icons/md";
import { useSelector } from "react-redux";

const TransactionList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;
  const { user_type, emp_data } = useSelector((state) => state.other);

  const transactionsMenu = [
    {
      title: "Purchases",
      icon: <FiShoppingBag size={15} color="#6e82a5" />,
      children: [
        {
          title: "Vendor PO",
          route: "/transactions/venderpurchase",
          permission: "VENDOR_PO_PERMISSION",
          activePath: "/transactions/venderpurchase",
        },
        {
          title: "Cylinder Purchases",
          route: "/transactions/Cylinder-Purchases",
          permission: "CP_PERMISSION",
          activePath: "/transactions/Cylinder-Purchases",
        },
        {
          title:
            (OMC == 1 && "NFR Purchase") ||
            (OMC == 2 && "ARB Purchase") ||
            (OMC == 3 && "BLPG Purchase"),
          route: "/transactions/BLPG/ARB/NFR-Purchases",
          permission: "ARB_PURCHASE_PERMISSION",
          activePath: "/transactions/BLPG/ARB/NFR-Purchases",
        },
        {
          title: "Other Purchases",
          route: "/transactions/ExpenditureOtherPurchase",
          permission: "OP_PERMISSION",
          activePath: [
            "/transactions/ExpenditureOtherPurchase",
            "/transactions/OtherPurchase",
          ],
        },
        {
          title: "Defective/Pur return",
          route: "/transactions/Defective/Pur/Return",
          permission: "DEF_PUR_PERMISSION",
          activePath: "/transactions/Defective/Pur/Return",
        },
      ],
    },
    {
      title: "Sales",
      icon: <BsGraphUp size={15} color="#6e82a5" />,
      children: [
        {
          title: "Quotations",
          route: "/transactions/Quotations",
          activePath: "/transactions/Quotations",
          permission: "QUOTATIONS_PERMISSION",
        },
        {
          title: "Customer PO",
          route: "/transactions/PurchaseSales",
          activePath: "/transactions/PurchaseSales",
          permission: "CUSTOMER_PO_PERMISSION",
        },
        {
          title: "Domestic CYL Sales",
          route: "/transactions/DomestiCylSale",
          activePath: "/transactions/DomestiCylSale",
          permission: "DOM_PERMISSION",
        },
        {
          title: "Commercial CYL Sales",
          route: "/transactions/CommercialCylSale",
          activePath: "/transactions/CommercialCylSale",
          permission: "COM_PERMISSION",
        },
        {
          title:
            (OMC == 1 && "NFR Sales") ||
            (OMC == 2 && "ARB Sales") ||
            (OMC == 3 && "BLPG Sales"),
          route: "/transactions/ARBSale",
          activePath: "/transactions/ARBSale",
          permission: "BLPG_PERMISSION",
        },
        {
          title: "Delivery Challan",
          route: "/transactions/DeliveryChallan",
          activePath: "/transactions/DeliveryChallan",
          permission: "DC_PERMISSION",
        },
        {
          title: "Sales Return",
          route: "/transactions/SalesReturn",
          activePath: "/transactions/SalesReturn",
          permission: "SALES_RETURN_PERMISSION",
        },
      ],
    },
    {
      title: "NC / DBC / RC / TV",
      icon: <MdOutlineDesktopWindows size={15} color="#6e82a5" />,
      children: [
        {
          title: "NC / DBC",
          route: "/transactions/ncdbctrans",
          activePath: "/transactions/ncdbctrans",
          permission: "NC_PERMISSION",
        },
        {
          title: "ITV / RC",
          route: "/transactions/itvrctrans",
          activePath: "/transactions/itvrctrans",
          permission: "RC_PERMISSION",
        },
        {
          title: "OTV / TTV / TV",
          route: "/transactions/otvttvtvtrans",
          activePath: "/transactions/otvttvtvtrans",
          permission: "TV_PERMISSION",
        },
      ],
    },
    {
      title: "Expenses",
      icon: <LuCalculator size={15} color="#6e82a5" />,
      route: "/transactions/OtherPurchase",
      permission: "EXP_PERMISSION",
      activePath: "/transactions/OtherPurchase",
      state: {
        itc: "",
        showData: 2,
      },
    },
  ];

  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };
  const extractPermissions = (menu) => {
    let permissions = [];

    menu.forEach((item) => {
      if (item.permission) {
        permissions.push(item.permission);
      }
      if (item.children && item.children.length > 0) {
        permissions = permissions.concat(
          item.children.map((child) => child.permission)
        );
      }
    });

    return permissions;
  };

  return (
    <>
      {permission_handle(extractPermissions(transactionsMenu)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "Transactions")
                ? pre.filter((v) => v != "Transactions")
                : ["Transactions"]
            )
          }
        >
          <div className="list-icon">
            <VscFiles size={18} color="#6e82a5" />
          </div>
          <div className="list-title fs15">Transactions</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused.includes("Transactions")
                  ? "rotate-down"
                  : "rotate-right"
              }
            />
          </div>
        </div>
      )}
      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <>
              {permission_handle(
                transactionsMenu[0]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Purchases")
                        ? pre.filter((v) => v != "Purchases")
                        : [...pre, "Purchases"]
                    )
                  }
                >
                  <div className="list-icon">
                    <FiShoppingBag size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">Purchases</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("Purchases")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}
              <AnimatePresence>
                {focused.includes("Purchases") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {transactionsMenu[0]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            key={i}
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                    {/* <CustomList
                      title={"Vendor PO"}
                      style={{
                        backgroundColor:
                          path == "/transactions/venderpurchase" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/venderpurchase");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Cylinder Purchases"}
                      style={{
                        backgroundColor:
                          path == "/transactions/Cylinder-Purchases"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/Cylinder-Purchases");
                        close();
                      }}
                    />
                    <CustomList
                      title={
                        (OMC == 1 && "NFR Purchase") ||
                        (OMC == 2 && "ARB Purchase") ||
                        (OMC == 3 && "BLPG Purchase")
                      }
                      style={{
                        backgroundColor:
                          path == "/transactions/BLPG/ARB/NFR-Purchases"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/BLPG/ARB/NFR-Purchases");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Other Purchases"}
                      style={{
                        backgroundColor:
                          path == "/transactions/ExpenditureOtherPurchase" ||
                          path == "/transactions/OtherPurchase"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/ExpenditureOtherPurchase");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Defective/Pur return"}
                      style={{
                        backgroundColor:
                          path == "/transactions/Defective/Pur/Return"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/Defective/Pur/Return");
                        close();
                      }} 
                    />
                       */}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              {permission_handle(
                transactionsMenu[1]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Sales")
                        ? pre.filter((v) => v != "Sales")
                        : [...pre, "Sales"]
                    )
                  }
                >
                  <div className="list-icon">
                    <BsGraphUp size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">Sales</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("Sales")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}
              <AnimatePresence>
                {focused.includes("Sales") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {transactionsMenu[1]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            key={i}
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                    {/* <CustomList
                      title={"Quotations"}
                      style={{
                        backgroundColor:
                          path == "/transactions/Quotations" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/Quotations");
                        close();
                      }}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/PurchaseSales" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/PurchaseSales");
                        close();
                      }}
                      title={"Customer PO"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/DomestiCylSale" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/DomestiCylSale");
                        close();
                      }}
                      title={"Domestic CYL Sales"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/CommercialCylSale"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/CommercialCylSale");
                        close();
                      }}
                      title={"Commercial CYL Sales"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/ARBSale" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/ARBSale");
                        close();
                      }}
                      title={
                        (OMC == 1 && "NFR Sales") ||
                        (OMC == 2 && "ARB Sales") ||
                        (OMC == 3 && "BLPG Sales")
                      }
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/DeliveryChallan"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/DeliveryChallan");
                        close();
                      }}
                      title={"Delivery Challan"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/SalesReturn" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/SalesReturn");
                        close();
                      }}
                      title={"Sales Return"}
                    /> */}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              {permission_handle(
                transactionsMenu[1]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "NC / DBC / RC / TV")
                        ? pre.filter((v) => v != "NC / DBC / RC / TV")
                        : [...pre, "NC / DBC / RC / TV"]
                    )
                  }
                >
                  <div className="list-icon">
                    <MdOutlineDesktopWindows size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">NC / DBC / RC / TV</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("NC / DBC / RC / TV")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}
              <AnimatePresence>
                {focused.includes("NC / DBC / RC / TV") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {transactionsMenu[2]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            key={i}
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                    {/* <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/ncdbctrans" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/ncdbctrans");
                        close();
                      }}
                      title={"NC / DBC"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/itvrctrans" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/itvrctrans");
                        close();
                      }}
                      title={"ITV / RC"}
                    />
                    <CustomList
                      style={{
                        backgroundColor:
                          path == "/transactions/otvttvtvtrans" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/transactions/otvttvtvtrans");
                        close();
                      }}
                      title={"OTV / TTV /TV"}
                    /> */}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            {/* <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/transactions/OtherPurchase" ? "white" : "",
              }}
            >
              <div className="list-icon">
                <LuCalculator size={15} color="#6e82a5" />
              </div>
              <div
                className="list-title fs14"
                onClick={() => {
                  // navigate("/transactions/ExpenditureOtherPurchase");
                  navigate("/transactions/OtherPurchase", {
                    state: {
                      itc: "",
                      showData: 2,
                    },
                  });
                  close();
                }}
              >
                Expenses
              </div>
            </div> */}
            {transactionsMenu
              ?.filter((v) => v?.permission)
              .map((v, i) => (
                <>
                  {permission_handle([v?.permission]) && (
                    <div
                      key={i}
                      className="list-main"
                      style={{
                        backgroundColor: path == v?.route ? "white" : "",
                      }}
                    >
                      <div className="list-icon">{v?.icon}</div>
                      <div
                        className="list-title fs14"
                        onClick={() => {
                          navigate(v?.route, { state: v?.state });
                          close();
                        }}
                      >
                        {v?.title}
                      </div>
                    </div>
                  )}
                </>
              ))}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default TransactionList;
