import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import { IoArrowBack } from "react-icons/io5";
import Input from "../../../components/inputs/Input";
import Modal from "../../../components/modal/Modal";
import Dropdown from "../../../components/inputs/Dropdown";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import {
  get_bank_master_list,
  get_equipment_list,
  get_nc_dbc_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import Table from "../../../components/table/Table";
import { BankCodeFinder, DeleteIcon } from "../../../utils/utils";
import {
  DateValidator2,
  gst_calculate,
  UnitFunction,
} from "../../../utils/validation";
import moment from "moment";
import Loader from "../../../components/Loader";
import SmallModal from "../../../components/modal/SmallModal";
import PageLoading from "../../../components/PageLoading";
import NoData from "../../../components/NoData";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import {
  get_ncdbc_data_list,
  get_otv_ttv_data_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { otv_ttv_data_API } from "../../../utils/apis";
import { DropInput } from "../../../components/inputs/DropInput";
import { Alerts } from "../../../components/Alerts/Alerts";
import ReactToPrint from "react-to-print";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import { UOMData } from "../../../utils/data";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";
// const tax_invoice_table_header = [
//   "S No.",
//   "PRODUCT",
//   "HSN/SAC",
//   "QTY",
//   "UOM",
//   "RATE",
//   "IMPREST AMOUNT",
//   "ADMIN CHARGES",
//   "TAXABLE",
//   "GST %	",
//   "TOTAL",
// ];

const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  { title: "Admin Amount", cols: false },
  { title: "Imprest Amount", cols: false },
  { title: "Amount", cols: false },
];

const emptyData = {
  product_code: "",
  prod_name: "",
  cylinder_no: "",
  cylinder_deposit: "",
  regulator_deposit: "",
  regulator_no: "",
  regulator_deposit: "",
  imprest_amt: "",
  imprest_gst_p: "",
  admin_charge: "",
  gst_amount: "",
  total_amt: "",
};

export const OTVTTVTV = () => {
  const previewRef = useRef(null);
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [addDataHead, setAddDataHead] = useState({});
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [entries, setEntries] = useState("5");
  const [DeleteId, setDeleteId] = useState("");
  const [viewItem, setViewItem] = useState({});
  const [openView, setOpenView] = useState(false);
  const { staff_list, equipment_list, bank_master_list } = useSelector(
    (state) => state.masterdata
  );

  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);

  const { user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const { godown_address_list } = useSelector((state) => state.profile);
  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };

  const {
    otv_ttv_data_list,
    otv_ttv_data_loading,
    transition_complete_list,
    nc_dbc_data_list,
  } = useSelector((state) => state.transactions);
  const { day_end_date } = useSelector((state) => state.profile);

  useEffect(() => {
    store.dispatch(get_staff_list());
    store.dispatch(get_nc_dbc_list());
    store.dispatch(get_equipment_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_ncdbc_data_list());
    store.dispatch(get_bank_master_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_otv_ttv_data_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const handleView = (item) => {
    setViewItem(item);
    setOpenView(true);
  };

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }
      const response = await otv_ttv_data_API(body);
      setShowLoader(false);
      if (response?.success) {
        setPageNo(
          otv_ttv_data_list?.length < 2 && pageNo != "1" ? pageNo - 1 : pageNo
        );
        store.dispatch(
          get_otv_ttv_data_list({
            page:
              otv_ttv_data_list?.length < 2 && pageNo != "1"
                ? pageNo - 1
                : pageNo,
            limit: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate_new = (type) => {
    const data = [];
    const data1 = DateValidator2(
      moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Invoice Date Cant be below as a day end date ${day_end_date}`
    );
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length == 0)
    ) {
      data.push("Please enter INVOICE DATE");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      (type == "invoicenumber" || !type) &&
      (!addDataHead?.customer_name ||
        (addDataHead?.customer_name && addDataHead?.customer_name?.length == 0))
    ) {
      data.push("Please Enter CUSTOMER NO/NAME and then fetch data");
    }
    return data;
  };
  const fetch_deposit = async () => {
    const data = validate_new("fetchgst");
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "fetchgst");
        body.append("agencyid", user_id);
        body.append("invoice_date", addDataHead?.inv_date);
        body.append("PROD_CODE", addDataHead?.product_code);
        const response = await otv_ttv_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddDataHead({
            ...addDataHead,
            imprest_gst_p: 18,
            admin_charge_gst: 18,
            unit_rate: response?.product?.[0]?.BASE_PRICE,
            p_gst: response?.product?.[0]?.GSTP,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const fetch_invoice = async () => {
    const data = validate_new("invoicenumber");
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "invoicenumber");
        body.append("agencyid", user_id);
        body.append("date", addDataHead?.inv_date);
        body.append("customer", addDataHead?.customer_id);
        const response = await otv_ttv_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddDataHead({
            ...addDataHead,
            inv_no: response?.invoice_number,
            inv_dis: true,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const validate_calculate = () => {
    const data = [];
    // const data = [...validate_new()];

    addDataHead?.items.forEach((value, index) => {
      if (
        !value?.product_code ||
        (value?.product_code && value?.product_code?.length == 0)
      ) {
        data.push("Please Select Product");
      }
      if (
        !value?.imprest_amt ||
        (value?.imprest_amt && value?.imprest_amt?.length == 0)
      ) {
        data.push("Please Enter Imprest Amount ");
      }
      if (
        !value?.cylinder_no ||
        (value?.cylinder_no && value?.cylinder_no?.length == 0)
      ) {
        data.push("Please Enter Cylinder Quantity");
      }
      // else if (value?.cylinder_no > 2 || value?.cylinder_no < 0) {
      //   data.push("NO. OF CYL Must be 1 or 2");
      // }

      //  else if (value?.regulator_no > 2) {
      //   data.push("NO. OF REGULATORS Must Be 0 or 1");
      // }
      if (
        !value?.cylinder_deposit ||
        (value?.cylinder_deposit && value?.cylinder_deposit?.length == 0)
      ) {
        data.push("Please Enter Cylinder Deposit");
      } else if (
        value?.cylinder_deposit > 1000000 ||
        value?.cylinder_deposit <= 0
      ) {
        data.push("Please Enter Valid CYLINDER DEPOSIT and then CALCULATE");
      }
      if (!addDataHead?.order_no) {
        if (
          !value?.regulator_no ||
          (value?.regulator_no && value?.regulator_no?.length < 0)
        ) {
          data.push("Please Enter Regulator Quantity");
        }
        if (
          !value?.regulator_deposit ||
          (value?.regulator_deposit && value?.regulator_deposit < 0)
        ) {
          data.push("Please enter REGULATOR DEPOSIT.Enter 0 if not available.");
        } else if (value?.regulator_no == 0 && value?.regulator_deposit > 0) {
          data.push("REGULATOR DEPOSIT must be 0 for Zero NO OF REGULATORS.");
        } else if (
          value?.regulator_deposit > 100000 ||
          value?.regulator_deposit < 0
        ) {
          data.push("REGULATOR DEPOSIT must be grater than 0.");
        }
      }

      if (
        !value?.admin_charge ||
        (value?.admin_charge && value?.admin_charge?.length == 0)
      ) {
        data.push("Please select Admin Charges.");
      }
    });

    return data;
  };

  const validate = () => {
    const data = [];
    data.push(...validate_new());
    if (
      !addDataHead?.inv_no ||
      (addDataHead?.inv_no && addDataHead?.inv_no?.length == 0)
    ) {
      data.push("Please Fetch Invoice Number");
    }

    if (
      !addDataHead?.staff_code ||
      (addDataHead?.staff_code && addDataHead?.staff_code?.length == 0)
    ) {
      data.push("Please Select Staff Name");
    }
    // New Logics start here
    if (
      !addDataHead?.cust_reg ||
      (addDataHead?.cust_reg && addDataHead?.cust_reg?.length == 0)
    ) {
      data.push("Please Select Customer Registration");
    } else if (
      addDataHead?.cust_reg == 1 &&
      (!addDataHead?.customer_id ||
        (addDataHead?.customer_id && addDataHead?.customer_id?.length == 0))
    ) {
      data.push("Please Enter Customer Name");
    } else if (
      addDataHead?.cust_reg == 0 &&
      (!addDataHead?.customer_name ||
        (addDataHead?.customer_name && addDataHead?.customer_name?.length == 0))
    ) {
      data.push("Please Enter Customer Name");
    }
    if (
      !addDataHead?.payment_terms ||
      (addDataHead?.payment_terms && addDataHead?.payment_terms?.length == 0)
    ) {
      data.push("Please Select Payment terms");
    }
    if (addDataHead?.payment_terms == 1 || addDataHead?.payment_terms == 3) {
      if (
        !addDataHead?.cash_received ||
        (addDataHead?.cash_received && addDataHead?.cash_received?.length == 0)
      ) {
        data.push("Please Enter Cash Received Amount ");
      }
      if (addDataHead?.payment_terms == "3" && addDataHead?.online_rcvd > 0) {
        if (
          !addDataHead?.online_rcvd ||
          (addDataHead?.online_rcvd && addDataHead?.online_rcvd?.length == 0)
        ) {
          data.push("Please enter AMOUNT RECEIVED ONLINE");
        }
        if (
          addDataHead?.online_rcvd > 0 &&
          (!addDataHead?.bank_acc ||
            (addDataHead?.bank_acc && addDataHead?.bank_acc?.length == 0))
        ) {
          data.push("Please Select Bank Account Number");
        }
        if (
          addDataHead?.online_rcvd > 0 &&
          (!addDataHead?.transaction_id ||
            (addDataHead?.transaction_id &&
              addDataHead?.transaction_id?.length == 0))
        ) {
          data.push("Please Enter Transaction ID");
        }
        if (
          (!addDataHead?.cash_recieved_from ||
            addDataHead?.cash_recieved_from == "") &&
          addDataHead?.cash_received > 0
        ) {
          data.push("Please Select a Cash Recieved From");
        }
      }
    }
    // New Logics end here

    if (
      godown_address_list?.length > 1 &&
      (!addDataHead?.godown ||
        (addDataHead?.godown && addDataHead?.godown?.length === 0))
    ) {
      data.push("Please Select Received Godown Address");
    }
    data.push(...validate_calculate());

    if (
      !addDataHead?.inv_amount ||
      (addDataHead?.inv_amount && addDataHead?.inv_amount?.length == 0)
    ) {
      data.push("Please Click on Calculate Button First");
    } else if (addDataHead?.inv_amount == 0) {
      data.push("Invoice Amount Always greater then zero");
    }

    return data;
  };

  const calculate = async () => {
    const data = validate_calculate();
    if (data?.length == 0) {
      let total_value = 0; // Use `let` instead of `const` to allow reassignment
      const updatedItems = addDataHead?.items?.map((value, index) => {
        const imprest_amt = Number(value?.imprest_amt);
        const cylinder_deposit = Number(value?.cylinder_deposit);
        const regulator_deposit = Number(value?.regulator_deposit);
        const admin_charge = Number(value?.admin_charge);
        const gst_amount = Number(value?.gst_amount);

        const total =
          Number(cylinder_deposit) +
          Number(regulator_deposit) -
          (Number(imprest_amt) + Number(admin_charge) + Number(gst_amount));

        total_value += total;

        return {
          ...value,
          total_amt: total,
          igst: addDataHead?.c_type === "isp" ? Number(gst_amount) : 0,
          sgst: addDataHead?.c_type !== "isp" ? Number(gst_amount / 2) : 0,
          cgst: addDataHead?.c_type !== "isp" ? Number(gst_amount / 2) : 0,
        };
      });

      setAddDataHead({
        ...addDataHead,
        items: updatedItems,
        inv_amount: total_value,
        cash_received:
          addDataHead?.cash_received &&
          addDataHead?.cash_received != 0 &&
          addDataHead?.payment_terms != 1
            ? addDataHead?.cash_received
            : Number(total_value),
        online_rcvd:
          addDataHead?.cash_received && addDataHead?.cash_received != 0
            ? Number(total_value - addDataHead?.cash_received).toFixed(2)
            : "0.00",
      });
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const add_items = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("SR_NO", addDataHead?.inv_no);
        body.append("TV_DATE", addDataHead?.inv_date);
        body.append(
          "godown_id",
          addDataHead?.godown || godown_address_list?.[0]?.ID || 0
        );

        body.append("CUSTOMER_NO", addDataHead?.customer_id || "");
        body.append("CUSTOMER_NAME", addDataHead?.customer_name);
        body.append("STAFF_ID", addDataHead?.staff_code);
        body.append("TV_AMOUNT", addDataHead?.inv_amount);
        body.append("TV_CAT", addDataHead?.category);

        body.append("CUST_REG", addDataHead?.cust_reg || "");
        body.append("INV_REF_NO", addDataHead?.order_no || "");
        body.append("PAYMENT_TERMS", addDataHead?.payment_terms || "");
        if (addDataHead?.payment_terms != "2") {
          body.append("CASH_RECEIVED", addDataHead?.cash_received || "");
          if (addDataHead?.payment_terms == "3") {
            body.append(
              "AMOUNT_RECEIVED_ONLINE",
              addDataHead?.online_rcvd || ""
            );
            body.append("TRANSACTION_ID", addDataHead?.transaction_id || "");
            body.append("BANK_ID", addDataHead?.bank_acc || "");
            body.append(
              "CASH_RECEIVED_FROM",
              addDataHead?.cash_recieved_from || ""
            );
          }
        }
        addDataHead?.items?.forEach((v) => {
          body.append("PROD_CODE[]", v?.product_code);
          body.append("NO_OF_CYL[]", v?.cylinder_no);
          body.append("NO_OF_REG[]", v?.regulator_no);
          body.append("CYL_DEPOSIT[]", v?.cylinder_deposit);
          body.append("REG_DEPOSIT[]", v?.regulator_deposit);
          body.append("ADMIN_CHARGES[]", v?.admin_charge);
          body.append("IMP_AMOUNT[]", v?.imprest_amt);
          body.append("IGST_AMOUNT[]", v?.igst);
          body.append("CGST_AMOUNT[]", v?.cgst);
          body.append("SGST_AMOUNT[]", v?.sgst);
          body.append("TOTAL_AMOUNT[]", v?.total_amt);
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }

        const response = await otv_ttv_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setIsAdd(false);
          toast(response.message);
          setAddDataHead({});
          store.dispatch(get_otv_ttv_data_list(obj));
        } else if (!response.success) {
          setOpen1(true);
          setMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  return (
    <div className="container">
      <PageHeading
        title={"OTV / TTV /TV"}
        right={
          <>
            <AddButton
              onClick={() => {
                setIsAdd(true);
                setAddDataHead({ ...addDataHead, items: [{}] });
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/_vkck33H-zE",
                "https://youtu.be/0iV0CiU67xc",
              ]}
            />
          </>
        }
      />

      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {otv_ttv_data_loading ? (
          <PageLoading />
        ) : otv_ttv_data_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {otv_ttv_data_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">
                    {v?.CUSTOMER || v?.CUSTOMER_NAME}{" "}
                  </div>
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${v?.CUSTOMER_NAME}-${v?.SR_NO}`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.TV_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Invoice Amt."} value={v?.TV_AMOUNT} />
                  <RowTxt title={"Godown Address "} value={v?.GODOWN_NAME} />
                  <RowTxt title={"Staff Name"} value={v?.STAFF_NAME} />
                  <RowTxt
                    title={"Customer Type "}
                    value={
                      v?.CUST_REG == "1"
                        ? "Registered Customer"
                        : v?.CUST_REG == ""
                        ? "Un Registered Customer"
                        : ""
                    }
                  />

                  <RowTxt
                    title={"Payment Terms "}
                    value={
                      v?.PAYMENT_TERMS == "1"
                        ? "Cash"
                        : v?.PAYMENT_TERMS == "2"
                        ? "Credit"
                        : v?.PAYMENT_TERMS == "3"
                        ? "Online"
                        : ""
                    }
                  />
                  {v?.PAYMENT_TERMS != "2" && (
                    <>
                      <RowTxt
                        title={"Cash Recieved"}
                        value={v?.CASH_RECEIVED}
                      />
                      {v?.PAYMENT_TERMS == "3" && (
                        <>
                          <RowTxt
                            title={"Online Recieved"}
                            value={v?.AMOUNT_RECEIVED_ONLINE}
                          />

                          {/* <RowTxt title={"Godown"} value={v?.GODOWN_NAME} /> */}
                          {v?.AMOUNT_RECEIVED_ONLINE == "0.00" ? (
                            ""
                          ) : (
                            <>
                              <RowTxt
                                title={"Bank Account"}
                                value={v?.BANK_ACC_NO}
                              />
                              <RowTxt
                                title={"Transaction Id"}
                                value={v?.TRANSACTION_ID}
                              />
                              <RowTxt
                                title={"RCVD From"}
                                value={
                                  v?.CASH_RECEIVED_FROM == "1"
                                    ? "Phonepay"
                                    : v?.CASH_RECEIVED_FROM == "2"
                                    ? "GPay"
                                    : v?.CASH_RECEIVED_FROM == "3"
                                    ? "Paytm"
                                    : v?.CASH_RECEIVED_FROM == "4"
                                    ? "Cred"
                                    : v?.CASH_RECEIVED_FROM == "5"
                                    ? "Others"
                                    : ""
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "1rem",
                  }}
                >
                  {v?.STATUS_FLAG == "0" ? (
                    <DeleteIcon
                      onClick={() => {
                        setOpen(true);
                        setDeleteId(v.ID);
                      }}
                    />
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    onClick={() => handleView(v)}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"OTV / TTV /TV"}
          body={
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <div className="flex aic gap10 credit-limit">
                  <Input
                    label={"INVOICE NUMBER"}
                    placeholder={"INVOICE NUMBER"}
                    value={addDataHead.inv_no}
                    disabled={true}
                  />
                  <Input
                    label={"INVOICE DATE"}
                    placeholder={"INVOICE DATE"}
                    type={"date"}
                    value={addDataHead?.inv_date}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setAddDataHead({
                          ...addDataHead,
                          inv_date: e.target.value,
                        });
                      }
                    }}
                  />
                  <Dropdown
                    width2=""
                    label={"STAFF NAME"}
                    value={addDataHead?.staff_code}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        staff_code: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value=""> SELECT</option>
                        {staff_list?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {v?.EMP_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <Dropdown
                    width2=""
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        cust_reg: e.target.value,
                        payment_terms: e.target.value == "1" ? 2 : "",
                        dis_payment: e.target.value == "1" ? true : false,
                        customer_id: "",
                        customer_name: "",
                        cash_received: "",
                        cash_recieved_from: "",
                        online_rcvd: "",
                        bank_acc: "",
                        order_no: "",
                        godown: "",
                        items: [emptyData],
                      });
                    }}
                    value={addDataHead?.cust_reg}
                    label={"CUSTOMER REG."}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        <option value={"1"}>YES</option>
                        <option value={"0"}>NO</option>
                      </>
                    }
                  />

                  <DropInput
                    label={"CUST NO / CUST NAME"}
                    id="custvend"
                    placeholder={"CUST NO / CUST NAME"}
                    value={addDataHead?.customer_name}
                    onChange={(e) => {
                      const data = transition_complete_list?.Customer?.filter(
                        (v) => v?.ID == e.target.value
                      );
                      setAddDataHead({
                        ...addDataHead,

                        order_no: "",
                        godown: "",
                        c_type:
                          data?.length > 0 ? data[0]?.connection_type : "lp",
                        items: [emptyData],
                        customer_name:
                          data?.length > 0 ? data[0]?.cvo_name : e.target.value,
                        customer_id: data?.length > 0 ? e.target.value : "",
                        c_type: data[0]?.connection_type,
                      });
                    }}
                    list={
                      addDataHead?.cust_reg == "1"
                        ? transition_complete_list?.Customer?.map((v, i) => (
                            <option key={i} value={v?.ID}>
                              {v?.cvo_name?.toUpperCase()}
                            </option>
                          ))
                        : ""
                    }
                  />

                  {addDataHead?.cust_reg == "1" && (
                    <Dropdown
                      width2=""
                      label={"NC ORDER NO."}
                      value={addDataHead?.order_no}
                      onChange={(e) => {
                        const data = nc_dbc_data_list?.filter(
                          (v, i) =>
                            v?.CUSTOMER_ID == addDataHead?.customer_id &&
                            v?.SR_NO == e.target.value
                        );
                        setAddDataHead({
                          ...addDataHead,
                          order_no: e.target.value,
                          godown: data?.length > 0 ? data[0]?.GODOWN_ID : "",
                          items: [emptyData],
                        });
                      }}
                      options={
                        <>
                          <option value=""> SELECT</option>
                          {nc_dbc_data_list
                            ?.filter(
                              (v, i) =>
                                v?.CUSTOMER_ID == addDataHead?.customer_id
                            )
                            ?.map((v, i) => (
                              <option key={i} value={v?.SR_NO}>
                                {v?.SR_NO}
                              </option>
                            ))}
                        </>
                      }
                    />
                  )}
                  <Dropdown
                    width2=""
                    disabled={addDataHead?.dis_payment}
                    label={"PAYMENT TERMS"}
                    value={addDataHead?.payment_terms}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        payment_terms: e.target.value,
                        cash_received: "",
                        cash_recieved_from: "",
                        online_rcvd: "",
                        bank_acc: "",
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        <option value={"1"}>CASH</option>
                        <option
                          disabled={addDataHead?.cust_reg == "0"}
                          value={"2"}
                        >
                          CREDIT
                        </option>
                        <option value={"3"}>ONLINE</option>
                      </>
                    }
                  />
                  {!addDataHead?.order_no && (
                    <Dropdown
                      width2=""
                      label={"CATEGORY"}
                      value={addDataHead?.category}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          category: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value=""> SELECT</option>
                          <option value="DOMESTIC"> DOMESTIC</option>
                          <option value="COMMERCIAL"> COMMERCIAL</option>
                        </>
                      }
                    />
                  )}
                  {(addDataHead?.payment_terms == "1" ||
                    addDataHead?.payment_terms == "3") && (
                    <Input
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.cash_received
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            cash_received:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) || inputValue === ""
                                ? inputValue
                                : "",
                            online_rcvd:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.inv_amount || 0) -
                                      e.target.value
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                      value={addDataHead?.cash_received}
                      label={"CASH RECEIVED"}
                      placeholder={"CASH RECEIVED"}
                    />
                  )}

                  {addDataHead?.payment_terms == "3" && (
                    <>
                      <Input
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            (inputValue?.length <= 10 &&
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              )) ||
                            inputValue === "" ||
                            inputValue === "-" ||
                            !addDataHead?.online_rcvd
                          ) {
                            setAddDataHead({
                              ...addDataHead,
                              online_rcvd:
                                /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                  inputValue
                                ) || inputValue === ""
                                  ? inputValue
                                  : "",
                              cash_received:
                                /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                  inputValue
                                ) ||
                                inputValue === "" ||
                                inputValue === "-"
                                  ? Number(
                                      Number(addDataHead?.inv_amount || 0) -
                                        e.target.value
                                    )?.toFixed(2)
                                  : "",
                            });
                          }
                        }}
                        value={addDataHead?.online_rcvd}
                        label={"AMOUNT RECEIVED ONLINE"}
                        placeholder={"AMOUNT RECEIVED ONLINE"}
                      />
                      <Input
                        disabled={addDataHead?.online_rcvd <= 0}
                        placeholder={"TRANSACTION ID"}
                        label={"TRANSACTION ID"}
                        onChange={(e) => {
                          const inputValue = e.target.value?.toUpperCase();
                          if (
                            inputValue.length <= 15 &&
                            (alfaNumericWithoutSpace.test(inputValue) ||
                              inputValue === "")
                          ) {
                            setAddDataHead({
                              ...addDataHead,
                              transaction_id: alfaNumericWithoutSpace.test(
                                inputValue
                              )
                                ? inputValue
                                : "",
                            });
                          }
                        }}
                        value={addDataHead?.transaction_id}
                      />
                      <Dropdown
                        disabled={addDataHead?.online_rcvd <= 0}
                        width2=""
                        label={"CASH RECIVED FROM"}
                        value={addDataHead?.cash_recieved_from}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            cash_recieved_from: e.target.value,
                          });
                        }}
                        options={
                          <>
                            <option value="">SELECT</option>
                            <option value="1">Phonepay</option>
                            <option value="2">GPay</option>
                            <option value="3">Paytm</option>
                            <option value="4">Cred</option>
                            <option value="5">Others</option>
                          </>
                        }
                      />
                      <Dropdown
                        disabled={Number(addDataHead?.online_rcvd || 0) <= 0}
                        width2=""
                        label={"BANK ACCOUNT"}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            bank_acc: e.target.value,
                          });
                        }}
                        value={addDataHead?.bank_acc}
                        options={
                          <>
                            <option value=""> SELECT</option>
                            {bank_master_list
                              ?.filter(
                                (v) =>
                                  v?.BANK_CODE == "TAR ACCOUNT" ||
                                  v?.BANK_CODE == "SAVINGS" ||
                                  v?.BANK_CODE == "CURRENT" ||
                                  v?.BANK_CODE == "OVER DRAFT"
                              )
                              ?.map((v, i) => (
                                <option key={i} value={v?.ID}>
                                  {bankCode(v?.BANK_CODE)} -
                                  {v?.BANK_ACC_NO == "NA"
                                    ? user_id
                                    : v?.BANK_ACC_NO}
                                </option>
                              ))}
                          </>
                        }
                      />
                    </>
                  )}

                  <Input
                    label={"INVOICE AMOUNT"}
                    placeholder={"INVOICE AMOUNT"}
                    disabled={true}
                    value={addDataHead?.inv_amount}
                  />
                  {godown_address_list?.length > 1 && (
                    <Dropdown
                      width2=""
                      disabled={addDataHead?.order_no}
                      label={"Received Godown"}
                      value={addDataHead?.godown}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          godown: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value={""}>SELECT</option>
                          {godown_address_list?.map((s, i) => (
                            <option key={i} value={s?.ID}>
                              {s?.UNIT_NAME}
                            </option>
                          ))}
                        </>
                      }
                    />
                  )}
                </div>
                {addDataHead?.items?.length > 0 && (
                  <div
                    style={{
                      maxHeight: "20%",
                      overflow: "scroll",
                      display: "flex",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <Table
                      headData={[
                        "PRODUCT",
                        "NUMBER OF CYLINDERS",
                        "NUMBER OF REGULATORS",
                        "CYLINDER DEPOSIT",
                        "REGULATOR DEPOSIT",
                        "IMPREST AMOUNT	",
                        "ADMIN CHARGES",
                        "GST AMOUNT",
                        "TOTAL AMOUNT",
                        "ACTION",
                      ]}
                      body={addDataHead?.items?.map((v, i) => (
                        <tr key={i}>
                          <td>
                            <Dropdown
                              value={v?.product_code}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const data = nc_dbc_data_list
                                  ?.filter(
                                    (val, i) =>
                                      val?.CUSTOMER_ID ==
                                        addDataHead?.customer_id &&
                                      val?.SR_NO == addDataHead?.order_no
                                  )?.[0]
                                  ?.details?.filter(
                                    (val) =>
                                      (val?.CAT_NAME == "DOMESTIC" ||
                                        val?.CAT_NAME == "COMMERCIAL") &&
                                      val?.PROD_CODE == inputValue
                                  )?.[0];

                                const data1 = uniqueEquipmentList
                                  ?.filter((v) => v?.PROD_CODE == inputValue)
                                  ?.filter((v) =>
                                    addDataHead?.category
                                      ? v?.CAT_NAME == addDataHead?.category
                                      : ""
                                  )[0];

                                const updatedItems = addDataHead?.items?.map(
                                  (item, ind) =>
                                    i == ind
                                      ? {
                                          ...item,
                                          product_code: inputValue,
                                          prod_name: data
                                            ? `${data?.CAT_NAME} - ${data?.CAT_DESC}`
                                            : "",
                                          cylinder_deposit: data
                                            ? data?.DEPOSIT_AMOUNT
                                            : "",
                                          regulator_deposit: data ? 0 : "",
                                          regulator_no: data ? 0 : "",
                                          regulator_deposit: data ? 0 : "",
                                          item_cat:
                                            data?.CAT_NAME == "DOMESTIC" ||
                                            data1?.CAT_NAME == "DOMESTIC"
                                              ? false
                                              : true,
                                          imprest_amt:
                                            data?.CAT_NAME == "DOMESTIC" ||
                                            data1?.CAT_NAME == "DOMESTIC"
                                              ? "0.00"
                                              : "0.00",
                                        }
                                      : item
                                );
                                setAddDataHead((prevData) => ({
                                  ...prevData,
                                  items: updatedItems,
                                }));
                              }}
                              options={
                                <>
                                  <option value=""> SELECT</option>
                                  {addDataHead?.order_no
                                    ? nc_dbc_data_list
                                        ?.filter(
                                          (val, i) =>
                                            val?.CUSTOMER_ID ==
                                              addDataHead?.customer_id &&
                                            val?.SR_NO == addDataHead?.order_no
                                        )?.[0]
                                        ?.details?.filter(
                                          (val) =>
                                            val?.CAT_NAME == "DOMESTIC" ||
                                            val?.CAT_NAME == "COMMERCIAL"
                                        )
                                        ?.map((v, i) => (
                                          <option
                                            disabled={
                                              addDataHead?.items?.filter(
                                                (s) =>
                                                  s?.product_code ==
                                                  v?.PROD_CODE
                                              )?.length > 0
                                            }
                                            key={i}
                                            value={v?.PROD_CODE}
                                          >
                                            {v?.CAT_NAME} - {v?.CAT_DESC}
                                          </option>
                                        ))
                                    : uniqueEquipmentList
                                        ?.filter((v) =>
                                          addDataHead?.category
                                            ? v?.CAT_NAME ==
                                              addDataHead?.category
                                            : ""
                                        )
                                        ?.map((v, i) => (
                                          <option
                                            disabled={
                                              addDataHead?.items?.filter(
                                                (s) =>
                                                  s?.product_code ==
                                                  v?.PROD_CODE
                                              )?.length > 0
                                            }
                                            key={i}
                                            value={v?.PROD_CODE}
                                          >
                                            {v?.CAT_NAME} - {v?.product_name}
                                          </option>
                                        ))}
                                </>
                              }
                            />
                          </td>
                          <td>
                            <Input
                              value={v?.cylinder_no}
                              onChange={(e) => {
                                if (e.target.value?.length < 5) {
                                  const updatedItems = addDataHead?.items?.map(
                                    (item, ind) =>
                                      i == ind
                                        ? {
                                            ...item,
                                            cylinder_no:
                                              /^\d+$/.test(e.target.value) ||
                                              e.target.value == ""
                                                ? e.target.value
                                                : "",
                                          }
                                        : item
                                  );
                                  setAddDataHead((prevData) => ({
                                    ...prevData,
                                    items: updatedItems,
                                  }));
                                }
                              }}
                              placeholder={"No. of Cylinders"}
                            />
                          </td>
                          <td>
                            <Input
                              disabled={addDataHead?.order_no}
                              value={v?.regulator_no}
                              onChange={(e) => {
                                if (e.target.value?.length < 5) {
                                  const updatedItems = addDataHead?.items?.map(
                                    (item, ind) =>
                                      i == ind
                                        ? {
                                            ...item,
                                            regulator_no:
                                              /^\d+$/.test(e.target.value) ||
                                              e.target.value == ""
                                                ? e.target.value
                                                : "",
                                          }
                                        : item
                                  );
                                  setAddDataHead({
                                    ...addDataHead,
                                    items: updatedItems,
                                  });
                                }
                              }}
                              placeholder={"No. of Regulators"}
                            />
                          </td>
                          <td>
                            <Input
                              disabled={addDataHead?.order_no}
                              value={v?.cylinder_deposit}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (
                                  (inputValue?.length <= 10 &&
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )) ||
                                  inputValue == 0 ||
                                  !v?.cylinder_deposit
                                ) {
                                  const updatedItems = addDataHead?.items?.map(
                                    (item, ind) =>
                                      i == ind
                                        ? {
                                            ...item,
                                            cylinder_deposit:
                                              /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                                inputValue
                                              )
                                                ? inputValue
                                                : "",
                                          }
                                        : item
                                  );
                                  setAddDataHead({
                                    ...addDataHead,
                                    items: updatedItems,
                                    inv_amount: "",
                                  });
                                }
                              }}
                              placeholder={"Cylinder Deposit"}
                            />
                          </td>
                          <td>
                            <Input
                              disabled={addDataHead?.order_no}
                              value={v?.regulator_deposit}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                if (
                                  (inputValue?.length <= 10 &&
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )) ||
                                  inputValue == 0 ||
                                  !v?.cylinder_deposit
                                ) {
                                  const updatedItems = addDataHead?.items?.map(
                                    (item, ind) =>
                                      i == ind
                                        ? {
                                            ...item,
                                            regulator_deposit:
                                              /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                                inputValue
                                              )
                                                ? inputValue
                                                : "",
                                          }
                                        : item
                                  );
                                  setAddDataHead({
                                    ...addDataHead,
                                    items: updatedItems,
                                    inv_amount: "",
                                  });
                                }
                              }}
                              placeholder={"Regulator Deposit"}
                            />
                          </td>
                          <td>
                            <Input
                              value={v?.imprest_amt}
                              disabled={v?.item_cat}
                              placeholder={"IMPREST Amount"}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (
                                  (inputValue?.length <= 8 &&
                                    /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )) ||
                                  inputValue == 0 ||
                                  !v?.imprest_amt
                                ) {
                                  const updatedItems = addDataHead?.items?.map(
                                    (item, ind) =>
                                      i == ind
                                        ? {
                                            ...item,
                                            imprest_amt:
                                              /^(0|[1-9]\d{0,4})(\.\d{0,2})?$/.test(
                                                inputValue
                                              )
                                                ? inputValue
                                                : "",
                                            igst: "",
                                            cgst: "",
                                            sgst: "",
                                          }
                                        : item
                                  );
                                  setAddDataHead({
                                    ...addDataHead,
                                    items: updatedItems,
                                    inv_amount: "",
                                  });
                                }
                              }}
                            />
                          </td>
                          <td>
                            <Dropdown
                              value={v?.admin_charge}
                              onChange={(e) => {
                                const updatedItems = addDataHead?.items?.map(
                                  (item, ind) =>
                                    i == ind
                                      ? {
                                          ...item,
                                          admin_charge: e.target.value,
                                          gst_amount:
                                            Number(e.target.value) * (18 / 100),
                                          igst: "",
                                          cgst: "",
                                          sgst: "",
                                        }
                                      : item
                                );
                                setAddDataHead({
                                  ...addDataHead,
                                  items: updatedItems,
                                  inv_amount: "",
                                });
                              }}
                              options={
                                <>
                                  <option value="">SELECT</option>
                                  <option value="100">
                                    TV ADMIN CHARGES ( Rs. 100.00/-)
                                  </option>
                                  <option value="250">
                                    TV CYLINDER COLLECTION CHARGES FROM CUSTOMER
                                    DOORSTEP ( Rs. 250.00/-)
                                  </option>
                                  <option value="0">NILL ( Rs. 0.00/-)</option>
                                </>
                              }
                            />
                          </td>

                          <td>
                            <Input
                              value={v?.gst_amount}
                              placeholder={"GST Amount"}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <Input
                              value={v?.total_amt}
                              placeholder={"Total Amount"}
                              disabled={true}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              onClick={() => {
                                const filterData = addDataHead?.items?.filter(
                                  (v, ind) => i != ind
                                );
                                setAddDataHead({
                                  ...addDataHead,
                                  items: filterData,
                                  inv_amount: "",
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                )}

                <div
                  className="flex aic gap10"
                  style={{ marginTop: 10, justifyContent: "flex-end" }}
                >
                  <AddButton
                    onClick={() => {
                      const data1 = validate_calculate();
                      addDataHead?.items.forEach((value, index) => {
                        if (
                          !value?.total_amt ||
                          (value?.total_amt && value?.total_amt?.length == 0)
                        ) {
                          data1.push("Please Click on calculate first");
                        }
                      });
                      if (data1?.length == 0) {
                        const updatedItems = [...addDataHead?.items, {}];
                        setAddDataHead((prevData) => ({
                          ...prevData,
                          items: updatedItems,
                        }));
                      } else {
                        setMess(<Alerts data={data1} />);
                        setOpen1(true);
                      }
                    }}
                  />
                  <Button
                    title={"FETCH INVOICE NO"}
                    onClick={fetch_invoice}
                    back
                  />
                  {/* <Button
                    title={"FETCH DEPOSITS AND CHARGES"}
                    onClick={fetch_deposit}
                    back
                  /> */}
                  <Button title={"CALCULATE"} onClick={calculate} back />
                  <SaveButton onClick={add_items} />
                </div>
              </div>
            </>
          }
        />
      )}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.CUSTOMER_NAME}-${viewItem?.SR_NO}`}
            />
          }
          isOpen={openView}
          title={viewItem?.CUSTOMER_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "NUMBER OF CYLINDERS",
                  "NUMBER OF REGULATORS",
                  "CYLINDER DEPOSIT",
                  "REGULATOR DEPOSIT	",
                  "IMPREST AMOUNT",
                  "ADMIN CHARGES	",
                  "IGST AMOUNT",
                  "CGST AMOUNT",
                  "SGST AMOUNT",
                  "TOTAL AMOUNT",
                ]}
                body={viewItem?.details?.map((v, i) => (
                  <tr key={i}>
                    <td>
                      {v?.CAT_NAME} - {v?.PRODUCT_NAME}
                    </td>
                    <td>{v?.NO_OF_CYL}</td>
                    <td>{v?.NO_OF_REG}</td>
                    <td>{v?.CYL_DEPOSIT}</td>
                    <td>{v?.REG_DEPOSIT}</td>
                    <td>{v?.ADMIN_CHARGES}</td>
                    <td>{v?.IMP_AMOUNT}</td>
                    <td>{v?.IGST_AMOUNT}</td>
                    <td>{v?.CGST_AMOUNT}</td>
                    <td>{v?.SGST_AMOUNT}</td>
                    <td>{v?.TOTAL_AMOUNT}</td>
                  </tr>
                ))}
              />
            </>
          }
        ></Modal>
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice3
          previewRef={previewRef}
          bank={false}
          empty_row_height={180 - viewItem?.products?.length * 25}
          empty_arr_length={9}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.RC_DATE,
            CVO_NAME: viewItem?.cvo_name,
            CVO_ADDRESS: viewItem?.PLACE_OF_SUPPLY || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.STATE_CODE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_PINCODE: viewItem?.B_PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.cvo_name,
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS || viewItem?.BILLING_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
          }}
          table_header={
            <tr>
              <td> SL NO</td>
              <td> ITEM DESCRIPTION</td>
              <td> HSN</td>
              <td>QTY </td>
              <td> RATE/UNIT</td>
              <td>ADMIN CHARGE </td>
              <td>IMPREST AMT </td>
              <td>GST </td>
              <td style={{ textAlign: "right" }}> AMOUNT</td>
            </tr>
          }
          table_body={
            <>
              {viewItem?.details?.map((v, i) => (
                <tr className="both" style={{ border: "none" }} key={i}>
                  <td className="both" style={{ width: "fit-content" }}>
                    1
                  </td>
                  <td className="both" style={{ width: "fit-content" }}>
                    {`${v?.CAT_NAME} - ${v?.PRODUCT_NAME}`}
                  </td>
                  <td className="both" style={{ border: "none" }}>
                    {v?.CSTEH_NO}
                  </td>
                  <td className="both" style={{ border: "none" }}>
                    {v?.NO_OF_CYL} {UnitFunction(v?.UNITS, "title")}
                  </td>
                  <td className="both" style={{ border: "none" }}>
                    {v?.BASE_PRICE}
                  </td>
                  <td className="both">{v?.ADMIN_CHARGES}</td>
                  <td className="both">{v?.IMP_AMOUNT}</td>
                  <td className="both">{v?.GSTP}</td>
                  <td className="both" style={{ textAlign: "right" }}>
                    {Number(v?.ADMIN_CHARGES) + Number(v?.IMP_AMOUNT)}
                  </td>
                </tr>
              ))}
            </>
          }
          cols={3}
          Total_tax={Number(
            Number(ReduceFunction(viewItem?.details, "IGST_AMOUNT")) +
              (Number(ReduceFunction(viewItem?.details, "CGST_AMOUNT")) +
                Number(ReduceFunction(viewItem?.details, "SGST_AMOUNT")))
          ).toFixed(2)}
          net_total={Number(viewItem?.TV_AMOUNT).toFixed(2) || 0}
          taxable_amt={Number(
            Number(ReduceFunction(viewItem?.details, "ADMIN_CHARGES")) +
              Number(ReduceFunction(viewItem?.details, "IMP_AMOUNT"))
          ).toFixed(2)}
          igst={ReduceFunction(viewItem?.details, "IGST_AMOUNT")}
          cgst={ReduceFunction(viewItem?.details, "CGST_AMOUNT")}
          sgst={ReduceFunction(viewItem?.details, "SGST_AMOUNT")}
          other={"0.00"}
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};
