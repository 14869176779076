import React, { useEffect, useRef, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import Table from "../../../components/table/Table";
import Dropdown from "../../../components/inputs/Dropdown";
import { Input } from "../../../components/inputs/Inputs";
import axios from "axios";
import { priceRefillAPI } from "../../../utils/apis";
import SmallModal from "../../../components/modal/SmallModal";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import PageLoading from "../../../components/PageLoading";
import { useNavigate } from "react-router-dom";
import "../../../CSS/masterData.css";
import RowTxt from "../../../components/RowTxt";
import Modal from "../../../components/modal/Modal";
import { motion } from "framer-motion";
import { HiOutlineArrowRight } from "react-icons/hi";
import { DeleteIcon } from "../../../utils/utils";
import { Pagination2 } from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import { DateValidator2 } from "../../../utils/validation";
import moment from "moment";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import { get_equipment_list, get_refill_price_list } from "../masterdataSlice";
import { PinVerify } from "../../OtherComponent/PinVerify";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import { Alerts } from "../../../components/Alerts/Alerts";
const monthFirstDate = moment().startOf("month").format("DD/MM/YYYY");

export const RefilePrice = () => {
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showPinVerify, setShowPinVerify] = useState(true);
  const [pin, setPin] = useState("");
  const [DeleteId, setDeleteId] = useState("");
  const [pinALert, setPinAlert] = useState(false);
  const [pinAlertMess, setPinAlertMess] = useState("");
  const [addProductData, setAddProductData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState({
    showAdd: false,
    showLoading: false,
    showLoader: false,
  });
  const [viewItem, setViewItem] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState();
  const [entries1, setEntries1] = useState("5");
  const [pageNo1, setPageNo1] = useState(1);
  const [SerchInput1, setSearchInput1] = useState("");

  const { refill_price_list, refill_price_loading, equipment_list } =
    useSelector((state) => state.masterdata);
  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);

  const { eff_date, user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const { day_end_date } = useSelector((state) => state.profile);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const navigate = useNavigate();
  useEffect(() => {
    store.dispatch(get_equipment_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_refill_price_list(obj));
  }, [pageNo, entries]);

  const handleDeleteProduct = async (deleteid) => {
    setShow({ ...show, showLoader: true });
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteid);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    const response = await axios.post(priceRefillAPI, body);
    setShow({ ...show, showLoader: false });

    if (response.data.success === true) {
      setShowDeleteAlert(false);
      store.dispatch(get_refill_price_list(obj));
      toast(response.data.message, {
        position: "top-center",
      });
    } else {
      setShow({ ...show, showDeleteAlert: false });
    }
  };

  const handleAddProduct = () => {
    const lastProduct = addProductData[addProductData.length - 1];

    // If no products are added yet, initialize with a new row
    if (addProductData.length === 0) {
      setAddProductData([
        {
          id: 1,
          PRODUCTID: "",
          PRODUCT: "",
          RSP: "",
          BASIC: "",
          SGST: "",
          CGST: "",
          OFFER: "",
          open_date: "",
        },
      ]);
    } else {
      // Validate the last row before adding a new one
      if (
        lastProduct?.PRODUCT &&
        lastProduct?.RSP.length > 0 &&
        lastProduct?.OFFER.length > 0 &&
        lastProduct?.BASIC.length > 0 &&
        lastProduct?.SGST.length > 0 &&
        lastProduct?.CGST.length > 0 &&
        lastProduct?.RSP >= lastProduct?.OFFER &&
        (lastProduct?.RSP * 65) / 100 <= lastProduct?.OFFER &&
        lastProduct?.open_date.length > 0
      ) {
        setAddProductData((prevData) => [
          ...prevData,
          {
            id: addProductData.length + 1,
            PRODUCTID: "",
            PRODUCT: "",
            RSP: "",
            BASIC: "",
            SGST: "",
            CGST: "",
            OFFER: "",
            open_date: "",
          },
        ]);
      } else {
        setPinAlert(true);
        setPinAlertMess(
          "Please enter all values in the current row, calculate, and then add the next row."
        );
      }
    }
  };

  const handleCalculate = () => {
    if (addProductData.length !== 0) {
      addProductData.forEach((product, index) => {
        if (
          product.PRODUCT !== "" &&
          product.RSP.length > 0 &&
          product.OFFER.length > 0 &&
          Number(product.RSP) >= Number(product.OFFER) &&
          (product.RSP * 65) / 100 <= product.OFFER
        ) {
          const basicPrice = (product.RSP / (1 + product.gstp / 100)).toFixed(
            2
          );
          const sgst = ((basicPrice * (product.gstp / 2)) / 100).toFixed(2);
          const cgst = ((basicPrice * (product.gstp / 2)) / 100).toFixed(2);

          // Update the product data in state
          setAddProductData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = {
              ...updatedData[index],
              BASIC: basicPrice,
              SGST: sgst,
              CGST: cgst,
            };
            return updatedData;
          });
        } else {
          setPinAlert(true);
          let alertMessage = "";
          if (product.PRODUCT === "") {
            alertMessage = "Please Select The PRODUCT";
          } else if (product.RSP.length <= 0) {
            alertMessage = "Please enter RSP";
          } else if (product.OFFER.length <= 0) {
            alertMessage = "Please enter OFFER PRICE.";
          } else if (Number(product.RSP) < Number(product.OFFER)) {
            alertMessage = "OFFER PRICE must be less than or equal to RSP";
          } else if ((product.RSP * 65) / 100 > product.OFFER) {
            alertMessage = "OFFER PRICE must be at least 65% of RSP.";
          }
          setPinAlertMess(alertMessage);
        }
      });
    } else {
      setPinAlert(true);
      setPinAlertMess("PLEASE ADD REFILL PRICE DATA AND CLICK CALCULATE");
    }
  };

  const valiDate = async () => {
    const data = [];

    if (addProductData?.length > 0) {
      for (let v of addProductData) {
        if (!v?.PRODUCT || (v?.PRODUCT && v?.PRODUCT.length === 0)) {
          data.push("Please Select Product.");
        }
        if (!v?.RSP || (v?.RSP && v?.RSP?.length == 0)) {
          data.push("Please Enter RSP Price.");
        } else if (Number(v?.RSP) <= 0) {
          data.push(" RSP Price can be Zero.");
        }

        if (!v?.OFFER || (v?.OFFER && v?.OFFER?.length == 0)) {
          data.push("Please Enter OFFER Price.");
        } else if (Number(v?.OFFER) <= 0) {
          data.push(" OFFER Price can be Zero.");
        }
        if (
          // (!v?.OFFER || (v?.RSP && v?.RSP?.length == 0) || v?.RSP <= 0) &&
          // (!v?.OFFER || (v?.OFFER && v?.RSP?.length == 0) || v?.OFFER <= 0) &&
          v?.RSP?.length > 0 &&
          v?.OFFER?.length > 0 &&
          (!v?.BASIC ||
            v?.BASIC == "" ||
            !v?.CGST ||
            v?.CGST == "" ||
            !v?.SGST ||
            v?.SGST == "")
        ) {
          data.push(" Please Click on Calculate");
        }
        const data1 = await DateValidator2(
          moment(v?.open_date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Effective Date cant be before ${day_end_date} `,
          `Effective Date cant be a future date`
        );
        if (!v?.open_date || (v?.open_date && v?.open_date.length === 0)) {
          data.push("Please Enter EFFECTIVE DATE");
        } else if (data1 != true) {
          data.push(...data1);
        }
      }
    } else {
      data.push("Please Add Product First");
    }
    return data;
  };

  const handleSaveProduct = async () => {
    const data = await valiDate();
    if (data.length == 0) {
      try {
        setShow({ ...show, showLoader: true });
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addProductData?.forEach((item) => {
          if (item) {
            body.append("pcode[]", item?.PRODUCTID);
            body.append("base[]", item?.BASIC);
            body.append("offer[]", item?.OFFER);
            body.append("sgst[]", item?.SGST);
            body.append("cgst[]", item?.CGST);
            body.append("rsp[]", item?.RSP);
            body.append(
              "openingdate[]",
              moment(item?.open_date).format("YYYY-MM-DD")
            );
          }
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const response = await axios.post(priceRefillAPI, body);
        setShow({ ...show, showLoader: false });

        if (response.data.success) {
          setShow({ ...show, showAdd: false });
          setAddProductData([]);
          toast(response.data.message, {
            position: "top-center",
          });
          store.dispatch(get_refill_price_list(obj));
        } else if (response.data.success == false) {
          setPinAlert(true);
          setPinAlertMess(response.data.message);
        }
      } catch (error) {
        console.error("Error while posting data:", error);
        setShowLoader(false);
        setPinAlert(true);
        setPinAlertMess("Error occurred. Please try again later.");
      }
    } else {
      setPinAlertMess(<Alerts data={data} />);
      setPinAlert(true);
    }
  };

  const handleAddDelete = (deleteId) => {
    const filterData = addProductData.filter((item) => item.id !== deleteId);
    setAddProductData(filterData);
  };

  const handleView = (item) => {
    setViewItem(item);
    setOpenViewModal(true);
  };

  const handleOfferReadonly = () => {
    const filtData = uniqueEquipmentList.filter(
      (item) =>
        item.PROD_CODE === addProductData[addProductData.length - 1]?.PRODUCTID
    )[0]?.CAT_NAME;
    if (filtData == "DOMESTIC") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="container">
      <PageHeading
        title={"REFILL PRICE MASTER"}
        right={
          <>
            <AddButton
              onClick={() => {
                setShow({ ...show, showAdd: true });
                handleAddProduct();
              }}
            />
            {addProductData?.length > 0 && (
              <>
                <Button onClick={handleCalculate} title={"CALCULATE"} />
                <SaveButton title={"SAVE"} onClick={handleSaveProduct} />
              </>
            )}
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/fL60bGxx8Ho",
                "https://youtu.be/-HYaZz6VdEk",
              ]}
            />
          </>
        }
      />
      {show.showAdd && addProductData.length != 0 && (
        <>
          <Table
            mt={30}
            headData={[
              "PRODUCT",
              "RSP PRICE",
              "BASIC PRICE",
              "SGST AMOUNT",
              "CGST AMOUNT",
              "OFFER PRICE",
              "OPENING DATE",
              "ACTIONS",
            ]}
            body={
              <>
                {addProductData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Dropdown
                        value={item.PRODUCTID}
                        onChange={(e) => {
                          const filterData = uniqueEquipmentList.filter(
                            (equipment) => equipment.PROD_CODE == e.target.value
                          );

                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              PRODUCT: filterData[0].product_name,
                              PRODUCTID: filterData[0].PROD_CODE,
                              gstp: filterData[0]?.GSTP,
                              BASIC: "",
                              SGST: "",
                              CGST: "",
                            };
                            return updatedData;
                          });

                          const str = filterData[0].product_name;
                          if (str.includes("FTL")) {
                            setPinAlert(true);
                            setPinAlertMess(
                              "Enter Only FTR Price For COMMERCIAL 5 KG FTL CYLINDER. CYLINDER DEPOSIT Will Be Automatically Picked Up With TAX"
                            );
                          }
                        }}
                        options={
                          <>
                            <option hidden>SELECT</option>
                            {uniqueEquipmentList.length > 0 && (
                              <>
                                {uniqueEquipmentList.map((equipment, i) => (
                                  <option key={i} value={equipment.PROD_CODE}>
                                    {equipment.CAT_NAME}-
                                    {equipment.product_name}
                                  </option>
                                ))}
                              </>
                            )}
                          </>
                        }
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"RSP PRICE"}
                        value={item.RSP}
                        onBlur={() => {
                          const filtData = uniqueEquipmentList.filter(
                            (item) =>
                              item.PROD_CODE ===
                              addProductData[addProductData.length - 1]
                                .PRODUCTID
                          )[0]?.CAT_NAME;
                          setAddProductData((prevData) => {
                            const updatedData = [...prevData];
                            updatedData[index] = {
                              ...updatedData[index],
                              OFFER:
                                filtData == "DOMESTIC"
                                  ? addProductData[addProductData.length - 1]
                                      .RSP
                                  : "",
                            };
                            return updatedData;
                          });
                        }}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (
                            (inputValue?.length < 11 &&
                              /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                inputValue
                              )) ||
                            event.target.value == 0
                          ) {
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                RSP: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                  inputValue
                                )
                                  ? inputValue
                                  : "",
                                BASIC: "",
                                SGST: "",
                                CGST: "",
                                OFFER: "",
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        readOnly={true}
                        placeholder={"BASIC PRICE"}
                        value={item.BASIC}
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"SGST"}
                        readOnly={true}
                        value={item.SGST}
                      />
                    </td>
                    <td>
                      <Input
                        placeholder={"CGST"}
                        readOnly={true}
                        value={item.CGST}
                      />
                    </td>
                    <td>
                      <Input
                        readOnly={handleOfferReadonly()}
                        placeholder={"OFFER PRICE"}
                        value={item.OFFER}
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (
                            (inputValue?.length < 11 &&
                              /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                inputValue
                              )) ||
                            event.target.value == 0
                          ) {
                            setAddProductData((prevData) => {
                              const updatedData = [...prevData];
                              updatedData[index] = {
                                ...updatedData[index],
                                OFFER: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                  inputValue
                                )
                                  ? inputValue
                                  : "",
                                BASIC: "",
                                SGST: "",
                                CGST: "",
                              };
                              return updatedData;
                            });
                          }
                        }}
                      />
                    </td>
                    <td>
                      <Input
                        type={"date"}
                        value={item.open_date}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            setAddProductData((pre) =>
                              pre.map((v, i) =>
                                i == index
                                  ? { ...v, open_date: e.target.value }
                                  : { ...v }
                              )
                            );
                          }
                        }}
                      />
                    </td>
                    <td>
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddDelete(item.id)}
                      />
                    </td>
                  </tr>
                ))}
              </>
            }
          />
          <div className="inp-error">
            * Please enter OFFER PRICE including with it's GST.
          </div>
        </>
      )}

      {refill_price_loading ? (
        <PageLoading />
      ) : refill_price_list?.length == 0 ? (
        <NoData />
      ) : (
        <>
          <Pagination2
            top={true}
            search={false}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          <div className="grid">
            {refill_price_list?.map((v, i) => (
              <div className="card" key={i}>
                <div className="card-heading">
                  {v?.cat_name}-{v?.cat_desc}
                </div>
                <RowTxt title={"RSP"} value={v?.rsp} />
                {/* <RowTxt title={"OFFER PRICE"} value={v?.offer_price} /> */}
                <RowTxt
                  title={"OPENING DATE"}
                  value={
                    moment(v?.opening_date).format("DD-MMM-YYYY") ==
                    "Invalid date"
                      ? "Not Avalible"
                      : moment(v?.opening_date).format("DD-MMM-YYYY")
                  }
                />
                <RowTxt title={"CREATED BY"} value={v?.CREATED_NAME || "-"} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "1rem",
                  }}
                >
                  {/* <DeleteIcon
                  style={{
                    color: "red",
                    fontSize: "22px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setDeleteId(v.ID);
                    setShowDeleteAlert(true);
                  }}
                /> */}

                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn" onClick={() => handleView(v)}>
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => handleView(v)}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
          <Pagination2
            bottom={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      )}

      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteProduct(DeleteId)}
          title={"Confirm"}
          mess={"Are you sure? You want to delete"}
        />
      )}

      {showPinVerify && (
        <PinVerify
          showPinVerify={showPinVerify}
          setShowPinVerify={setShowPinVerify}
          setShowLoader={setShowLoader}
          setAlertOpen={setPinAlert}
          setAlertMess={setPinAlertMess}
        />
      )}

      {pinALert && (
        <SmallModal
          isOpen={pinALert}
          setIsOpen={setPinAlert}
          title={"alert"}
          mess={pinAlertMess}
          okbtn={setPinAlert}
        />
      )}

      {openViewModal && (
        <Modal
          isOpen={openViewModal}
          setIsOpen={setOpenViewModal}
          title={`${viewItem.cat_name} - ${viewItem.cat_desc}`}
          body={
            <>
              <Pagination2
                top={true}
                totalCount={viewItem?.items?.length}
                SerchInput={SerchInput1}
                setSearchInput={setSearchInput1}
                pageNo={pageNo1}
                setPageNo={setPageNo1}
                entries={entries1}
                setEntries={setEntries1}
              />
              <Table
                mt={0.1}
                headData={[
                  "PRODUCT",
                  "RSP PRICE",
                  "BASIC PRICE",
                  "SGST AMOUNT",
                  "CGST AMOUNT",
                  "OFFER PRICE",
                  "OPENING DATE",
                  "CLOSING DATE",
                ]}
                body={viewItem?.items
                  ?.slice(pageNo1 * entries1 - entries1, pageNo1 * entries1)
                  .map((v) => (
                    <tr>
                      <td>
                        {v?.cat_name}-{v?.cat_desc}
                      </td>
                      <td>{v?.rsp}</td>
                      <td>{v?.base_price}</td>
                      <td>{v?.sgst_price}</td>
                      <td>{v?.cgst_price}</td>
                      <td>{v?.offer_price}</td>
                     
                      <td>{moment(v?.opening_date).format("DD-MMM-YYYY")}</td>
                      <td>{moment(v?.closing_date).format("DD-MMM-YYYY")}</td>
                    </tr>
                  ))}
              ></Table>
              <Pagination2
                bottom={true}
                totalCount={viewItem?.items?.length}
                SerchInput={SerchInput1}
                setSearchInput={setSearchInput1}
                pageNo={pageNo1}
                setPageNo={setPageNo1}
                entries={entries1}
                setEntries={setEntries1}
              />
            </>
          }
        />
      )}
      {show.showLoader && <Loader />}
    </div>
  );
};
