import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useRedirectIfAuthenticated() {
  const navigate = useNavigate();

  useEffect(() => {
    const uid = localStorage.getItem("userId");
    if (uid) {
      navigate("/Home");
    }
  }, [navigate]);
}

export default useRedirectIfAuthenticated;
