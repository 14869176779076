import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CustomList14 from "./CustomList14";
import { FiChevronRight } from "react-icons/fi";
import { BsPercent } from "react-icons/bs";
import CustomList from "./CustomList";
import { useSelector } from "react-redux";

const GstReturnList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const { emp_data, user_type } = useSelector((state) => state.other);

  const gstrMenu = [
    {
      title: "GSTR1",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/GSTReportS/GST1",
      activePath: "/GSTReportS/GST1",
      permission: "GSTR1_PERMISSION",
    },
    {
      title: "GSTR2",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/GSTReportS/GST2",
      activePath: "/GSTReportS/GST2",
      permission: "GSTR2_PERMISSION",
    },
    {
      title: "GSTR3",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/GSTReportS/GST3",
      activePath: "/GSTReportS/GST3",
      permission: "GSTR3_PERMISSION",
    },
    {
      title: "GSTR9",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/GSTReportS/GST9",
      activePath: "/GSTReportS/GST9",
      permission: "GSTR9_PERMISSION",
    },
  ];
  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };

  return (
    <>
      {permission_handle(gstrMenu?.map((v) => v?.permission)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "Transactions")
                ? pre.filter((v) => v != "Transactions")
                : ["Transactions"]
            )
          }
        >
          <div className="list-icon">
            <BsPercent color="#6e82a5" size={16} />
          </div>
          <div className="list-title fs15">GST Returns</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused.includes("Transactions")
                  ? "rotate-down"
                  : "rotate-right"
              }
            />
          </div>
        </div>
      )}

      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {/* <CustomList14
              title={"GSTR1"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST1" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST1");
                close();
              }}
            />
            <CustomList14
              title={"GSTR2"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST2" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST2");
                close();
              }}
            />
            <CustomList14
              title={"GSTR3"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST3" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST3");
                close();
              }}
            />
            <CustomList14
              title={"GSTR9"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/GSTReportS/GST9" ? "white" : "",
              }}
              onClick={() => {
                navigate("/GSTReportS/GST9");
                close();
              }}
            /> */}
            {gstrMenu?.map((v, i) => (
              <>
                {permission_handle([v?.permission]) && (
                  <CustomList14
                  key={i}

                    title={v?.title}
                    style={{
                      backgroundColor: path == v?.route ? "white" : "",
                    }}
                    type={v?.permission}
                    onClick={() => {
                      navigate(v?.route);
                      close();
                    }}
                    icon={
                     v?.icon
                    }
                  />
                )}
              </>
            ))}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default GstReturnList;
