import React from "react";
import { hsncode_api } from "./apis";
import moment from "moment";
import { UOMData } from "./data";
const userIdString = localStorage.getItem("userId");
export const effective_date = JSON.parse(userIdString)?.date;
export const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
export const last90days = moment().add(90, "days").format("DD/MM/YYYY");
export const today = moment().subtract(0, "days").format("DD/MM/YYYY");
export const oneYear = moment().add(1, "years").format("DD/MM/YYYY");
export const HSNValidator = async (data, fill = "HSN Code") => {
  const errors = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].hsn === "NA" || data[i].hsn === "na" || data[i].hsn === "") {
        errors.push(` Please Enter a valid ${fill}`);
      } else if (!/^[0-9]{6,8}$/.test(data[i].hsn)) {
        errors.push(` ${fill} Must Be Numeric and have 6-8 digits.`);
      } else {
        const response = await hsncode_api(
          data[i].hsn,
          fill == "HSN Code" ? "hsn" : fill == "SAC code" ? "sac" : "both"
        );
        if (!response) {
          errors.push(`Invalid ${fill}. Please check and try again.`);
        }
      }
    }
  }
  if (errors.length > 0) {
    return errors;
  }

  return true;
};

// export const DateValidator = (dateValue, endDate = "12/31/2030", validateDate = moment(effective_date).format("DD/MM/YYYY")) => {

export const DateValidator = (
  dateValue,
  // endDate = today,
  endDate,
  // validateDate = yesterday,
  validateDate,
  dateType = "EFFECTIVE DATE",
  mess
) => {
  const [dayA, monthA, yearA] = dateValue.split("/").map(Number);
  const [dayB, monthB, yearB] = validateDate.split("/").map(Number);
  const [dayC, monthC, yearC] = endDate.split("/").map(Number);
  const dateA = new Date(yearA, monthA - 1, dayA);
  const dateB = new Date(yearB, monthB - 1, dayB);
  const dateC = new Date(yearC, monthC - 1, dayC);
  const errors = [];

  if (dateA < dateB) {
    errors.push(
      mess
        ? mess
        : `${dateType}  ${
            dateType == "EFFECTIVE DATE"
              ? "is valid only after"
              : "should be start date of the month like"
          } ${validateDate}`
    );
  } else if (dateA > dateC) {
    errors.push(
      mess
        ? mess
        : `${dateType} ${
            dateType == "EFFECTIVE DATE"
              ? "is valid only before"
              : "should be start date of the month like"
          }  ${endDate}`
    );
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return true;
  }
};

export const DateValidator2 = (
  dateValue,
  endDate = today,
  validateDate = yesterday,
  mess1 = "",
  mess2 = ""
) => {
  const [dayA, monthA, yearA] = dateValue.split("/").map(Number);
  const [dayB, monthB, yearB] = validateDate.split("/").map(Number);
  const [dayC, monthC, yearC] = endDate.split("/").map(Number);
  const dateA = new Date(yearA, monthA - 1, dayA);
  const dateB = new Date(yearB, monthB - 1, dayB);
  const dateC = new Date(yearC, monthC - 1, dayC);
  const errors = [];
  if (dateA < dateB) {
    errors.push(mess1);
  } else if (dateA > dateC) {
    errors.push(mess2);
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return true;
  }
};

export const DateValidatorReports = (
  fromDate,
  toDate,
  mess1 = "To date must be after From date",
  mess2 = "The date range should not exceed 365 days"
) => {
  const [fromDay, fromMonth, fromYear] = fromDate.split("/").map(Number);
  const [toDay, toMonth, toYear] = toDate.split("/").map(Number);

  const dateFrom = new Date(fromYear, fromMonth - 1, fromDay);
  const dateTo = new Date(toYear, toMonth - 1, toDay);
  const errors = [];

  // Check if "To date" is after "From date"
  const oneYearInMs = 365 * 24 * 60 * 60 * 1000; // Milliseconds in a year
  const dateDifference = dateTo - dateFrom;
  if (dateTo <= dateFrom) {
    errors.push(mess1);
  } else if (dateDifference > oneYearInMs) {
    errors.push(mess2);
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return true;
  }
};

export const VehicalValidator = (vehicle_number) => {
  const errors = [];

  if (vehicle_number?.length !== 9 && vehicle_number?.length !== 10) {
    if (vehicle_number?.length == 0) {
      errors.push("Please Enter VEHICLE NUMBER.");
    } else {
      errors.push("Please Enter Valid VEHILE NUMBER");
    }
  } else if (!/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]*$/.test(vehicle_number)) {
    errors.push(
      "VEHICLE NUMBER Must Contain Combination of Alphabets and Numerics."
    );
  }

  if (errors.length > 0) {
    return errors;
  } else {
    return true;
  }
};

export function checkNumber(e) {
  var regex = /^[0-9]+$/;
  if (!regex.test(e)) {
    return false;
  }
  return true;
}

export function checkIFSC(input) {
  var ifscPattern = /^([A-Z]{4})(0{1})(\d{6}|[A-Z]{6}|[0-9A-Z]{6})$/;
  if (ifscPattern.test(input)) return true;
  return false;
}

// Function to validate Indian PAN card number
export const validatePAN = (pan) => {
  const regex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

  return regex.test(pan);
};

export default validatePAN;

export function isInvoiceDateWithinCreditPeriod(
  createdDateStr,
  invoiceDateStr,
  creditDays
) {
  // Parse the input dates
  const createdDate = new Date(createdDateStr);
  const invoiceDate = new Date(invoiceDateStr);

  // Calculate the end date by adding credit days to the created date
  const endDate = new Date(createdDate);
  endDate.setDate(endDate.getDate() + creditDays);

  // Check if the invoice date is within the period
  // return invoiceDate >= createdDate && invoiceDate <= endDate;
  return invoiceDate <= endDate;
}

export const gst_calculate = (amt, p_gst) => {
  const amount = Number(amt || 0);
  const gst = Number(p_gst || 0);
  const gst_amt = amount * (gst / 100);
  return Number(gst_amt.toFixed(2));
};

export const UnitFunction = (value, type = "title") => {
  // Predefined UOM to title mappings
  const predefinedUOM = { 1: "NOS", 2: "KGS", 3: "SET" };
  const predefinedTitle = { NOS: "1", KGS: "2", SET: "3" };

  // If type is 'title', convert UOM to title
  if (type === "title") {
    return (
      predefinedUOM[value] ||
      UOMData?.find((val) => val?.UOM == value || val?.title == value)?.title ||
      value
    );
  }

  // If type is 'UOM', convert title to UOM
  if (type === "UOM") {
    return (
      predefinedTitle[value] ||
      UOMData?.find((val) => val?.title == value || val?.UOM == value)?.UOM ||
      value
    );
  }

  return null; // Return null if no match is found
};

export function validateEmail2(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
  return emailRegex.test(email);
}

// Function to validate mobile number
export function validateMobile(mobile) {
  const mobileRegex = /^[6-9]\d{9}$/; 
  return mobileRegex.test(mobile);
}
