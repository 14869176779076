import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Table from "../../../components/table/Table";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import { DeleteIcon, base_url } from "../../../utils/utils";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { useSelector } from "react-redux";
import { get_arb_list, get_master_complete_list } from "../masterdataSlice";
import store from "../../../redux/store";
import SmallModal from "../../../components/modal/SmallModal";
import { arb_action } from "../../../utils/apis";
import Loader from "../../../components/Loader";
import PageLoading from "../../../components/PageLoading";
import moment from "moment";
import {
  DateValidator2,
  HSNValidator,
  UnitFunction,
} from "../../../utils/validation";
import { toast } from "react-toastify";
import TableNew from "../../../components/table/TableWithPagination";
import NoData from "../../../components/NoData";
import { Pagination2 } from "../../../components/pagination/pagination";
import { UOMData } from "../../../utils/data";
import { DropInput } from "../../../components/inputs/DropInput";
import { get_hsn_list } from "../../../redux/slice/SlideSlice";
import { Alerts } from "../../../components/Alerts/Alerts";
const headData = [
  "PRODUCT CATEGORY",
  "BRAND/MFR",
  "PRODUCT NAME",
  "HSN CODE",
  "UNITS",
  "GST %",
  "PURCHASE PRICE",
  "OPENING STOCK",
  "CURRENT STOCK",
  "EFFECTIVE DATE",
  "CREATED BY",
  "ACTIONS",
];

const BANMaster = () => {
  const [addData, setAddData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [open1, setOpen1] = useState(false);
  const [ID, setID] = useState("");
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const userIdString = localStorage.getItem("userId");
  const OMC = JSON.parse(userIdString)?.OMC;
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  const { arb_loading, arb_list, master_complete_list } = useSelector(
    (state) => state.masterdata
  );
  const { hsn_list, eff_date, user_id, emp_id, user_type, today } = useSelector(
    (state) => state.other
  );

  useEffect(() => {
    store.dispatch(get_master_complete_list());
  }, []);

  useEffect(() => {
    store.dispatch(get_arb_list(obj));
  }, [pageNo, entries]);

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product ||
          (v?.product && v?.product.length == 0) ||
          !v?.unit ||
          (v?.unit && v?.unit.length == 0) ||
          !v?.gst ||
          (v?.gst && v?.gst.length == 0) ||
          !v?.hsn ||
          (v?.hsn && v?.hsn.length == 0) ||
          !v?.price ||
          (v?.price && v?.price.length == 0) ||
          !v?.brand.trim() ||
          (v?.brand.trim() && v?.brand.trim().length == 0) ||
          !v?.name.trim() ||
          (v?.name.trim() && v?.name.trim().length == 0) ||
          !v?.stock ||
          (v?.stock && v?.stock.length == 0) ||
          !v?.date ||
          (v?.date && v?.date.length == 0)
      )
    ) {
      return true;
    }
  };

  const valiDate = async () => {
    const data = [];

    for (let v of addData) {
      if (!v?.product || (v?.product && v?.product.length === 0)) {
        data.push("Please Select the ARB ITEM from the Drop-down.");
      }
      if (
        !v?.brand.trim() ||
        (v?.brand.trim() && v?.brand.trim().length === 0)
      ) {
        data.push("Please Enter PRODUCT BRAND.");
      }
      if (!v?.name.trim() || (v?.name.trim() && v?.name.trim().length === 0)) {
        data.push("Please Enter PRODUCT NAME.");
      }
      if (!v?.unit || (v?.unit && v?.unit.length === 0)) {
        data.push("Please Select UNITS.");
      }
      if (!v?.date || (v?.date && v?.date.length === 0)) {
        data.push("Please Enter EFFECTIVE DATE");
      } else if (
        DateValidator2(moment(v?.date).format("DD/MM/YYYY"), today, eff_date) !=
        true
      ) {
        data.push(
          ...DateValidator2(
            moment(addData[addData.length - 1]?.date).format("DD/MM/YYYY"),
            today,
            eff_date,
            `Effective Date cant be before ${eff_date} `,
            `Effective Date cant be a future date`
          )
        );
      }
      if (!v?.gst || (v?.gst && v?.gst.length === 0)) {
        data.push("Please Select GST%");
      }
      const data1 = await HSNValidator([v]);
      if (!v?.hsn || (v?.hsn && v?.hsn.length === 0)) {
        data.push("Please Enter HSN CODE");
      } else if (data1 != true) {
        data.push(...data1);
      }
      if (!v?.price || (v?.price && v?.price.length === 0)) {
        data.push(
          "Please Enter PURCHASE PRICE. It Must Be Numeric And less than 100,00,000 And greater than 0"
        );
      } else {
        const numericPrice = parseFloat(v?.price);
        if (
          isNaN(numericPrice) ||
          numericPrice <= 0 ||
          numericPrice >= 10000000
        ) {
          data.push(
            "Please Enter a Valid PURCHASE PRICE. It Must Be Numeric And less than 100,00,000 And greater than 0"
          );
        }
      }

      if (!v?.stock || (v?.stock && v?.stock.length === 0)) {
        data.push("Please Enter OPENING STOCK.If not Enter 0.");
      }
    }
    return data;
  };

  const add_items = async () => {
    const data = await valiDate();
    if (data.length == 0) {
      try {
        setLoading(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        addData.forEach((v) => body.append("productcode[]", v?.product));
        addData.forEach((v) => body.append("brand[]", v?.brand.trim()));
        addData.forEach((v) => body.append("pname[]", v?.name.trim()));
        addData.forEach((v) => body.append("hsn[]", v?.hsn));
        addData.forEach((v) => body.append("unit[]", v?.unit));
        addData.forEach((v) => body.append("gst[]", v?.gst));
        addData.forEach((v) => body.append("price[]", v?.price));
        addData.forEach((v) => body.append("stock[]", v?.stock));
        addData.forEach((v) => body.append("date[]", v?.date));

        if (emp_id) {
          body.append("emp_code", emp_id);
        }

        const responce = await fetch(base_url + "arb_api.php", {
          method: "POST",
          body: body,
        });
        const res = await responce.json();
        setLoading(false);
        if (res.success) {
          store.dispatch(get_arb_list(obj));
          setAddData([]);
          toast(res.message);
        } else {
          alert(res.message);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen(true);
    }
  };

  const deleteItem = () => {
    setOpen1(false);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", ID);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    arb_action(body, setLoading).then((res) => {
      if (arb_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_arb_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_arb_list(obj));
      }
    });
  };
  const pro_code = [
    { value: 13, label: "STOVE" },
    { value: 14, label: "SURAKSHA" },
    { value: 15, label: "LIGHTER" },
    { value: 16, label: "KITCHENWARE" },
    { value: 17, label: "OTHERS" },
    { value: 34, label: "MSL DEBITS" },
  ];

  const fetch_data = async () => {
    if (
      addData[addData?.length - 1]?.brand !== "" &&
      addData[addData?.length - 1]?.product !== "" &&
      addData[addData?.length - 1]?.name !== ""
    ) {
      try {
        const body = new FormData();
        body.append("action", "fetch");
        body.append("agencyid", user_id);
        body.append("pid", addData[addData?.length - 1]?.product);
        body.append("brand", addData[addData?.length - 1]?.brand);
        body.append("pname", addData[addData?.length - 1]?.name);
        const responce = await fetch(base_url + "arb_api.php", {
          method: "POST",
          body: body,
        });
        const res = await responce.json();
        if (res.success) {
          setAddData((pre) =>
            pre.map((v, i) =>
              i === addData?.length - 1
                ? {
                    ...v,
                    hsn: res?.CSTEH_NO,
                    unit: res?.UNITS,
                    gst: res?.GSTP,
                    price: res?.MRP,
                    stock: res?.OPENING_STOCK,
                    date:
                      moment(res?.EFFECTIVE_DATE).format("YYYY-MM-DD") || "",
                  }
                : { ...v }
            )
          );
        } else {
          setAddData((pre) =>
            pre.map((v, i) =>
              i === addData?.length - 1
                ? {
                    ...v,
                    hsn: "",
                    unit: "",
                    gst: "",
                    price: "",
                    stock: "",
                    date: "",
                  }
                : { ...v }
            )
          );
          setMess(res.message);
          setOpen(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  };

  return (
    <div className="container">
      {loading && <Loader loading={loading} />}
      <PageHeading
        title={
          (OMC == 1 && "NFR PRODUCT") ||
          (OMC == 2 && "ARB PRODUCT") ||
          (OMC == 3 && "BLPG PRODUCT")
        }
        right={
          <>
            <AddButton
              onClick={() => {
                if (validation_check()) {
                  setMess(
                    "Please enter all the values in current row and then add next row"
                  );
                  setOpen(true);
                  return;
                } else if (addData?.length >= 4) {
                  setMess("Please Save the Records and ADD Again");
                  setOpen(true);
                  return;
                }
                setAddData((pre) => [
                  ...pre,
                  {
                    product: "",
                    unit: "",
                    gst: "",
                    hsn: "",
                    price: "0.00",
                    brand: "",
                    name: "",
                    stock: "",
                    date: "",
                  },
                ]);
              }}
            />
            {addData.length > 0 && <SaveButton onClick={() => add_items()} />}
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/eEx6_r6i7g4",
                " https://youtu.be/LYBHys_AQSc",
              ]}
            />
          </>
        }
      />
      {addData.length > 0 && (
        <Table
          headData={[
            "PRODUCT CATEGORY",
            "BRAND/MFR",
            "PRODUCT NAME",
            "HSN CODE",
            "UNITS",
            "GST %",
            "PURCHASE PRICE",
            "OPENING STOCK",
            "EFFECTIVE DATE",
            "ACTIONS",
          ]}
          body={addData.map((val, ind) => (
            <tr key={ind}>
              {/* <td>
                
              </td> */}
              <td>
                <Dropdown
                  value={val?.product}
                  onChange={(e) => {
                    {
                      const data = master_complete_list?.ARB.filter(
                        (item) => item?.ID == e.target.value
                      )[0];
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i === ind
                            ? {
                                ...v,
                                product: e.target.value,
                                hsn:
                                  e.target.value === ""
                                    ? ""
                                    : (() => {
                                        switch (data?.CAT_DESC) {
                                          case "OTHERS":
                                            return "";
                                          case "DGCC/BLUE BOOK":
                                            return "";
                                          default:
                                            return data?.HSN_CODE;
                                        }
                                      })(),
                                hsn_disable:
                                  e.target.value == ""
                                    ? false
                                    : (() => {
                                        switch (data?.CAT_DESC) {
                                          case "OTHERS":
                                            return false;
                                          case "DGCC/BLUE BOOK":
                                            return false;
                                          default:
                                            return true;
                                        }
                                      })(),
                                gst:
                                  e.target.value == ""
                                    ? ""
                                    : (() => {
                                        switch (data?.CAT_DESC) {
                                          case "OTHERS":
                                            return "";
                                          // case "DGCC/BLUE BOOK":
                                          //   return "18";
                                          default:
                                            return "18";
                                        }
                                      })(),
                                gst_disable:
                                  e.target.value == ""
                                    ? false
                                    : (() => {
                                        switch (data?.CAT_DESC) {
                                          case "OTHERS":
                                            return false;
                                          // case "DGCC/BLUE BOOK":
                                          //   return false;
                                          default:
                                            return true;
                                        }
                                      })(),
                                unit:
                                  e.target.value == ""
                                    ? ""
                                    : (() => {
                                        const catDesc = data?.CAT_DESC;
                                        switch (catDesc) {
                                          case "OTHERS":
                                            return "";
                                          case "DGCC/BLUE BOOK":
                                            return "1";
                                          case "LIGHTER+CANISTER":
                                            return "2";
                                          case "LIGHTER":
                                          case "CANISTER":
                                          case "STOVE":
                                          case "SURAKSHA":
                                          case "KITCHENWARE":
                                            return "1";
                                          default:
                                            return "1";
                                        }
                                      })(),
                                unit_dis:
                                  e.target.value == ""
                                    ? false
                                    : (() => {
                                        const catDesc = data?.CAT_DESC;
                                        switch (catDesc) {
                                          case "OTHERS":
                                            return false;
                                          case "DGCC/BLUE BOOK":
                                          case "LIGHTER+CANISTER":
                                          case "LIGHTER":
                                          case "CANISTER":
                                          case "STOVE":
                                          case "SURAKSHA":
                                          case "KITCHENWARE":
                                            return true;
                                          default:
                                            return true;
                                        }
                                      })(),
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                  onBlur={fetch_data}
                  options={[<option value={""}>{"SELECT"}</option>].concat(
                    master_complete_list?.ARB?.map((v, i) => (
                      <option
                        value={v.ID}
                        disabled={
                          addData.filter((item) => item?.product == v.ID)
                            .length > 0
                            ? true
                            : false
                        }
                        key={i}
                      >
                        {v.CAT_DESC}
                      </option>
                    ))
                  )}
                />
              </td>
              <td>
                <Input
                  placeholder={"BRAND"}
                  value={val.brand}
                  onChange={(e) => {
                    if (e.target.value.length <= 25) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i === ind
                            ? {
                                ...v,
                                // brand: /^[a-zA-Z0-9 ]+$/.test(e.target.value)
                                //   ? e.target.value
                                //   : "",
                                brand: e.target.value,
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                  onBlur={fetch_data}
                />
              </td>
              <td>
                <Input
                  placeholder={"PRODUCT NAME"}
                  value={val.name}
                  onChange={(e) => {
                    if (e.target.value.length <= 25) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                // name: /^[a-zA-Z ]+$/.test(e.target.value)
                                //   ? e.target.value
                                //   : "",
                                name: e.target.value,
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                  onBlur={fetch_data}
                />
              </td>
              <td>
                <DropInput
                  id="hsn"
                  placeholder={"HSN CODE"}
                  disabled={val?.hsn_disable}
                  onChange={(e) => {
                    if (e.target.value?.length > 1) {
                      store.dispatch(
                        get_hsn_list({
                          search: e.target.value,
                          type: "hsn",
                        })
                      );
                    }
                    if (e.target.value?.length < 9) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                hsn: /^\d+$/.test(e.target.value)
                                  ? e.target.value
                                  : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                  value={val?.hsn}
                  list={
                    val?.hsn?.length > 1
                      ? hsn_list?.map((v) => (
                          <option value={v?.HSNCODE}>{v?.HSNCODE}</option>
                        ))
                      : ""
                  }
                />
              </td>
              <td>
                <Dropdown
                  value={val.unit}
                  disabled={val?.unit_dis}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind ? { ...v, unit: e.target.value } : { ...v }
                      )
                    )
                  }
                  options={
                    <>
                      <option value={""}>{"SELECT"}</option>
                      {master_complete_list?.ARB?.filter(
                        (v) => v?.ID == val?.product
                      )[0]?.CAT_DESC == "OTHERS" ? (
                        UOMData.map((item) => (
                          <option value={item.UOM} key={item.id}>
                            {item.title}
                          </option>
                        ))
                      ) : (
                        <>
                          <option value={"1"}>NOS</option>
                          <option value={"2"}>KGS</option>
                          <option value={"3"}>SET</option>
                        </>
                      )}
                    </>
                  }
                />
              </td>
              <td>
                <Dropdown
                  value={val.gst}
                  disabled={val?.gst_disable}
                  onChange={(e) =>
                    setAddData((pre) =>
                      pre.map((v, i) =>
                        i == ind ? { ...v, gst: e.target.value } : { ...v }
                      )
                    )
                  }
                  options={
                    <>
                      <option value={""}>{"SELECT"}</option>
                      <option value={"0"}>0</option>
                      <option value={"5"}>5</option>
                      <option value={"12"}>12</option>
                      <option value={"18"}>18</option>
                      <option value={"28"}>28</option>
                    </>
                  }
                />
              </td>
              <td>
                <Input
                  placeholder={"PURCHASE PRICE"}
                  value={val.price}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      (inputValue?.length < 11 &&
                        /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(inputValue)) ||
                      e.target.value == 0
                    ) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i === ind
                            ? {
                                ...v,
                                price: /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                  inputValue
                                )
                                  ? inputValue
                                  : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  placeholder={"OPENING STOCK"}
                  value={val.stock}
                  onChange={(e) => {
                    if (e.target.value?.length < 7) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                stock: /^\d+$/.test(e.target.value)
                                  ? Number(e.target.value)
                                  : "",
                              }
                            : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <Input
                  type={"date"}
                  value={val.date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind ? { ...v, date: e.target.value } : { ...v }
                        )
                      );
                    }
                  }}
                />
              </td>
              <td>
                <DeleteIcon
                  onClick={() =>
                    setAddData((pre) => pre.filter((v, i) => i != ind))
                  }
                />
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {arb_loading ? (
        <PageLoading />
      ) : arb_list.length == 0 ? (
        <NoData mt={30} />
      ) : (
        <TableNew
          // nodata={arb_list?.length == 0 ? true : false}
          headData={headData}
          body={arb_list.map((val, ind) => (
            <tr key={ind}>
              <td>
                {/* {pro_code.filter((v) => v.value == val.PROD_CODE)[0]?.label} */}
                {val?.category_description}
              </td>
              <td>{val?.PROD_BRAND}</td>
              <td>{val?.PROD_NAME}</td>
              <td>{val?.CSTEH_NO}</td>
              <td>{UnitFunction(val?.UNITS, "title")}</td>
              <td>{val?.GSTP}</td>
              <td>{val?.PROD_MRP}</td>
              <td>{val?.OPENING_STOCK}</td>
              <td>{val?.CURRENT_STOCK}</td>
              <td>{moment(val?.effective_date).format("DD-MMM-YYYY")}</td>
              <td>{val?.CREATED_NAME || "-"}</td>
              <td>
                {user_type == "admin" || val?.EMPLOYEE_CODE == emp_id ? (
                  <DeleteIcon
                    onClick={() => {
                      setID(val?.ID);
                      setOpen1(true);
                    }}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          ))}
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {open && (
        <SmallModal
          okbtn
          title={"Alerts"}
          mess={mess}
          isOpen={open}
          setIsOpen={setOpen}
        />
      )}
      {open1 && (
        <SmallModal
          onClick={() => deleteItem()}
          title={"Alerts"}
          mess={"Are you sure? You want to delete"}
          isOpen={open1}
          setIsOpen={setOpen1}
        />
      )}
    </div>
  );
};

export default BANMaster;
