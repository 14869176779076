import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CustomList14 from "./CustomList14";
import { FiChevronRight } from "react-icons/fi";
import { BsPercent } from "react-icons/bs";
import { useSelector } from "react-redux";
import CustomList from "./CustomList";

const TdsTclList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const { emp_data, user_type } = useSelector((state) => state.other);

  const tdsTcsMenu = [
    {
      title: "TDS",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/TDS&TCS/TDS",
      activePath: "/TDS&TCS/TDS",
      permission: "TDS_PERMISSION",
    },
    {
      title: "TCS",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/TDS&TCS/TCS",
      activePath: "/TDS&TCS/TCS",
      permission: "TCS_PERMISSION",
    },
    {
      title: "TDS/TCS Returns",
      icon: <BsPercent color="#6e82a5" size={15} />,
      route: "/TDS&TCS/TDSTCSReturns",
      activePath: "/TDS&TCS/TDSTCSReturns",
      permission: "TDS_TCS_RETURN_PERMISSION",
    },
  ];
  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };

  return (
    <>
      {permission_handle(tdsTcsMenu?.map((v) => v?.permission)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "Transactions")
                ? pre.filter((v) => v != "Transactions")
                : ["Transactions"]
            )
          }
        >
          <div className="list-icon">
            <BsPercent color="#6e82a5" size={16} />
          </div>
          <div className="list-title fs15">TDS/TCS</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused.includes("Transactions")
                  ? "rotate-down"
                  : "rotate-right"
              }
            />
          </div>
        </div>
      )}
      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {tdsTcsMenu?.map((v, i) => (
              <>
                {permission_handle([v?.permission]) && (
                  <CustomList14
                  key={i}
                    title={v?.title}
                    style={{
                      backgroundColor: path == v?.route ? "white" : "",
                    }}
                    type={v?.permission}
                    onClick={() => {
                      navigate(v?.route);
                      close();
                    }}
                    icon={
                     v?.icon
                    }
                  />
                )}
              </>
            ))}
            {/* <CustomList14
              title={"TDS"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/TDS&TCS/TDS" ? "white" : "",
              }}
              onClick={() => {
                navigate("/TDS&TCS/TDS");
                close();
              }}
            />
            <CustomList14
              title={"TCS"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/TDS&TCS/TCS" ? "white" : "",
              }}
              onClick={() => {
                navigate("/TDS&TCS/TCS");
                close();
              }}
            />
            <CustomList14
              style={{
                backgroundColor:
                  path == "/TDS&TCS/TDSTCSReturns" ? "white" : "",
              }}
              title={"TDS/TCS Returns"}
              icon={<BsPercent color="#6e82a5" size={15} />}
              onClick={() => {
                navigate("/TDS&TCS/TDSTCSReturns");
                close();
              }}
            /> */}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default TdsTclList;
