import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import PageHeading from "../../../components/PageHeading";
import { DeleteIcon } from "../../../utils/utils";
import Button from "../../../components/btn/Button";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import Table from "../../../components/table/Table";
import {
  get_transition_complete_list,
  get_vendor_purchase_list,
} from "../trasactionsSlice";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import PageLoading from "../../../components/PageLoading";
import NoData from "../../../components/NoData";
import store from "../../../redux/store";
import moment from "moment";
import {
  get_arb_list,
  get_customer_vender_list,
} from "../../MasterData/masterdataSlice";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { vendor_purchase_api } from "../../../utils/apis";
import { POValidation } from "../../../utils/validator";
import {
  DateValidator2,
  gst_calculate,
  UnitFunction,
} from "../../../utils/validation";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import ReactToPrint from "react-to-print";
import { Alerts } from "../../../components/Alerts/Alerts";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import { UOMData } from "../../../utils/data";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";
const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  { title: "Other", cols: false },
  { title: "Amount", cols: false },
];

// const tax_invoice_table_header = [
//   "S No.",
//   "PRODUCT",
//   "HSN/SAC	",
//   "QTY",
//   "RATE",
//   "OTHER",
//   "TAXABLE",
//   "GST %	",
//   "TOTAL",
// ];
const table_header = [
  "PRODUCT",
  "GST%",
  "HSN CODE",
  "UNIT RATE ",
  "QUANTITY",
  "OTHER CHARGES",
  "TAXABLE AMOUNT",
  "IGST AMOUNT",
  "CGST AMOUNT",
  "SGST AMOUNT",
  "AMOUNT",
  "ACTIONS",
];
export const VenderPurchase = () => {
  const previewRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [isAdd, setIsAdd] = useState(false);
  const [addDataHead, setAddDataHead] = useState({});
  const [addData, setAddData] = useState([]);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [totalCount, setTotalCount] = useState("");
  const { user_id, eff_date, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );

  const { day_end_date } = useSelector((state) => state.profile);
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };

  const {
    vendor_purchase_list,
    vendor_purchase_loading,
    transition_complete_list,
  } = useSelector((state) => state.transactions);

  console.log("vendor_purchase_list ===", vendor_purchase_list);

  const { arb_list } = useSelector((state) => state.masterdata);
  useEffect(() => {
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_customer_vender_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_vendor_purchase_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const calculate = () => {
    if (!addDataHead.name) {
      setMess("Please select Vendor Name");
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) => !v?.unit_rate || (v?.unit_rate && v?.unit_rate.length == 0)
      )
    ) {
      setMess(`Please Enter Unit Price.`);
      setOpen1(true);
      return;
    }
    if (addData.find((v) => v?.unit_rate && v?.unit_rate <= 0)) {
      setMess(`Please Enter Unit Price cant be zero.`);
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) => !v?.quantity || (v?.quantity && v?.quantity.length == 0)
      )
    ) {
      setMess(`Please Enter QUANTITY.`);
      setOpen1(true);
      return;
    }
    if (
      addData.find(
        (v) => !v?.o_charges || (v?.o_charges && v?.o_charges.length == 0)
      )
    ) {
      setMess(`Please Enter Other Charges if not then enter zero.`);
      setOpen1(true);
      return;
    }
    var total = 0;
    addData.forEach((data, index) => {
      const unit_rate = Number(data.unit_rate);
      const quantity = Number(data.quantity);
      const o_charges = Number(data.o_charges);
      const p_gst = Number(data.p_gst);

      const tax_amount = unit_rate * quantity + o_charges;
      const gst_amount = tax_amount * (p_gst / 100);
      const amount = gst_amount + tax_amount;
      setAddData((prevData) => {
        return prevData.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              tax_amount: tax_amount.toFixed(2),
              total: amount.toFixed(2),
              igst_amount:
                addDataHead?.c_type == "isp" ? gst_amount.toFixed(2) : "0.00",
              cgst_amount:
                addDataHead?.c_type != "isp"
                  ? (gst_amount / 2).toFixed(2)
                  : "0.00",
              sgst_amount:
                addDataHead?.c_type != "isp"
                  ? (gst_amount / 2).toFixed(2)
                  : "0.00",
            };
          }
          return item;
        });
      });
      total += amount;
    });
    setAddDataHead({ ...addDataHead, total_amount: Number(total).toFixed(2) });
  };

  const validation_check = () => {
    const error = [];
    addData.forEach((v) => {
      if (!v?.product_id) {
        error.push("Please Select Product ID ");
      }
      if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate?.length === 0)) {
        error.push("Please Enter Unit Rate ");
      } else if (v?.unit_rate == 0) {
        error.push(" Unit Rate cant be Zero ");
      }
      if (!v?.o_charges || v?.o_charges == "") {
        error.push("Please Enter Other charges");
      }
      if (
        !v?.quantity ||
        v?.quantity == 0 ||
        (v?.quantity && v?.quantity?.length === 0)
      ) {
        error.push(" Please Enter Quantity ");
      } else if (
        !v?.tax_amount ||
        !v?.igst_amount ||
        !v?.cgst_amount ||
        !v?.sgst_amount ||
        !v?.total ||
        !addDataHead?.total_amount ||
        (addDataHead?.total_amount && addDataHead?.total_amount?.length == 0)
      ) {
        error.push("Please Click on Calculate First after save the data ");
      }
    });

    return error;
  };

  const validation_check1 = async () => {
    const error = [];
    const data = DateValidator2(
      moment(addDataHead?.po_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `Purchase Order Date cant be a before Day End date ${day_end_date}`,
      `Purchase Order Date cant be a future date`
    );
    if (!addDataHead?.po_number || addDataHead?.po_number?.length === 0) {
      error.push("Please fetch PO Number first ");
    } else if (!POValidation.test(addDataHead?.po_number)) {
      error.push("Invalid PO Number");
    }
    if (!addDataHead?.po_date || addDataHead?.po_date.length !== 10) {
      error.push("Please Select PO Date");
    } else if (data != true) {
      error.push(...data);
    }
    if (!addDataHead?.name || addDataHead?.name?.length === 0) {
      error.push("Please Select Vendor Name");
    }
    if (!addDataHead?.narration || addDataHead?.narration?.length === 0) {
      error.push("Please Enter Narration");
    }
    const data2 = DateValidator2(
      moment(addDataHead?.valid_upto).format("DD/MM/YYYY"),
      moment(addDataHead?.po_date).add(1, "years").format("DD/MM/YYYY"),
      moment(addDataHead?.po_date).format("DD/MM/YYYY"),
      `Valid Upto Date cant be a before ${moment(addDataHead?.po_date).format(
        "DD/MM/YYYY"
      )}`,
      `Purchase Order valid upto One Year`
    );
    if (!addDataHead?.valid_upto || addDataHead?.valid_upto?.length !== 10) {
      error.push("Please Select Validity date ");
    } else if (data2 != true) {
      error.push(...data2);
    }
    if (!addDataHead?.po_terms || addDataHead?.po_terms?.length === 0) {
      error.push("Please Enter PO terms");
    }
    const twoFYBackStartDate = moment()
      .subtract(2, "years") // Go two years back
      .startOf("year") // Get the start of that year
      .add(3, "months") // Add 3 months to get to 1st April
      .format("DD/MM/YYYY");

    const data1 = DateValidator2(
      moment(addDataHead?.quo_date).format("DD/MM/YYYY"),
      moment(addDataHead?.po_date).format("DD/MM/YYYY"),
      twoFYBackStartDate,
      `Quotation Date cant be a before as ${twoFYBackStartDate}`,
      `Quotation date cant be a after PO Date`
    );
    if (
      addDataHead?.quo_number?.length > 0 &&
      (!addDataHead?.quo_date ||
        (addDataHead?.quo_date && addDataHead?.quo_date?.length != 10))
    ) {
      error.push("Please Enter Quotation Date");
    } else if (addDataHead?.quo_number?.length > 0 && data1 != true) {
      error.push(...data1);
    }
    return error;
  };

  const FetchInvoiceNumber = async () => {
    const data = () => {
      if (
        !addDataHead?.po_date ||
        (addDataHead?.po_date && addDataHead?.po_date.length != 10)
      ) {
        return "Please enter Purchase Order Date";
      } else if (
        DateValidator2(
          moment(addDataHead?.po_date).format("DD/MM/YYYY"),
          today,
          day_end_date
        ) != true
      ) {
        return DateValidator2(
          moment(addDataHead?.po_date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          "PO date Cant be before as day end date ",
          "PO date cant be a future date"
        );
      } else {
        return true;
      }
    };
    try {
      if (data() == true) {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "ponumber");
        body.append("agencyid", user_id);
        body.append("date", addDataHead?.po_date);
        const res = await vendor_purchase_api(body);
        setShowLoader(false);
        if (res?.success) {
          setAddDataHead({ ...addDataHead, po_number: res?.invoice_number });
        } else {
          setMess(res?.message);
          setOpen1(true);
        }
      } else {
        setOpen1(true);
        setMess(data());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const add_items = async () => {
    const data = [
      ...(await validation_check1()),
      ...(await validation_check()),
    ];
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("po_no", addDataHead?.po_number);
        body.append("po_date", addDataHead?.po_date);
        body.append("vendor", addDataHead?.name);
        body.append("number", addDataHead?.quo_number || "");
        body.append(
          "q_date",
          addDataHead?.quo_number?.length > 0 ? addDataHead?.quo_date : ""
        );
        body.append("narration", addDataHead?.narration);
        body.append("valid_upto", addDataHead?.valid_upto);
        body.append("po_terms", addDataHead?.po_terms);
        body.append(
          "po_amount",
          addData
            .map((v) => v?.total)
            .reduce((a, b) => Number(a) + Number(b), 0)
            ?.toFixed(2) || ""
        );
        addData.forEach((v) => {
          body.append("procode[]", v?.product_id);
          body.append("gst[]", v?.p_gst);
          body.append("unit_rate[]", v?.unit_rate);
          body.append("hsn[]", v?.hsn);
          body.append("quantity[]", v?.quantity);
          body.append("other_charge[]", v?.o_charges);
          body.append("taxable_amount[]", v?.tax_amount);
          body.append("igst[]", v?.igst_amount);
          body.append("sgst[]", v?.sgst_amount);
          body.append("cgst[]", v?.cgst_amount);
          body.append("prod_amount[]", v?.total);
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const res = await vendor_purchase_api(body);
        setShowLoader(false);
        if (res?.success) {
          setIsAdd(false);
          toast(res?.message);
          setAddData([]);
          setAddDataHead({});
          store.dispatch(get_vendor_purchase_list(obj));
        } else {
          setMess(res?.message);
          setOpen1(true);
        }
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", id);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }
      const res = await vendor_purchase_api(body);
      setShowLoader(false);
      if (res?.success) {
        setOpen(false);
        toast(res?.message);
        setPageNo(vendor_purchase_list?.length < 2 ? pageNo - 1 : pageNo);
        store.dispatch(
          get_vendor_purchase_list({
            pageNo: vendor_purchase_list?.length < 2 ? pageNo - 1 : pageNo,
            entries: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
      } else {
        setMess(res.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"VENDOR PURCHASE ORDER "}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setIsAdd(true);
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/O9aHvKV5yiQ",
                " https://youtu.be/SRyl_1Te5GY",
              ]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {vendor_purchase_loading ? (
          <PageLoading />
        ) : vendor_purchase_list?.length == 0 ? (
          <NoData />
        ) : (
          <>
            <div className="grid">
              {vendor_purchase_list.map((v, i) => (
                <div className="card" key={i}>
                  <div>
                    <div className="card-heading">{v?.CVO_NAME}</div>
                    <RowTxt
                      title={"PO No."}
                      value={
                        <ReactToPrint
                          trigger={() => (
                            <div
                              onBeforeInput={() => {
                                setViewItem(v);
                              }}
                              onMouseEnter={() => {
                                setViewItem(v);
                              }}
                              onTouchStart={() => {
                                setViewItem(v);
                              }}
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                setViewItem(v);
                              }}
                            >
                              {v?.PO_NUMBER}
                            </div>
                          )}
                          onBeforePrint={(e) => {
                            setViewItem(v);
                          }}
                          content={() => previewRef.current}
                          documentTitle={`${v?.CVO_NAME}-${v?.PO_NUMBER}`}
                        />
                      }
                    />
                    <RowTxt
                      title={"PO Date"}
                      value={moment(v?.PO_Date).format("DD-MMM-YYYY")}
                    />
                    <RowTxt
                      title={"Valid Up"}
                      value={moment(v?.VALID_UPTO).format("DD-MMM-YYYY")}
                    />
                    <RowTxt title={"PO terms"} value={v?.PO_TERMS} />
                    <RowTxt title={"Narration"} value={v?.NARRATION} />

                    {v?.QUOTATION_NO && (
                      <>
                        <RowTxt
                          title={"Quotation No."}
                          value={v?.QUOTATION_NO}
                        />
                        <RowTxt
                          title={"Quotation Date"}
                          value={moment(v?.QUATATION_DATE).format(
                            "DD-MMM-YYYY"
                          )}
                        />
                      </>
                    )}
                    <RowTxt
                      title={"Created By"}
                      value={v?.CREATED_NAME || "-"}
                    />
                  </div>
                  <div
                    className="df jcsb mt10"
                    style={{
                      justifyContent:
                        user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                          ? "flex-end"
                          : "space-between",
                    }}
                  >
                    {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                      <DeleteIcon
                        onClick={() => {
                          setId(v?.ID);
                          setOpen(true);
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 1.05 }}
                      className="flex aic gap10 jce cursor-pointer"
                    >
                      <div
                        className="view-btn"
                        onClick={() => {
                          setOpenView(true);
                          setViewItem(v);
                        }}
                      >
                        View
                      </div>
                      <HiOutlineArrowRight
                        size={20}
                        color="#1b64af"
                        onClick={() => {
                          setOpenView(true);
                          setViewItem(v);
                        }}
                      />
                    </motion.div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"VENDOR PURCHASE ORDER"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"PO DATE"}
                  type={"date"}
                  value={addDataHead?.po_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        po_date: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"PO NUMBER"}
                  placeholder={"PO NUMBER"}
                  disabled={true}
                  value={addDataHead?.po_number}
                />

                <Dropdown
                  width2=""
                  value={addDataHead?.name}
                  onChange={(e) => {
                    const data = transition_complete_list?.Vendor?.filter(
                      (v) => v?.ID == e.target.value
                    )[0];

                    setAddDataHead({
                      ...addDataHead,
                      name: e.target.value,
                      c_type:
                        e.target.value == ""
                          ? ""
                          : [
                              ...transition_complete_list?.Vendor,
                              ...transition_complete_list?.OMC,
                            ]?.filter((v) => v.ID == e.target.value)[0]
                              .connection_type,
                    });

                    addData?.forEach((data, ind) => {
                      setAddData((pre) =>
                        pre.map((v, i) =>
                          i == ind
                            ? {
                                ...v,
                                tax_amount: "",
                                total: "",
                                igst_amount: "",
                                cgst_amount: "",
                                sgst_amount: "",
                              }
                            : { ...v }
                        )
                      );
                    });
                  }}
                  options={[<option value={""}>{"SELECT"}</option>].concat(
                    [
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                    ]?.map((v, i) => (
                      <option value={v?.ID} key={i}>
                        {v.CVO_CAT + "-" + v.cvo_name}
                      </option>
                    ))
                  )}
                  label={"VENDOR NAME"}
                />
                <Input
                  label={"QUOTATION NUMBER"}
                  placeholder={"QUOTATION NUMBER"}
                  value={addDataHead?.quo_number}
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      quo_number: e.target.value,
                      quo_date:
                        e.target.value == "" ? "" : addDataHead?.quo_date || "",
                    });
                  }}
                />
                <Input
                  label={"QUOTATION DATE"}
                  type={"date"}
                  disabled={
                    !addDataHead?.quo_number ||
                    addDataHead?.quo_number?.length == 0
                  }
                  value={addDataHead?.quo_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        quo_date: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"NARRATION"}
                  placeholder={"NARRATION"}
                  value={addDataHead?.narration}
                  onChange={(e) => {
                    if (e.target.value.length <= 200) {
                      setAddDataHead({
                        ...addDataHead,
                        narration: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"VALID UPTO"}
                  type={"date"}
                  value={addDataHead?.valid_upto}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        valid_upto: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"PO TERMS"}
                  placeholder={"PO TERMS"}
                  value={addDataHead?.po_terms}
                  onChange={(e) => {
                    if (e.target.value.length <= 200) {
                      setAddDataHead({
                        ...addDataHead,
                        po_terms: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={"PO TOTAL AMOUNT"}
                  placeholder={"PO TOTAL AMOUNT"}
                  value={addDataHead?.total_amount || "0.00"}
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={table_header}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            value={val?.product_id}
                            onChange={(e) => {
                              const data = arb_list.filter(
                                (val, ind) => val?.ID == e.target.value
                              )[0];
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        product_id: e.target.value,
                                        p_gst: data?.GSTP || "",
                                        hsn: data?.CSTEH_NO || "",
                                        // unit_rate: data?.PROD_MRP || "",
                                        quantity: "",
                                        o_charges: "",
                                        tax_amount: "",
                                        total: "",
                                        gst_amount: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            options={[
                              <option value={""}>{"SELECT"}</option>,
                            ].concat(
                              arb_list.map((val, ind) => (
                                <>
                                  <option
                                    disabled={
                                      addData?.filter(
                                        (v) => v?.product_id == val?.ID
                                      )?.length > 0
                                    }
                                    key={ind}
                                    value={val?.ID}
                                  >
                                    {val?.category_description +
                                      " - " +
                                      val?.PROD_BRAND +
                                      " - " +
                                      val?.PROD_NAME}
                                  </option>
                                </>
                              ))
                            )}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.p_gst}
                            disabled={true}
                            placeholder={"GST%"}
                          />
                        }{" "}
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.hsn}
                            disabled={true}
                            placeholder={"HSN CODE"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.unit_rate}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.unit_rate
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          unit_rate:
                                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          tax_amount: "",
                                          total: "",
                                          igst_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                            placeholder={"UNIT RATE"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val?.quantity}
                            placeholder={"QUANTITY"}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length < 5 &&
                                  /^\d+$/.test(inputValue)) ||
                                event.target.value == 0 ||
                                !val?.quantity
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          quantity: /^\d+$/.test(inputValue)
                                            ? inputValue
                                            : "",
                                          tax_amount: "",
                                          total: "",
                                          igst_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"0.00"}
                            value={val?.o_charges}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length < 10 &&
                                  /^(0|[1-9]\d{0,3})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.o_charges
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          o_charges:
                                            /^(0|[1-9]\d{0,3})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          tax_amount: "",
                                          total: "",
                                          igst_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"TAXABLE AMOUNT"}
                            value={val?.tax_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"IGST AMOUNT"}
                            value={val?.igst_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"CGST AMOUNT"}
                            value={val?.cgst_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"SGST AMOUNT"}
                            value={val?.sgst_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"AMOUNT"}
                            value={val?.total}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                total_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    if (addData?.length < 4) {
                      const data = validation_check();
                      if (data?.length == 0) {
                        setAddData((pre) => [...pre, {}]);
                      } else {
                        setMess(
                          "Please enter all the values in current row,calculate and then add next row"
                        );
                        setOpen1(true);
                      }
                    } else {
                      setMess("You can add max 4 product at a single time");
                      setOpen1(true);
                    }
                  }}
                />
                <Button
                  title={"FETCH PO NUMBER"}
                  back
                  onClick={FetchInvoiceNumber}
                  disable={
                    !addDataHead?.po_date ||
                    (addDataHead?.po_date && addDataHead?.po_date.length != 10)
                  }
                />
                <Button
                  title={"CALCULATE"}
                  back
                  disable={
                    !addData[addData.length - 1]?.product_id ||
                    !addData[addData.length - 1].p_gst
                      ? true
                      : false
                  }
                  onClick={() =>
                    !addData[addData.length - 1]?.product_id ||
                    !addData[addData.length - 1]?.p_gst
                      ? ""
                      : calculate()
                  }
                />
                <SaveButton onClick={add_items} />
              </div>
            </div>
          }
        ></Modal>
      )}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.CVO_NAME}-${viewItem?.PO_NUMBER}`}
            />
          }
          bg="white"
          isOpen={openView}
          title={viewItem?.CVO_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "GST%",
                  "HSN CODE",
                  "UNIT PRICE	",
                  "QUANTITY",
                  "OTHER CHARGES",
                  "TAXABLE AMOUNT	",
                  "IGST AMOUNT",
                  "SGST AMOUNT",
                  "CGST AMOUNT",
                  "TOTAL AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>
                          {(() => {
                            const data = arb_list?.filter(
                              (d) => d?.ID == v?.PROD_CODE
                            );
                            if (data?.length > 0) {
                              return `${data[0]?.category_description} - ${data[0]?.PROD_BRAND} - ${data[0]?.PROD_NAME}`;
                            } else {
                              return "";
                            }
                          })()}
                        </td>

                        <td>{v?.GST}</td>
                        <td>{v?.HSN_CODE}</td>
                        <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{Number(v?.OTHER_CHARGES).toFixed(2)}</td>
                        <td>{Number(v?.BASIC_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                        <td>{v?.PROD_AMOUNT}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={4} />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.BASIC_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.IGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.SGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.CGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {Number(viewItem?.TOTAL_AMOUNT)?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice3
          previewRef={previewRef}
          empty_row_height={180 - viewItem?.products?.length * 25}
          doc_name={"Vendor Purchase Order"}
          table_header={
            <tr>
              <td> SL NO</td>
              <td> ITEM DESCRIPTION</td>
              <td> HSN</td>
              <td>QTY </td>
              <td> RATE/UNIT</td>
              <td>OTHER CHARGES </td>
              <td>GST </td>
              <td style={{ textAlign: "right" }}> AMOUNT</td>
            </tr>
          }
          listdata={{
            SR_NO: viewItem?.PO_NUMBER,
            SR_NO_DATE: viewItem?.PO_Date,
            // REF_NUMBER: viewItem?.QUOTATION_NO,
            // REF_DATE: viewItem?.QUATATION_DATE,

            CVO_NAME: viewItem?.cvo_name,
            CVO_ADDRESS: viewItem?.PLACE_OF_SUPPLY || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.STATE_CODE,
            B_STATE_NAME: viewItem?.B_STATE,
            CVO_PINCODE: viewItem?.B_PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.cvo_name,
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS || viewItem?.BILLING_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",

            // Other info
            pay_terms:
              viewItem?.PAYMENT_TERMS == "1"
                ? "Cash"
                : viewItem?.PAYMENT_TERMS == "2"
                ? "Credit"
                : "",
          }}
          table_body={
            <>
              {viewItem?.products?.map((v, i) => (
                <tr key={i} className="both">
                  <td className="both">{i + 1}</td>
                  <td
                    className="both"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      minWidth: "250px",
                    }}
                  >
                    {(() => {
                      const data = arb_list?.filter(
                        (d) => d?.ID == v?.PROD_CODE
                      );
                      if (data?.length > 0) {
                        return `${data[0]?.category_description}-${data[0]?.PROD_BRAND}-${data[0]?.PROD_NAME}`;
                      } else {
                        return "";
                      }
                    })()}
                  </td>
                  <td className="both">{v?.HSN_CODE}</td>
                  <td className="both">
                    {v?.QUANTITY} {UnitFunction(v?.UNITS || v?.UOM, "title")}
                  </td>
                  <td className="both">{v?.UNIT_RATE}</td>
                  {/* <td className="both">0</td> */}
                  <td className="both">{v?.OTHER_CHARGES}</td>
                  <td className="both">{v?.GST} %</td>
                  <td className="both" style={{ textAlign: "right" }}>
                    {v?.BASIC_AMOUNT}
                  </td>
                </tr>
              ))}
            </>
          }
          Total_tax={Number(
            (Number(ReduceFunction(viewItem?.products, "IGST_AMOUNT")) || 0) +
              (Number(ReduceFunction(viewItem?.products, "CGST_AMOUNT")) || 0) +
              (Number(ReduceFunction(viewItem?.products, "SGST_AMOUNT")) || 0)
          ).toFixed(2)}
          net_total={Number(viewItem?.TOTAL_AMOUNT).toFixed(2)}
          taxable_amt={Number(
            ReduceFunction(viewItem?.products, "BASIC_AMOUNT")
          ).toFixed(2)}
          igst={ReduceFunction(viewItem?.products, "IGST_AMOUNT")}
          cgst={ReduceFunction(viewItem?.products, "CGST_AMOUNT")}
          sgst={ReduceFunction(viewItem?.products, "SGST_AMOUNT")}
          other={"0.00"}
        />
      </div>
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};
