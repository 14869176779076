import React, { useEffect, useLayoutEffect, useState } from "react";
import Layout from "./components/layout/Layout";
import "./App.css";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CylinderPurchases from "./screens/transactions/purchase/CylinderPurchases";
import BANPurchases from "./screens/transactions/purchase/BANPurchases";
import Login from "./screens/auth/Login";
import EmployeeLogin from "./screens/auth/EmployeeLogin";
import Profile from "./screens/profile";
import Receipt from "./screens/cash&bank/Receipt";
import Payments from "./screens/cash&bank/Payments";
import BankTransactions from "./screens/cash&bank/BankTransactions";
import CreditNote from "./screens/cash&bank/CreditNote";
import DebitNote from "./screens/cash&bank/DebitNote";
import Signup from "./screens/auth/Signup";
import StatutoryMaster from "./screens/MasterData/StatutoryMaster";
import EquipmentMaster from "./screens/MasterData/productmaster/EquipmentMaster";
import BANMaster from "./screens/MasterData/productmaster/BANMaster";
import ServicesMaster from "./screens/MasterData/productmaster/ServicesMaster";
import AreaCodeMaster from "./screens/MasterData/productmaster/AreaCodeMaster";
import NDPackaging from "./screens/MasterData/productmaster/NDPackaging";
import store from "./redux/store";
import { get_complete_list } from "./redux/slice/completeListSlice";
import PurchaseReport from "./screens/transactionReport/PurchaseReport";
import StockReport from "./screens/transactionReport/StockReport";
import Ledger from "./screens/transactionReport/Ledger";
import SalesReport from "./screens/transactionReport/SalesReport";
import NDRTReport from "./screens/transactionReport/NDRTReport";
import Payables from "./screens/transactionReport/Payables";
import BankBook from "./screens/transactionReport/BankBook";
import Receivables from "./screens/transactionReport/Receivables";
import StafMaster from "./screens/Payroll/StafMaster";
import Laons from "./screens/Payroll/Loans";
import PayRuns from "./screens/Payroll/PayRuns";
import Reports from "./screens/Payroll/Reports";
import { RefilePrice } from "./screens/MasterData/PriceMaster/RefilePrice";
import { BLPGPrice } from "./screens/MasterData/PriceMaster/BLPGPrice";
import { CustVendMaster } from "./screens/MasterData/CustVendMaster/CustVendMaster";
import { VehicalMaster } from "./screens/MasterData/VehicalMaster/VehicalMaster";
import { StaffMaster } from "./screens/MasterData/StaffMaster/StaffMaster";
import { get_master_complete_list } from "./screens/MasterData/masterdataSlice";
import { Quotations } from "./screens/transactions/sales/Quotations";

import DayEnd from "./screens/DayEnd/DayEnd";
import ProAndLoss from "./screens/FinacialReports/ProAndLoss";
import BalanceSheet from "./screens/FinacialReports/BalanceSheet";
import ProfitabilityAnalysis from "./screens/FinacialReports/ProfitabilityAnalysis";
import PartnersData from "./screens/FinacialReports/CapitalAccount/PartnersData";
import PartnersTransaction from "./screens/FinacialReports/CapitalAccount/PartnersTransaction";
import CapitalAccSearch from "./screens/FinacialReports/CapitalAccount/CapitalAccSearch";
import CapitalAccReport from "./screens/FinacialReports/CapitalAccount/CapitalAccReport";
import AssetsManagement from "./screens/FinacialReports/DepreciationAccount/AssetsManagement";
import DepreciationReport from "./screens/FinacialReports/DepreciationAccount/DepreciationReport";
import GSTR1 from "./screens/GST-Return/GSTR1";
import GSTR2 from "./screens/GST-Return/GSTR2";
import GSTR3 from "./screens/GST-Return/GSTR3";
import GSTR9 from "./screens/GST-Return/GSTR9";

import TDS from "./screens/GST-Return/TDS&TCS/TDS";
import TCS from "./screens/GST-Return/TDS&TCS/TCS";
import TDSTCSReturn from "./screens/GST-Return/TDS&TCS/TDSTCSReturn";
import { SetCreditLimit } from "./screens/MasterData/SetCreditLimit/SetCreditLimit";
import { DefectiveReturn } from "./screens/transactions/purchase/DefectiveReturn";
import { PurchaseOrder } from "./screens/transactions/sales/PurchaseOrder";
import { OtherPurchase } from "./screens/transactions/purchase/OtherPurchase";
import { ExpenditureOtherPurchase } from "./screens/OtherComponent/ExpenditureOtherPurchase";
import { DomestiCylSale } from "./screens/transactions/sales/DomestiCylSale";
import { ComercialCylSale } from "./screens/transactions/sales/ComercialCylSale";
import { BANSales } from "./screens/transactions/sales/BANSales";
import { DeliveryChallan } from "./screens/transactions/sales/DeliveryChallan";
import { SalesReturn } from "./screens/transactions/sales/SalesReturn";
import { BankMaster } from "./screens/MasterData/BankMaster/BankMaster";
import { StockAdjustMent } from "./screens/MasterData/StockAdjustment/StockAdjustMent";
import { ForgotPass } from "./screens/auth/ForgotPass";
import { Home } from "./screens/Home/Home";
import { EmailUpdate, ForgotPassword, LoginNew } from "./screens/auth/LoginNew";
import { NCDBCTran } from "./screens/transactions/NCDBC/NCDBCTran";
import { OTVTTVTV } from "./screens/transactions/NCDBC/OTVTTVTV";
import { ITVRC } from "./screens/transactions/NCDBC/ITVRC";
import {
  get_effective_date,
  get_emp_id,
  get_employee_data,
  get_omc,
  get_user_id,
} from "./redux/slice/SlideSlice";
import { VenderPurchase } from "./screens/transactions/purchase/VenderPurchase";
import PaymentReceived from "./screens/transactionReport/PaymentReceived";
import Cheque from "./screens/cash&bank/Cheque";
import LayoutProfile from "./components/layout/LayoutProfile";
import Ainfo from "./screens/profile/Ainfo";
import InvoiceBillInfo from "./screens/profile/InvoiceBillInfo";
import Settinginfo from "./screens/profile/Settinginfo";
import PPinfo from "./screens/profile/PPinfo";
import Pinfo from "./screens/profile/Pinfo";
import Uinfo from "./screens/profile/Uinfo";
import { logoutAllTabs } from "./utils/Hook/useBroadcastChannel";
import ReceivablesPaymentsDetails from "./screens/transactionReport/ReceivablesDetails";
import EmployeeMaster from "./screens/MasterData/EmployeeMaster/EmployeeMaster";
import { useSelector } from "react-redux";
import Loans from "./screens/Payroll/Loans";
import EmpProfile from "./screens/profile/EmpProfile";
// import { get_employee_data } from "./screens/profile/UsersSlice";
// import useBroadcastChannel from "./utils/Hook/useBroadcastChannel";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uid = localStorage.getItem("userId");
  const ID = JSON.parse(uid);
  const { user_type, emp_data } = useSelector((state) => state.other);
  // const [count, setcount] = useState(1)
  console.log("emp_data ===", emp_data);
  useEffect(() => {
    if (user_type == "employee") {
      // console.log("count ===", count);
      store.dispatch(get_employee_data());
      // setcount(count + 1);
    }
  }, [user_type]);

  useEffect(() => {
    logoutAllTabs();
  }, []);

  useEffect(() => {
    if (ID == null) {
      if (
        location?.pathname != "/forgotpass/login" &&
        location?.pathname != "/email" &&
        location?.pathname != "/loginn"
      ) {
        navigate("/login");
      } else {
        return;
      }
    } else {
      if (location.pathname == "/") {
        navigate("/Home");
      }
    }
  }, []);

  useEffect(() => {
    store.dispatch(get_complete_list());
    store.dispatch(get_master_complete_list());
    store.dispatch(get_effective_date());
    store.dispatch(get_user_id());
    store.dispatch(get_emp_id());
    store.dispatch(get_omc());
  }, [ID]);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  // Path Access Data

  const master_data = [
    {
      permission: emp_data?.ALERT_PERMISSION,
      path: "StatutoryMaster",
      element: <StatutoryMaster />,
    },
    {
      permission: emp_data?.EQUIPMENT_MASTER_PERMISSION,
      path: "EquipmentMaster",
      element: <EquipmentMaster />,
    },
    {
      permission: emp_data?.ARB_MASTER_PERMISSION,
      path: "BLPG/ARB/NFR-Master",
      element: <BANMaster />,
    },
    {
      permission: emp_data?.SERVICE_MASTER_PERMISSION,
      path: "ServicesMaster",
      element: <ServicesMaster />,
    },
    {
      permission: emp_data?.AREA_CODE_MASTER_PERMISSION,
      path: "AreaCodeMaster",
      element: <AreaCodeMaster />,
    },
    {
      permission: emp_data?.BOM_PERMISSION,
      path: "NC/DBC-Packaging",
      element: <NDPackaging />,
    },
    {
      permission: emp_data?.REFILL_PRICE_PERMISSION,
      path: "Price-Refill",
      element: <RefilePrice />,
    },
    {
      permission: emp_data?.ARB_PRICE_PERMISSION,
      path: "BLPG/ARB/NFR-price",
      element: <BLPGPrice />,
    },
    {
      permission: emp_data?.CUST_VEND_PERMISSION,
      path: "custVendMaster",
      element: <CustVendMaster />,
    },
    {
      permission: emp_data?.VEHICLE_PERMISSION,
      path: "VehicalMaster",
      element: <VehicalMaster />,
    },
    {
      permission: emp_data?.STAFF_PERMISSION,
      path: "StaffMaster",
      element: <StaffMaster />,
    },
    {
      permission: emp_data?.DISC_CREDIT_PERMISSION,
      path: "SetCreditLimit",
      element: <SetCreditLimit />,
    },
    {
      permission: emp_data?.BANK_PERMISSION,
      path: "BankMaster",
      element: <BankMaster />,
    },
    {
      permission: emp_data?.STOCK_ADJ_PERMISSION,
      path: "StockAdjustMent",
      element: <StockAdjustMent />,
    },
  ];

  const trans_data = [
    {
      permission: emp_data?.VENDOR_PO_PERMISSION,
      path: "venderpurchase",
      element: <VenderPurchase />,
    },
    {
      permission: emp_data?.CP_PERMISSION,
      path: "Cylinder-Purchases",
      element: <CylinderPurchases />,
    },
    {
      permission: emp_data?.ARB_PURCHASE_PERMISSION,
      path: "BLPG/ARB/NFR-Purchases",
      element: <BANPurchases />,
    },
    {
      permission: emp_data?.OP_PERMISSION,
      path: "ExpenditureOtherPurchase",
      element: <ExpenditureOtherPurchase />,
    },
    {
      permission: emp_data?.DEF_PUR_PERMISSION,
      path: "Defective/Pur/Return",
      element: <DefectiveReturn />,
    },
    {
      permission: emp_data?.QUOTATIONS_PERMISSION,
      path: "Quotations",
      element: <Quotations />,
    },
    {
      permission: emp_data?.CUSTOMER_PO_PERMISSION,
      path: "PurchaseSales",
      element: <PurchaseOrder />,
    },
    {
      permission: emp_data?.DOM_PERMISSION,
      path: "DomestiCylSale",
      element: <DomestiCylSale />,
    },
    {
      permission: emp_data?.COM_PERMISSION,
      path: "CommercialCylSale",
      element: <ComercialCylSale />,
    },
    {
      permission: emp_data?.BLPG_PERMISSION,
      path: "ARBSale",
      element: <BANSales />,
    },
    {
      permission: emp_data?.DC_PERMISSION,
      path: "DeliveryChallan",
      element: <DeliveryChallan />,
    },
    {
      permission: emp_data?.SALES_RETURN_PERMISSION,
      path: "SalesReturn",
      element: <SalesReturn />,
    },
    {
      permission: emp_data?.NC_PERMISSION,
      path: "ncdbctrans",
      element: <NCDBCTran />,
    },
    {
      permission: emp_data?.RC_PERMISSION,
      path: "itvrctrans",
      element: <ITVRC />,
    },
    {
      permission: emp_data?.TV_PERMISSION,
      path: "otvttvtvtrans",
      element: <OTVTTVTV />,
    },
  ];

  const cash_and_bank_data = [
    {
      permission: emp_data?.RECEIPTS_PERMISSION,
      path: "Receipts",
      element: <Receipt />,
    },
    {
      permission: emp_data?.PAYMENTS_PERMISSION,
      path: "Payments",
      element: <Payments />,
    },
    {
      permission: emp_data?.CHEQUE_CLEARANCE_PERMISSION,
      path: "cheque",
      element: <Cheque />,
    },
    {
      permission: emp_data?.BT_PERMISSION,
      path: "BankTransactions",
      element: <BankTransactions />,
    },
    {
      permission: emp_data?.CN_PERMISSION,
      path: "CreditNote",
      element: <CreditNote />,
    },
    {
      permission: emp_data?.DN_PERMISSION,
      path: "DebitNote",
      element: <DebitNote />,
    },
  ];

  const gstr_data = [
    {
      permission: emp_data?.GSTR1_PERMISSION,
      path: "GST1",
      element: <GSTR1 />,
    },
    {
      permission: emp_data?.GSTR2_PERMISSION,
      path: "GST2",
      element: <GSTR2 />,
    },
    {
      permission: emp_data?.GSTR3_PERMISSION,
      path: "GST3",
      element: <GSTR3 />,
    },
    {
      permission: emp_data?.GSTR9_PERMISSION,
      path: "GST9",
      element: <GSTR9 />,
    },
  ];

  const tds_data = [
    {
      permission: emp_data?.TDS_PERMISSION,
      path: "TDS",
      element: <TDS />,
    },
    {
      permission: emp_data?.TCS_PERMISSION,
      path: "TCS",
      element: <TCS />,
    },
    {
      permission: emp_data?.TDS_TCS_RETURN_PERMISSION,
      path: "TDSTCSReturns",
      element: <TDSTCSReturn />,
    },
  ];

  const reports_data = [
    {
      permission: emp_data?.PURCHASE_REPORT_PERMISSION,
      path: "PurchaseReport",
      element: <PurchaseReport />,
    },
    {
      permission: emp_data?.SALES_REPORT_PERMISSION,
      path: "SalesReport",
      element: <SalesReport />,
    },
    {
      permission: emp_data?.NCDBC_REPORT_PERMISSION,
      path: "NDRTReport",
      element: <NDRTReport />,
    },
    {
      permission: emp_data?.STOCK_REPORT_PERMISSION,
      path: "StockReport",
      element: <StockReport />,
    },
    {
      permission: emp_data?.BANK_BOOK_PERMISSION,
      path: "BankBook",
      element: <BankBook />,
    },
    {
      permission: emp_data?.LEDGER_REPORT_PERMISSION,
      path: "Ledger",
      element: <Ledger />,
    },
    {
      permission: emp_data?.RECEIVABLE_PERMISSION,
      path: "Receivables",
      element: <Receivables />,
    },
    {
      permission: emp_data?.PAYABLE_PERMISSION,
      path: "Payables",
      element: <Payables />,
    },
    {
      permission: emp_data?.PAYMENT_RECEIPT_REPORT_PERMISSION,
      path: "PaymentReceipts",
      element: <PaymentReceived />,
    },
  ];

  const financial_data = [
    {
      permission: emp_data?.PARTNERS_DATA_PERMISSION,
      path: "PartnersData",
      element: <PartnersData />,
    },
    {
      permission: emp_data?.PARTNERS_TRANSACTION_PERMISSION,
      path: "PartnersTransaction",
      element: <PartnersTransaction />,
    },
    {
      permission: emp_data?.CAPITAL_ACC_SEARCH_PERMISSION,
      path: "CapitalAccReport",
      element: <CapitalAccReport />,
    },
    {
      permission: emp_data?.CAPITAL_ACC_REPORT_PERMISSION,
      path: "CapitalAccSearch",
      element: <CapitalAccSearch />,
    },
    {
      permission: emp_data?.ASSETS_MANAGEMENT_PERMISSION,
      path: "AssetsManagement",
      element: <AssetsManagement />,
    },
    {
      permission: emp_data?.DEPRECIATION_REPORT_PERMISSION,
      path: "DepreciationReport",
      element: <DepreciationReport />,
    },
    {
      permission: emp_data?.PROFIT_LOSS_ACC_PERMISSION,
      path: "ProLossAccount",
      element: <ProAndLoss />,
    },
    {
      permission: emp_data?.BALANCE_SHEET_PERMISSION,
      path: "BalanceSheet",
      element: <BalanceSheet />,
    },
    {
      permission: emp_data?.PROFITABILITY_PERMISSION,
      path: "ProfitabilityAnalysis",
      element: <ProfitabilityAnalysis />,
    },
  ];

  const payroll_data = [
    {
      permission: emp_data?.PAYROLL_STAFF_PERMISSION,
      path: "StaffMaster",
      element: <StafMaster />,
    },
    {
      permission: emp_data?.PAY_RUNS_PERMISSION,
      path: "PayRuns",
      element: <PayRuns />,
    },
    {
      permission: emp_data?.LOANS_PERMISSION,
      path: "Loans",
      element: <Loans />,
    },
    {
      permission: emp_data?.REPORTS_PERMISSION,
      path: "Reports",
      element: <Reports />,
    },
  ];

  return (
    <Wrapper>
      <Routes>
        <Route element={<Layout />}>
          <Route path="transactions" element={<Outlet />}>
            {/* <Route path="Cylinder-Purchases" element={<CylinderPurchases />} />
            <Route path="BLPG/ARB/NFR-Purchases" element={<BANPurchases />} />
            <Route path="Defective/Pur/Return" element={<DefectiveReturn />} />
            <Route path="OtherPurchase" element={<OtherPurchase />} />
            <Route path="Quotations" element={<Quotations />} />
            <Route path="venderpurchase" element={<VenderPurchase />} />
            <Route
              path="ExpenditureOtherPurchase"
              element={<ExpenditureOtherPurchase />}
            />
            <Route path="PurchaseSales" element={<PurchaseOrder />} />
            <Route path="DomestiCylSale" element={<DomestiCylSale />} />
            <Route path="CommercialCylSale" element={<ComercialCylSale />} />
            <Route path="ARBSale" element={<BANSales />} />
            <Route path="DeliveryChallan" element={<DeliveryChallan />} />
            <Route path="SalesReturn" element={<SalesReturn />} />
            <Route path="ncdbctrans" element={<NCDBCTran />} />
            <Route path="otvttvtvtrans" element={<OTVTTVTV />} />
            <Route path="itvrctrans" element={<ITVRC />} /> */}
            {trans_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
            {emp_data?.EXP_PERMISSION || user_type === "admin" ? (
              <Route path="OtherPurchase" element={<OtherPurchase />} />
            ) : (
              ""
            )}
          </Route>
          <Route path="Cash&Bank" element={<Outlet />}>
            {/* <Route path="Receipts" element={<Receipt />} />
            <Route path="Payments" element={<Payments />} />
            <Route path="cheque" element={<Cheque />} />
            <Route path="BankTransactions" element={<BankTransactions />} />
            <Route path="CreditNote" element={<CreditNote />} />
            <Route path="DebitNote" element={<DebitNote />} /> */}
            {cash_and_bank_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
          </Route>
          <Route path="GSTReportS" element={<Outlet />}>
            {/* <Route path="GST1" element={<GSTR1 />} />
            <Route path="GST2" element={<GSTR2 />} />
            <Route path="GST3" element={<GSTR3 />} />
            <Route path="GST9" element={<GSTR9 />} /> */}
            {gstr_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
          </Route>
          <Route path="TDS&TCS" element={<Outlet />}>
            {/* <Route path="TDS" element={<TDS />} />
            <Route path="TCS" element={<TCS />} />
            <Route path="TDSTCSReturns" element={<TDSTCSReturn />} /> */}
            {tds_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
          </Route>
          <Route path="MasterData" element={<Outlet />}>
            {user_type == "admin" && (
              <Route path="EmployeeMaster" element={<EmployeeMaster />} />
            )}

            {master_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
          </Route>
          <Route path="transactionReport" element={<Outlet />}>
            {reports_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
            {/* <Route path="PurchaseReport" element={<PurchaseReport />} />
            <Route path="StockReport" element={<StockReport />} />
            <Route path="Ledger" element={<Ledger />} />
            <Route path="SalesReport" element={<SalesReport />} />
            <Route path="NDRTReport" element={<NDRTReport />} />
            <Route path="Payables" element={<Payables />} />
            <Route path="BankBook" element={<BankBook />} />
            <Route path="Receivables" element={<Receivables />} />
            <Route
              path="ReceivablesDetails"
              element={<ReceivablesPaymentsDetails />}
            />
            <Route path="PaymentReceipts" element={<PaymentReceived />} /> */}
          </Route>
          <Route path="Payroll" element={<Outlet />}>
            <Route path="StaffMaster" element={<StafMaster />} />
            {/* <Route path="PayRuns" element={<PayRuns />} />
            <Route path="Loans" element={<Laons />} />
            <Route path="Reports" element={<Reports />} /> */}
            {payroll_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
          </Route>
          <Route path="FinacialReport" element={<Outlet />}>
            {financial_data.map(({ permission, path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  permission || user_type === "admin" ? (
                    element
                  ) : (
                    <Navigate to="/home" replace />
                  )
                }
              />
            ))}
            {/* <Route path="CapitalAccSearch" element={<CapitalAccSearch />} />
            <Route path="DepreciationReport" element={<DepreciationReport />} />
            <Route path="ProLossAccount" element={<ProAndLoss />} />
            <Route path="BalanceSheet" element={<BalanceSheet />} />
            <Route
              path="ProfitabilityAnalysis"
              element={<ProfitabilityAnalysis />}
            /> */}

            {/* 'PARTNERS_DATA_PERMISSION', 'PARTNERS_TRANSACTION_PERMISSION', 'CAPITAL_ACC_SEARCH_PERMISSION', 'CAPITAL_ACC_REPORT_PERMISSION',
        'ASSETS_MANAGEMENT_PERMISSION', 'DEPRECIATION_REPORT_PERMISSION', */}

            {/* <Route path="PartnersData" element={<PartnersData />} />
            <Route
              path="PartnersTransaction"
              element={<PartnersTransaction />}
            />
            <Route path="CapitalAccReport" element={<CapitalAccReport />} />
            <Route path="AssetsManagement" element={<AssetsManagement />} /> */}
          </Route>
          {emp_data?.DAY_END_PERMISSION || user_type === "admin" ? (
            <Route path="DayEndPage" element={<DayEnd />} />
          ) : (
            ""
          )}

          <Route path="Home" element={<Home />} />
          {/* <Route path="profile" element={<Profile />} /> */}
          {user_type == "admin" ? (
            <Route element={<LayoutProfile />}>
              <Route path="profile" element={<Outlet />}>
                <Route path="/profile/Ainfo" element={<Ainfo />} />
                <Route path="/profile/Pinfo" element={<Pinfo />} />
                <Route path="/profile/Uinfo" element={<Uinfo />} />
                <Route
                  path="/profile/InvoiceBillInfo"
                  element={<InvoiceBillInfo />}
                />
                <Route path="/profile/Settinginfo" element={<Settinginfo />} />
                <Route path="/profile/PPinfo" element={<PPinfo />} />
              </Route>
            </Route>
          ) : (
            <Route element={<LayoutProfile />}>
              <Route path="profile" element={<Outlet />}>
                <Route path="/profile/Ainfo" element={<EmpProfile />} />
              </Route>
            </Route>
          )}
        </Route>
        <Route exact path="login" element={<Login />} />
        <Route path="/loginn" element={<LoginNew />} />
        {/* <Route path="/login/:aid" element={<LoginNew />} /> */}
        {/* <Route path="/email/:aid/:oldemail/:newemail" element={<EmailUpdate />} /> */}

        <Route path="/email" element={<EmailUpdate />} />

        <Route path="register" element={<Signup />} />
        <Route path="forgot" element={<ForgotPass />} />
        <Route path="/forgotpass/login" element={<ForgotPassword />} />
        <Route path="employee-login" element={<EmployeeLogin />} />
        <Route
          path="*"
          element={ID ? <Navigate to="/home" /> : <Navigate to="/login" />}
        />
      </Routes>
    </Wrapper>
  );
};

export default App;
