import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import CustomList from "./CustomList";
import { useNavigate } from "react-router-dom";
import { GoChecklist } from "react-icons/go";
import { FiChevronRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { MdAccountBalance } from "react-icons/md";
import { IoAnalytics } from "react-icons/io5";

const FinacialReportsList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const { user_type, emp_data } = useSelector((state) => state.other);

  const financialReportMenu = [
    {
      title: "Capital Account",
      key: "Product Master",
      children: [
        {
          title: "Partners Data",
          route: "/FinacialReport/PartnersData",
          activePath: "/FinacialReport/PartnersData",
          permission: "PARTNERS_DATA_PERMISSION",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "Partners Transaction",
          route: "/FinacialReport/PartnersTransaction",
          activePath: "/FinacialReport/PartnersTransaction",
          permission: "PARTNERS_TRANSACTION_PERMISSION",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "Capital Acc Search",
          route: "/FinacialReport/CapitalAccSearch",
          activePath: "/FinacialReport/CapitalAccSearch",
          permission: "CAPITAL_ACC_SEARCH_PERMISSION",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "Capital Acc Report",
          route: "/FinacialReport/CapitalAccReport",
          activePath: "/FinacialReport/CapitalAccReport",
          permission: "CAPITAL_ACC_REPORT_PERMISSION",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Depreciation Account",
      key: "Price Master",
      children: [
        {
          title: "Assets Management",
          route: "/FinacialReport/AssetsManagement",
          activePath: "/FinacialReport/AssetsManagement",
          permission: "ASSETS_MANAGEMENT_PERMISSION",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
        {
          title: "Depreciation Report",
          route: "/FinacialReport/DepreciationReport",
          activePath: "/FinacialReport/DepreciationReport",
          permission: "DEPRECIATION_REPORT_PERMISSION",
          icon: (
            <div className="list-icon">
              <div className="circle"></div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Profile and Loss Account",
      key: "ProfitLoss",
      children: [],
      route: "/FinacialReport/ProLossAccount", // Direct navigation, no sub-items
      activePath: "/FinacialReport/ProLossAccount", // Direct navigation, no sub-items
      permission: "PROFIT_LOSS_ACC_PERMISSION",
      icon: <IoAnalytics size={15} color="#6e82a5" />,
    },
    {
      title: "Balance Sheet",
      key: "BalanceSheet",
      children: [],
      route: "/FinacialReport/BalanceSheet",
      activePath: "/FinacialReport/BalanceSheet",
      permission: "BALANCE_SHEET_PERMISSION",
      icon: <IoAnalytics size={15} color="#6e82a5" />,
    },
    {
      title: "Profitability Analysis",
      key: "ProfitabilityAnalysis",
      children: [],
      route: "/FinacialReport/ProfitabilityAnalysis",
      activePath: "/FinacialReport/ProfitabilityAnalysis",
      permission: "PROFITABILITY_PERMISSION",
      icon: <IoAnalytics size={15} color="#6e82a5" />,
    },
  ];

  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };
  const extractPermissions = (menu) => {
    let permissions = [];

    menu.forEach((item) => {
      if (item.permission) {
        permissions.push(item.permission);
      }
      if (item.children && item.children.length > 0) {
        permissions = permissions.concat(
          item.children.map((child) => child.permission)
        );
      }
    });

    return permissions;
  };

  return (
    <>
      {permission_handle(extractPermissions(financialReportMenu)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "Transactions")
                ? pre.filter((v) => v != "Transactions")
                : ["Transactions"]
            )
          }
        >
          <div className="list-icon">
            <GoChecklist size={18} color="#6e82a5" />
          </div>
          <div className="list-title fs15">Finacial Reports</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused.includes("Transactions")
                  ? "rotate-down"
                  : "rotate-right"
              }
            />
          </div>
        </div>
      )}

      <AnimatePresence initial={false}>
        {focused.includes("Transactions") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <>
              {permission_handle(
                financialReportMenu[0]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Product Master")
                        ? pre.filter((v) => v != "Product Master")
                        : [...pre, "Product Master"]
                    )
                  }
                >
                  <div className="list-icon">
                    <MdAccountBalance size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">Capital Account</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("Product Master")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}
              <AnimatePresence>
                {focused.includes("Product Master") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {financialReportMenu[0]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            key={i}
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                    {/* <CustomList
                      title={"Partners Data"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/PartnersData" ? "white" : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/PartnersData");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Partners Transaction"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/PartnersTransaction"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/PartnersTransaction");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Capital Acc Search"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/CapitalAccSearch"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/CapitalAccSearch");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Capital Acc Report"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/CapitalAccReport"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/CapitalAccReport");
                        close();
                      }}
                    /> */}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            <>
              {permission_handle(
                financialReportMenu[1]?.children?.map((v) => v?.permission)
              ) && (
                <div
                  className="list-main"
                  onClick={() =>
                    setFocused((pre) =>
                      pre.find((v) => v == "Price Master")
                        ? pre.filter((v) => v != "Price Master")
                        : [...pre, "Price Master"]
                    )
                  }
                >
                  <div className="list-icon">
                    <MdAccountBalance size={15} color="#6e82a5" />
                  </div>
                  <div className="list-title fs14">Depreciation Account</div>
                  <div className="list-arrow">
                    <FiChevronRight
                      size={18}
                      className={
                        focused.includes("Price Master")
                          ? "rotate-down"
                          : "rotate-right"
                      }
                    />
                  </div>
                </div>
              )}
              <AnimatePresence>
                {focused.includes("Price Master") && (
                  <motion.div
                    style={{ marginLeft: 15, overflow: "hidden" }}
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: "auto" },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{
                      duration: 1,
                      bounce: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {/* <CustomList
                      title={"A ssets Management"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/AssetsManagement"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/AssetsManagement");
                        close();
                      }}
                    />
                    <CustomList
                      title={"Depreciaton Report"}
                      style={{
                        backgroundColor:
                          path == "/FinacialReport/DepreciationReport"
                            ? "white"
                            : "",
                      }}
                      onClick={() => {
                        navigate("/FinacialReport/DepreciationReport");
                        close();
                      }}
                    /> */}

                    {financialReportMenu[1]?.children.map((v, i) => (
                      <>
                        {permission_handle([v?.permission]) && (
                          <CustomList
                            key={i}
                            title={v?.title}
                            style={{
                              backgroundColor: path == v?.route ? "white" : "",
                            }}
                            type={v?.permission}
                            onClick={() => {
                              navigate(v?.route);
                              close();
                            }}
                          />
                        )}
                      </>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </>
            {/* <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/FinacialReport/ProLossAccount" ? "white" : "",
              }}
              onClick={() => {
                navigate("/FinacialReport/ProLossAccount");
                close();
              }}
            >
              <div className="list-icon"></div>
              <div className="list-title fs14">Profile and Loass Acoount</div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/FinacialReport/BalanceSheet" ? "white" : "",
              }}
              onClick={() => {
                navigate("/FinacialReport/BalanceSheet");
                close();
              }}
            >
              <div className="list-icon"></div>
              <div className="list-title fs14">Balance Sheet</div>
            </div>
            <div
              className="list-main"
              style={{
                backgroundColor:
                  path == "/FinacialReport/ProfitabilityAnalysis"
                    ? "white"
                    : "",
              }}
              onClick={() => {
                navigate("/FinacialReport/ProfitabilityAnalysis");
                close();
              }}
            >
              <div className="list-icon"></div>
              <div className="list-title fs14">Profitability Analysis</div>
            </div> */}

            {financialReportMenu
              ?.filter((v) => v?.permission)
              .map((v, i) => (
                <>
                  {permission_handle([v?.permission]) && (
                    <div
                      key={i}
                      className="list-main"
                      style={{
                        backgroundColor: path == v?.route ? "white" : "",
                      }}
                    >
                      <div className="list-icon">{v?.icon}</div>
                      <div
                        className="list-title fs14"
                        onClick={() => {
                          navigate(v?.route);
                          close();
                        }}
                      >
                        {v?.title}
                      </div>
                    </div>
                  )}
                </>
              ))}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default FinacialReportsList;
