import React, { useEffect, useState } from "react";
import PageHeading from "../../components/PageHeading";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../components/btn/Buttons";
import TableNew from "../../components/table/TableWithPagination";
import { useSelector } from "react-redux";
import axios from "axios";
import { staffMasterAPI } from "../../utils/apis";
import { toast } from "react-toastify";
import store from "../../redux/store";
import { get_staff_list } from "../MasterData/masterdataSlice";
import moment from "moment";
import { EmailValidation, MobileValidation } from "../../utils/validator";
import { Pagination2 } from "../../components/pagination/pagination";
import PageLoading from "../../components/PageLoading";
import Loader from "../../components/Loader";
import { DeleteIcon, base_url } from "../../utils/utils";
import SmallModal from "../../components/modal/SmallModal";

const Loans = () => {
  const [staffData, setStaffData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");

  const [showEdit, setShowEdit] = useState(false);
  const [EditItem, setEditItem] = useState({});
  const { user_id } = useSelector((state) => state.other);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { staff_list, staff_loading } = useSelector(
    (state) => state.masterdata
  );

  const handleFetchData = async () => {
    try {
      const body = {
        AGENCY_ID: user_id,
        action: "fetchStaffLoanData",
      };

      const response = await fetch(
        `${base_url}DOMSALES/staffLoan.php`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
      }

      const resJson = await response.json();
      setStaffData(resJson?.data?.STAFF_DATA);
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="container">
      <PageHeading
        title={"STAFF LOAN INFO"}
        right={
          <>
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/4FzTz5cY6bc",
                "https://youtu.be/JcUiQNwM4QY",
              ]}
            />
          </>
        }
      />

      {totalCount > 5 && (
        <Pagination2
          top={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {staff_loading ? (
        <PageLoading />
      ) : (
        <TableNew
          mt={5}
          nodata={staffData.length !== 0 && !staff_loading ? false : true}
          headData={[
            "EMPLOYEE CODE",
            "EMPLOYEE NAME",
            "ROLE",
            "REMAINING LOAN AMOUNT",
            "MONTHS LEFT",
            "EMI PER MONTH",
          ]}
          body={
            <>
              {staffData?.length != 0 &&
                staffData?.map((item, i) => (
                  <tr key={i}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditItem(item);
                        setShowEdit(true);
                      }}
                    >
                      {item?.EMP_CODE}
                    </td>
                    <td>{item?.EMP_NAME}</td>
                    <td>{moment(item?.ROLE).format("DD/MM/YYYY")}</td>
                    <td>{item?.DESIGNATION}</td>
                    <td>{item?.LOAN_MONTHS_LEFT}</td>
                    <td>{moment(item?.DOJ).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
            </>
          }
        />
      )}

      {showEdit && <>
      </>}

      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {showLoader && <Loader />}
    </div>
  );
};

export default Loans;
