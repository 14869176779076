import React from "react";
import "./layout.css";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import Sidebar from "../sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import { setSidebarProfileWidth } from "../../redux/slice/actionSlice";
import store from "../../redux/store";
import { Drawer } from "antd";
import styles from "../../screens/profile/profile.module.css";
import SidebarProfile from "../sidebar/SidebarProfile";
import { CgMenuLeftAlt } from "react-icons/cg";

const LayoutProfile = () => {
  const { smallSidebarProfileWidth } = useSelector((state) => state.actions);
  const { user_type } = useSelector((state) => state.other);

  return (
    <div>
      <div className={styles.profile_head}>
        <div
          className={styles.title_main}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>Account Info</div>
          {/* {user_type === "admin" && ( */}
            <div className={styles.profile_icon}>
              <CgMenuLeftAlt
                size={30}
                className="cursor"
                color="#9e72ff"
                onClick={() => {
                  store.dispatch(
                    setSidebarProfileWidth(!smallSidebarProfileWidth)
                  );
                }}
              />
            </div>
          {/* )} */}
        </div>
        <div className={styles.subtitle_main}>
          You have full control to manage your own account setting
        </div>
      </div>
      <div className=" layout-profile" style={{ backgroundColor: "#f5f6fa" }}>
        <div
          className="layout-sidebar"
          style={{
            width: smallSidebarProfileWidth ? "6rem" : "20rem",
            transition: "width ease 1s",
            paddingTop: "1rem",
            backgroundColor: "#fff",
            borderRadius: "12px",
          }}
        >
          <SidebarProfile onClose={() => console.log("")} />
        </div>
        <div className="sidebar-new">
          <Drawer
            width={"16rem"}
            placement={"right"}
            destroyOnClose={true}
            rootClassName={"sidebar-new"}
            Header={false}
            onClose={() =>
              store.dispatch(setSidebarProfileWidth(!smallSidebarProfileWidth))
            }
            open={smallSidebarProfileWidth}
          >
            <SidebarProfile
              onClose={() =>
                store.dispatch(
                  setSidebarProfileWidth(!smallSidebarProfileWidth)
                )
              }
            />
          </Drawer>
        </div>

        <div
          className={`layout-body `}
          style={{ backgroundColor: "#f5f6fa", borderRadius: "12px" }}
        >
          <div className="layout-outlet2">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutProfile;
