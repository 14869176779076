import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/inputs/Input";
import Button from "../../components/btn/Button";
import "./login.css";
import logoicon from "../../assets/logo512.png";
import logotxt from "../../assets/mylpgtxt.png";
import { BiLogIn } from "react-icons/bi";
import AuthBg from "../../components/authbg/AuthBg";
import { toast } from "react-toastify";
import { Text, base_url } from "../../utils/utils";
import Loader from "../../components/Loader";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FiArrowLeft } from "react-icons/fi";
import { EmailValidation } from "../../utils/validator";
import { forgot_password_email_api } from "../../utils/apis";
import useRedirectIfAuthenticated from "../../utils/Hook/useRedirectIfAuthenticated";

export const ForgotPass = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [userData, setUserData] = useState({});

  const { state } = useLocation();

  // console.log("state ===",state);

  const forgot_mail_send = async () => {
    if (
      !EmailValidation.test(userData?.email) ||
      (state === "employee" && !userData?.emp_code)
    ) {
      const obj = { ...userData };
      if (
        !userData?.email ||
        (userData?.email && userData?.email?.length == 0)
      ) {
        obj.emailmess = "Email is required";
      } else if (!EmailValidation.test(userData?.email)) {
        obj.emailmess = "Please Enter a Valid Email";
      }

      if (state === "employee" && !userData?.emp_code) {
        obj.emp_codemess = "Employee Id is required";
      } else if (userData?.emp_code?.length < 6) {
        obj.emp_codemess = "Employee Id must be greaterthen 6 digit";
      }
      setUserData(obj);
    } else {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", state);
        body.append("email", userData?.email);
        if (state === "employee") {
          body.append("emp_code", userData?.emp_code);
        }
        const responce = await forgot_password_email_api(body);
        setShowLoader(false);
        if (responce.success) {
          toast.success(responce.message);
          if (state === "employee") {
            navigate("/employee-login");
          } else {
            navigate("/login");
          }
        } else if (!responce.success) {
          toast.error(responce.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useRedirectIfAuthenticated();

  return (
    <AuthBg>
      <div className="login-inner-div" style={{ maxWidth: "500px" }}>
        <div className="sidebar-logo aic jcc ">
          <img src={logoicon} className="s_logo" />
          <img src={logotxt} className="s_logotxt" />
        </div>
        <div className="login-text ">Forgot Password</div>
        <div className="forgot-content">
          Enter your {state === "employee" ? "Employee Code and " : ""}
          Registered Email Address, and we will send you a link to reset your
          password.
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: " 1rem 0",
            flexDirection: "column",
          }}
        >
          {state == "employee" && (
            <Input
              label={"Employee Id"}
              placeholder={"Employee Id"}
              value={userData.emp_code}
              onChange={(e) => {
                const input = e.target.value
                  .toUpperCase()
                  .replace(/[^a-zA-Z0-9]/g, "");
                if (input.length <= 10) {
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    emp_code: input,
                    emp_codemess: "",
                    emp_codeval: false,
                  }));
                }
              }}
              w={"80%"}
              error={userData.emp_codemess}
              bcolor={userData.emp_codeval}
            />
          )}

          <Input
            label={"Enter Email"}
            placeholder={"Enter Email"}
            value={userData.email}
            onChange={(e) =>
              setUserData({
                ...userData,
                email: e.target.value,
                emailmess: "",
                emailval: false,
              })
            }
            w={"80%"}
            error={userData.emailmess}
            bcolor={userData.emailval}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0 20px 0",
          }}
        >
          <Button
            title={"SEND PASSWORD RESET LINK"}
            onClick={forgot_mail_send}
          />
        </div>
        <hr />
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-start",
            gap: "10px",
            justifyContent: "flex-start",
            padding: "1rem 0 1rem 1rem",
          }}
          onClick={() => navigate("/login")}
        >
          <FiArrowLeft size={20} color="#000" />
          <div
            style={{
              color: "#000",
              fontSize: "16px",
            }}
          >
            Back to Login
          </div>
        </div>
      </div>
      {showLoader && <Loader loading={showLoader} />}
    </AuthBg>
  );
};
