import React, { useEffect, useRef, useState } from "react";
import "./button.css";
import { MdAdd } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineDown } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export const AddButton = ({ back, onClick }) => {
  return (
    <div className={`btn btn-success`} role="button" onClick={onClick}>
      <MdAdd className="btn-icon" size={20} color="#fff" />
      ADD
    </div>
  );
};

export const BackButton = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <div className={`btn btn-info`} role="button" onClick={() => navigate(-1)}>
      <IoArrowBack className="btn-icon" size={20} color="#fff" />
      BACK
    </div>
  );
};

export const SaveButton = ({ onClick, class1 = "", disable,title }) => {
  return (
    <div
      className={`btn btn-save ${class1}`}
      style={{
        cursor: disable && "default",
        backgroundColor: disable ? "gray" : "",
        border: disable ? "none" : "",
      }}
      role="button"
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
    >
     {title?title?.toUpperCase(): "SAVE"} 
    </div>
  );
};
export const Button = ({ title, icon, mt, onClick, bgColor,disabled=false }) => {
  return (
    <div
      className={`btn btn-success`}
      style={{ marginTop: mt, backgroundColor: disabled? "" :bgColor, borderColor:  disabled? "" :bgColor,
        //  cursor:disabled?"not-allowed":"pointer"
         }}
      role="button"
      onClick={()=>{
        onClick()
        // if(!disabled){
        //   onClick()
        // }
      }}
    >
      {icon}
      {title}
    </div>
  );
};
export const SubmitButton = ({ title, icon, mt, onClick }) => {
  return (
    <div
      className="submit-btn"
      style={{ marginTop: mt }}
      role="button"
      onClick={onClick}
    >
      {icon}
      {title}
    </div>
  );
};

export const HelpButton = ({ link, Hindi = true }) => {
  const [HelpClick, setHelpClick] = useState(false);
  const helpRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (helpRef.current && !helpRef.current.contains(event.target)) {
        setHelpClick(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="Help" ref={helpRef}>
      <button className="RP-help" onClick={() => setHelpClick(!HelpClick)}>
        <pre>
          HELP{" "}
          <AiOutlineDown style={{ fontSize: "12px", fontWeight: "1000" }} />
        </pre>{" "}
        {/* <option hidden></option> */}
      </button>
      {HelpClick && (
        <div className="Help-content">
          <a className="help-content-item" href={link[0]} target="_blank">
            English
          </a>
          {Hindi && (
            <a className="help-content-item" href={link[1]} target="_blank">
              Hindi
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export const EditButton = ({ onClick,bg }) => {
  return (
    <div  onClick={onClick} className="edit-btn">
      <FiEdit size={20} marbo color={bg} />
    </div>
  );
};


