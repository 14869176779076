import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../../components/PageHeading";
import Input from "../../../components/inputs/Input";
import Modal from "../../../components/modal/Modal";
import Dropdown from "../../../components/inputs/Dropdown";
import { motion } from "framer-motion";
import {
  get_bank_master_list,
  get_equipment_list,
  get_nc_dbc_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import Table from "../../../components/table/Table";
import { BankCodeFinder, DeleteIcon } from "../../../utils/utils";
import {
  DateValidator2,
  gst_calculate,
  UnitFunction,
} from "../../../utils/validation";
import moment from "moment";
import Loader from "../../../components/Loader";
import SmallModal from "../../../components/modal/SmallModal";
import PageLoading from "../../../components/PageLoading";
import NoData from "../../../components/NoData";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { itv_rc_data_API } from "../../../utils/apis";
import { toast } from "react-toastify";
import {
  get_itv_rc_data_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { DropInput } from "../../../components/inputs/DropInput";
import { Alerts } from "../../../components/Alerts/Alerts";
import ReactToPrint from "react-to-print";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import { UOMData } from "../../../utils/data";
import { get_home_data } from "../../profile/UsersSlice";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";
import TaxInvoice5 from "../../OtherComponent/TaxInvoice/TaxInvoice5";
const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Admin Amount", cols: false },
  { title: "DGCC Amount", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];

export const ITVRC = () => {
  const previewRef = useRef(null);
  const [isAdd, setIsAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [addDataHead, setAddDataHead] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [entries, setEntries] = useState("5");
  const [DeleteId, setDeleteId] = useState("");
  const [viewItem, setViewItem] = useState({});
  const [openView, setOpenView] = useState(false);
  const [open3, setOpen3] = useState(false);
  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };

  console.log("viewItem -===", viewItem);

  const { godown_address_list } = useSelector((state) => state.profile);

  const { staff_list, equipment_list, bank_master_list } = useSelector(
    (state) => state.masterdata
  );
  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);
  const { user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const { transition_complete_list, itv_rc_data_list, itv_rc_data_loading } =
    useSelector((state) => state.transactions);
  const { day_end_date, home_data } = useSelector((state) => state.profile);
  useEffect(() => {
    store.dispatch(get_staff_list());
    store.dispatch(get_nc_dbc_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_equipment_list());
    store.dispatch(get_bank_master_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_itv_rc_data_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const handleView = (item) => {
    setViewItem(item);
    setOpenView(true);
  };
  const validate_new = (type) => {
    const data = [];
    if (
      !addDataHead?.inv_date ||
      (addDataHead?.inv_date && addDataHead?.inv_date?.length == 0)
    ) {
      data.push("Please enter INVOICE DATE");
    } else if (
      DateValidator2(
        moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
        today,
        day_end_date
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.inv_date).format("DD/MM/YYYY"),
          today,
          day_end_date,
          `Invoice Date Cant be below as a day end date ${day_end_date}`
        )
      );
    } else if (
      type != "invoicenumber" &&
      // type != "calculate" &&
      type != "fetchdgcc" &&
      (!addDataHead?.inv_no ||
        (addDataHead?.inv_no && addDataHead?.inv_no?.length == 0))
    ) {
      data.push("Please fetch  Invoice Number First ");
    }
    if (
      (type == "invoicenumber" || !type) &&
      (!addDataHead?.customer_name ||
        (addDataHead?.customer_name && addDataHead?.customer_name?.length == 0))
    ) {
      data.push("Please Enter CUSTOMER NO/NAME and then fetch data");
    }

    if (
      (type == "fetchdgcc" || !type) &&
      (!addDataHead?.product_code ||
        (addDataHead?.product_code && addDataHead?.product_code?.length == 0))
    ) {
      data.push("Please Select Product");
    }
    return data;
  };
  const fetch_deposit = async () => {
    const data = validate_new("fetchdgcc");
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "fetchdgcc");
        body.append("agencyid", user_id);
        body.append("invoice_date", addDataHead?.inv_date);
        body.append("PROD_CODE", addDataHead?.product_code);
        const response = await itv_rc_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddDataHead({
            ...addDataHead,
            dgcc_amt: response?.services?.[0]?.PROD_CHARGES,
            dgcc_gst_p: response?.services?.[0]?.GST_AMT,
            admin_charge_gst: 18,
            unit_rate: response?.product?.[0]?.BASE_PRICE,
            p_gst: response?.product?.[0]?.GSTP,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const fetch_invoice = async () => {
    const data = validate_new("invoicenumber");
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "invoicenumber");
        body.append("agencyid", user_id);
        body.append("date", addDataHead?.inv_date);
        body.append("customer", addDataHead?.customer_id);
        const response = await itv_rc_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddDataHead({
            ...addDataHead,
            inv_no: response?.invoice_number,
            inv_dis: true,
          });
        } else if (!response.success) {
          setMess(response.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const onDelete_item = async () => {
    try {
      setShowLoader(true);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("id", DeleteId);
      if (emp_id) {
        body.append("emp_code", emp_id);
      }
      const response = await itv_rc_data_API(body);
      setShowLoader(false);
      if (response?.success) {
        setPageNo(
          itv_rc_data_list?.length < 2 && pageNo != "1" ? pageNo - 1 : pageNo
        );
        store.dispatch(
          get_itv_rc_data_list({
            page:
              itv_rc_data_list?.length < 2 && pageNo != "1"
                ? pageNo - 1
                : pageNo,
            limit: entries,
            setTotalCount: setTotalCount,
            setSearchCount: setSearchCount,
            search: SerchInput,
          })
        );
        toast(response?.message);
        setOpen(false);
      } else {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validate_calculate = () => {
    const data = [...validate_new("calculate")];
    if (
      !addDataHead?.dgcc_amt ||
      (addDataHead?.dgcc_amt && addDataHead?.dgcc_amt?.length == 0)
    ) {
      data.push("Please Fetch Imprest Amount first");
    }
    if (
      !addDataHead?.cylinder_no ||
      (addDataHead?.cylinder_no && addDataHead?.cylinder_no?.length == 0)
    ) {
      data.push("Please Enter Cylinder Quantity");
    } else if (addDataHead?.cylinder_no > 2 || addDataHead?.cylinder_no < 0) {
      data.push("NO. OF CYL Must be 1 or 2");
    }
    if (
      !addDataHead?.regulator_no ||
      (addDataHead?.regulator_no && addDataHead?.regulator_no?.length == 0)
    ) {
      data.push("Please Enter Regulator Quantity");
    } else if (addDataHead?.regulator_no > 2) {
      data.push("NO. OF REGULATORS Must Be 0 or 1");
    }
    if (
      !addDataHead?.cylinder_deposit ||
      (addDataHead?.cylinder_deposit &&
        addDataHead?.cylinder_deposit?.length == 0)
    ) {
      data.push("Please Enter Cylinder Deposit");
    } else if (
      addDataHead?.cylinder_deposit > 1000000 ||
      addDataHead?.cylinder_deposit < 0
    ) {
      data.push(
        "CYLINDER DEPOSIT must be less than 10,00,000 and greater than 0"
      );
    }
    if (
      !addDataHead?.regulator_deposit ||
      (addDataHead?.regulator_deposit &&
        addDataHead?.regulator_deposit?.length == 0)
    ) {
      data.push("Please enter REGULATOR DEPOSIT.Enter 0 if not available.");
    } else if (
      addDataHead?.regulator_no == 0 &&
      addDataHead?.regulator_deposit > 0
    ) {
      data.push("REGULATOR DEPOSIT must be 0 for Zero NO OF REGULATORS.");
    } else if (
      addDataHead?.regulator_deposit > 100000 ||
      addDataHead?.regulator_deposit < 0
    ) {
      data.push("REGULATOR DEPOSIT must be <10000 and >=0");
    }
    if (
      !addDataHead?.admin_charge ||
      (addDataHead?.admin_charge && addDataHead?.admin_charge?.length == 0)
    ) {
      data.push("Please select Admin Charges.");
    }
    return data;
  };

  const validate = () => {
    const data = [...validate_calculate()];
    if (
      !addDataHead?.payment_terms ||
      (addDataHead?.payment_terms && addDataHead?.payment_terms?.length == 0)
    ) {
      data.push("Please Select Payment Terms first");
    } else if (addDataHead?.payment_terms == "1") {
      if (
        !addDataHead?.cash_received ||
        (addDataHead?.cash_received && addDataHead?.cash_received?.length == 0)
      ) {
        data.push("Please enter CASH RECEIVED");
      }
    } else if (addDataHead?.payment_terms == "3") {
      if (
        !addDataHead?.cash_received ||
        (addDataHead?.cash_received && addDataHead?.cash_received?.length == 0)
      ) {
        data.push("Please enter CASH RECEIVED");
      }
      if (
        !addDataHead?.online_rcvd ||
        (addDataHead?.online_rcvd && addDataHead?.online_rcvd?.length == 0)
      ) {
        data.push("Please enter AMOUNT RECEIVED ONLINE");
      }
      if (
        addDataHead?.online_rcvd > 0 &&
        (!addDataHead?.bank_acc ||
          (addDataHead?.bank_acc && addDataHead?.bank_acc?.length == 0))
      ) {
        data.push("Please Select Bank Account Number");
      }
      if (
        (!addDataHead?.cash_recieved_from ||
          addDataHead?.cash_recieved_from == "") &&
        addDataHead?.cash_received > 0
      ) {
        data.push("Please Select a Cash Recieved From");
      }
    }

    if (
      !addDataHead?.staff_code ||
      (addDataHead?.staff_code && addDataHead?.staff_code?.length == 0)
    ) {
      data.push("Please Select Staff Name");
    }
    if (
      godown_address_list?.length > 1 &&
      (!addDataHead?.godown ||
        (addDataHead?.godown && addDataHead?.godown?.length === 0))
    ) {
      data.push("Please Select Dispatch Godown Address");
    }

    return data;
  };

  const calculate = async () => {
    const data = validate_calculate();
    if (data?.length == 0) {
      const p_gst = Number(addDataHead?.p_gst);
      const unit_rate = Number(addDataHead?.unit_rate);
      const dgcc_gst_p = Number(addDataHead?.dgcc_gst_p);
      const dgcc_amt = Number(addDataHead?.dgcc_amt);
      const admin_charge_gst = Number(addDataHead?.admin_charge_gst);
      const admin_charge = Number(addDataHead?.admin_charge);
      const cylinder_no = Number(addDataHead?.cylinder_no);
      const cylinder_deposit = Number(addDataHead?.cylinder_deposit);
      const regulator_deposit = Number(addDataHead?.regulator_deposit);

      const basic_amount = unit_rate * cylinder_no;
      const gst_amount = (basic_amount * p_gst) / 100;

      const dgcc_gst_amt = dgcc_gst_p;
      const admin_gst_amt = (admin_charge * admin_charge_gst) / 100;

      const total =
        cylinder_deposit +
        regulator_deposit +
        dgcc_amt +
        dgcc_gst_amt +
        admin_charge +
        admin_gst_amt +
        basic_amount +
        gst_amount;

      const total_gst_amt = dgcc_gst_amt + admin_gst_amt + gst_amount;

      setAddDataHead({
        ...addDataHead,
        inv_amount: Number(total).toFixed(2),
        gst_amount: Number(total_gst_amt).toFixed(2),
        igst:
          addDataHead?.c_type == "isp" ? Number(total_gst_amt).toFixed(2) : 0,
        sgst:
          addDataHead?.c_type != "isp"
            ? Number(total_gst_amt / 2).toFixed(2)
            : 0,
        cgst:
          addDataHead?.c_type != "isp"
            ? Number(total_gst_amt / 2).toFixed(2)
            : 0,
        cash_received:
          addDataHead?.payment_terms == "1"
            ? Number(total).toFixed(2)
            : addDataHead?.payment_terms == "3" &&
              addDataHead?.cash_received != 0
            ? addDataHead?.cash_received
            : Number(total).toFixed(2),

        online_rcvd:
          addDataHead?.payment_terms == "3"
            ? addDataHead?.cash_received && addDataHead?.cash_received != 0
              ? (
                  Number(total) - Number(addDataHead?.cash_received || 0)
                ).toFixed(2)
              : "0.00"
            : "0.00",
      });
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  const add_items = async (type) => {
    const data1 = validate();
    const data = [...new Set(data1)];

    if (
      data?.length == 0 &&
      Number(home_data?.bank[1]?.ACC_CB) < Number(addDataHead.inv_amount) &&
      !type
    ) {
      setOpen3(true);
      return;
    } else {
      setOpen3(false);
    }
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("SR_NO", addDataHead?.inv_no);
        body.append("RC_DATE", addDataHead?.inv_date);
        body.append("CUSTOMER_NO", addDataHead?.customer_id || "");
        body.append("CUSTOMER_NAME", addDataHead?.customer_name);
        body.append("STAFF_ID", addDataHead?.staff_code);
        body.append("RC_AMOUNT", addDataHead?.inv_amount);
        body.append("PROD_CODE", addDataHead?.product_code);
        body.append(
          "godown_id",
          addDataHead?.godown || godown_address_list?.[0]?.ID || 0
        );
        body.append("NO_OF_CYL", addDataHead?.cylinder_no);
        body.append("NO_OF_REG", addDataHead?.regulator_no);
        body.append("CYL_DEPOSIT", addDataHead?.cylinder_deposit);
        body.append("REG_DEPOSIT", addDataHead?.regulator_deposit);
        body.append("ADMIN_CHARGES", addDataHead?.admin_charge);
        body.append("DGCC_AMOUNT", addDataHead?.dgcc_amt);
        body.append("IGST_AMOUNT", addDataHead?.igst);
        body.append("CGST_AMOUNT", addDataHead?.cgst);
        body.append("SGST_AMOUNT", addDataHead?.sgst);
        body.append("PAYMENT_TERMS", addDataHead?.payment_terms);

        // New Added Perameter start
        if (addDataHead?.payment_terms == "1") {
          body.append("CASH_RECEIVED_AMT", addDataHead?.cash_received);
        } else if (addDataHead?.payment_terms == "3") {
          body.append("CASH_RECEIVED_AMT", addDataHead?.cash_received);
          body.append("ONLINE_AMT", addDataHead?.online_rcvd);
          body.append("TRANSACTION_ID", addDataHead?.transaction_id);
          body.append("CASH_RECEIVED_FROM", addDataHead?.cash_recieved_from);
          body.append("BANK_ACC", addDataHead?.bank_acc);
        }
        if (emp_id) {
          body.append("emp_code", emp_id);
        }

        // New Added Perameter end

        const response = await itv_rc_data_API(body);
        setShowLoader(false);
        if (response.success) {
          setIsAdd(false);
          toast(response.message);
          setAddDataHead({});
          store.dispatch(get_itv_rc_data_list(obj));
          store.dispatch(get_home_data(user_id));
        } else if (!response.success) {
          setOpen1(true);
          setMess(response?.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };
  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);
  return (
    <div className="container">
      <PageHeading
        title={"ITV / RC"}
        right={
          <>
            <AddButton
              onClick={() => {
                setIsAdd(true);
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/_vkck33H-zE",
                "https://youtu.be/0iV0CiU67xc",
              ]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {itv_rc_data_loading ? (
          <PageLoading />
        ) : itv_rc_data_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {itv_rc_data_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">{v?.CUSTOMER_NAME} </div>
                  <RowTxt
                    title={"Invoice No."}
                    value={
                      <ReactToPrint
                        trigger={() => (
                          <div
                            onTouchStart={() => {
                              setViewItem(v);
                            }}
                            onMouseEnter={() => {
                              setViewItem(v);
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setViewItem(v);
                            }}
                          >
                            {v?.SR_NO}
                          </div>
                        )}
                        content={() => previewRef.current}
                        documentTitle={`${v?.CUSTOMER_NAME}-${v?.SR_NO}`}
                      />
                    }
                  />
                  <RowTxt
                    title={"Invoice Date"}
                    value={moment(v?.RC_DATE).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"Invoice Amt."} value={v?.RC_AMOUNT} />
                  <RowTxt title={"Godown Address "} value={v?.GODOWN_NAME} />
                  <RowTxt title={"Staff Name"} value={v?.STAFF_NAME} />
                  <RowTxt
                    title={"Payment Terms "}
                    value={
                      v?.PAYMENT_TERMS == "1"
                        ? "Cash"
                        : v?.PAYMENT_TERMS == "3"
                        ? "Online"
                        : ""
                    }
                  />
                  <RowTxt title={"Cash Recieved"} value={v?.CASH_RECEIVED} />
                  {v?.PAYMENT_TERMS == "3" && (
                    <>
                      <RowTxt
                        title={"Online Recieved"}
                        value={v?.AMOUNT_RECEIVED_ONLINE}
                      />

                      {/* <RowTxt title={"Godown"} value={v?.GODOWN_NAME} /> */}
                      {v?.AMOUNT_RECEIVED_ONLINE == "0.00" ? (
                        ""
                      ) : (
                        <>
                          <RowTxt
                            title={"Bank Account"}
                            value={v?.BANK_ACC_NO}
                          />
                          <RowTxt
                            title={"Transaction Id"}
                            value={v?.TRANSACTION_ID}
                          />
                          <RowTxt
                            title={"RCVD From"}
                            value={
                              v?.CASH_RECEIVED_FROM == "1"
                                ? "Phonepay"
                                : v?.CASH_RECEIVED_FROM == "2"
                                ? "GPay"
                                : v?.CASH_RECEIVED_FROM == "3"
                                ? "Paytm"
                                : v?.CASH_RECEIVED_FROM == "4"
                                ? "Cred"
                                : v?.CASH_RECEIVED_FROM == "5"
                                ? "Others"
                                : ""
                            }
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  className="df jcsb mt10"
                  style={{
                    justifyContent:
                      user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                        ? "flex-end"
                        : "space-between",
                  }}
                >
                  {v?.STATUS_FLAG == "0" ? (
                    <>
                      {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                        <DeleteIcon
                          onClick={() => {
                            setOpen(true);
                            setDeleteId(v?.ID);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    onClick={() => handleView(v)}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn">View</div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={"ITV / RC"}
          body={
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                }}
              >
                <div className="flex aic gap10 credit-limit">
                  <Input
                    label={"INVOICE NUMBER"}
                    placeholder={"INVOICE NUMBER"}
                    value={addDataHead.inv_no}
                    disabled={true}
                  />
                  <Input
                    label={"INVOICE DATE"}
                    placeholder={"INVOICE DATE"}
                    type={"date"}
                    value={addDataHead?.inv_date}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setAddDataHead({
                          ...addDataHead,
                          inv_date: e.target.value,
                        });
                      }
                    }}
                  />
                  <Dropdown
                    width2=""
                    label={"STAFF NAME"}
                    value={addDataHead?.staff_code}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        staff_code: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value=""> SELECT</option>
                        {staff_list?.map((v, i) => (
                          <option key={i} value={v?.ID}>
                            {v?.EMP_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <DropInput
                    label={"CUST NO / CUST NAME"}
                    id="custvend"
                    placeholder={"CUST NO / CUST NAME"}
                    value={addDataHead?.customer_name}
                    onChange={(e) => {
                      const data = transition_complete_list?.Customer?.filter(
                        (v) => v?.ID == e.target.value
                      );
                      setAddDataHead({
                        ...addDataHead,
                        customer_name:
                          data?.length > 0 ? data[0]?.cvo_name : e.target.value,
                        customer_id: data?.length > 0 ? e.target.value : "",
                        c_type: data[0]?.connection_type,
                      });
                    }}
                    // list={transition_complete_list?.Customer?.map((v, i) => (
                    //   <option key={i} value={v?.ID}>
                    //     {v?.cvo_name?.toUpperCase()}
                    //   </option>
                    // ))}
                  />
                  <Dropdown
                    width2=""
                    disabled={addDataHead?.dis_payment}
                    label={"PAYMENT TERMS"}
                    value={addDataHead?.payment_terms}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        payment_terms: e.target.value,
                        cash_received: "",
                        cash_recieved_from: "",
                        online_rcvd: "",
                        bank_acc: "",
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        <option value={"1"}>CASH</option>
                        <option value={"3"}>ONLINE</option>
                      </>
                    }
                  />

                  {addDataHead?.payment_terms && (
                    <Input
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (
                          (inputValue?.length <= 10 &&
                            /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                              inputValue
                            )) ||
                          inputValue === "" ||
                          inputValue === "-" ||
                          !addDataHead?.cash_received
                        ) {
                          setAddDataHead({
                            ...addDataHead,
                            cash_received:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) || inputValue === ""
                                ? inputValue
                                : "",
                            online_rcvd:
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              ) ||
                              inputValue === "" ||
                              inputValue === "-"
                                ? Number(
                                    Number(addDataHead?.inv_amount || 0) -
                                      e.target.value
                                  )?.toFixed(2)
                                : "",
                          });
                        }
                      }}
                      value={addDataHead?.cash_received}
                      label={"CASH RECEIVED"}
                      placeholder={"CASH RECEIVED"}
                    />
                  )}

                  {addDataHead?.payment_terms == "3" && (
                    <>
                      <Input
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (
                            (inputValue?.length <= 10 &&
                              /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                inputValue
                              )) ||
                            inputValue === "" ||
                            inputValue === "-" ||
                            !addDataHead?.online_rcvd
                          ) {
                            setAddDataHead({
                              ...addDataHead,
                              online_rcvd:
                                /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                  inputValue
                                ) || inputValue === ""
                                  ? inputValue
                                  : "",
                              cash_received:
                                /^(\-?(0|[1-9]\d{0,6})(\.\d{0,2})?)$/.test(
                                  inputValue
                                ) ||
                                inputValue === "" ||
                                inputValue === "-"
                                  ? Number(
                                      Number(addDataHead?.inv_amount || 0) -
                                        e.target.value
                                    )?.toFixed(2)
                                  : "",
                            });
                          }
                        }}
                        value={addDataHead?.online_rcvd}
                        label={"AMOUNT RECEIVED ONLINE"}
                        placeholder={"AMOUNT RECEIVED ONLINE"}
                      />
                      <Input
                        disabled={addDataHead?.online_rcvd <= 0}
                        placeholder={"TRANSACTION ID"}
                        label={"TRANSACTION ID"}
                        onChange={(e) => {
                          const inputValue = e.target.value?.toUpperCase();
                          if (
                            inputValue.length <= 15 &&
                            (alfaNumericWithoutSpace.test(inputValue) ||
                              inputValue === "")
                          ) {
                            setAddDataHead({
                              ...addDataHead,
                              transaction_id: alfaNumericWithoutSpace.test(
                                inputValue
                              )
                                ? inputValue
                                : "",
                            });
                          }
                        }}
                        value={addDataHead?.transaction_id}
                      />
                      <Dropdown
                        disabled={addDataHead?.online_rcvd <= 0}
                        width2=""
                        label={"CASH RECIVED FROM"}
                        value={addDataHead?.cash_recieved_from}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            cash_recieved_from: e.target.value,
                          });
                        }}
                        options={
                          <>
                            <option value="">SELECT</option>
                            <option value="1">Phonepay</option>
                            <option value="2">GPay</option>
                            <option value="3">Paytm</option>
                            <option value="4">Cred</option>
                            <option value="5">Others</option>
                          </>
                        }
                      />
                      <Dropdown
                        disabled={Number(addDataHead?.online_rcvd || 0) <= 0}
                        width2=""
                        label={"BANK ACCOUNT"}
                        onChange={(e) => {
                          setAddDataHead({
                            ...addDataHead,
                            bank_acc: e.target.value,
                          });
                        }}
                        value={addDataHead?.bank_acc}
                        options={
                          <>
                            <option value=""> SELECT</option>
                            {bank_master_list
                              ?.filter(
                                (v) =>
                                  v?.BANK_CODE == "TAR ACCOUNT" ||
                                  v?.BANK_CODE == "SAVINGS" ||
                                  v?.BANK_CODE == "CURRENT" ||
                                  v?.BANK_CODE == "OVER DRAFT"
                              )
                              ?.map((v, i) => (
                                <option key={i} value={v?.ID}>
                                  {bankCode(v?.BANK_CODE)} -
                                  {v?.BANK_ACC_NO == "NA"
                                    ? user_id
                                    : v?.BANK_ACC_NO}
                                </option>
                              ))}
                          </>
                        }
                      />
                    </>
                  )}

                  <Input
                    label={"INVOICE AMOUNT"}
                    placeholder={"INVOICE AMOUNT"}
                    disabled={true}
                    value={addDataHead?.inv_amount}
                  />
                  {godown_address_list?.length > 1 && (
                    <Dropdown
                      width2=""
                      label={"Dispatch Godown"}
                      value={addDataHead?.godown}
                      onChange={(e) => {
                        setAddDataHead({
                          ...addDataHead,
                          godown: e.target.value,
                        });
                      }}
                      options={
                        <>
                          <option value={""}>SELECT</option>
                          {godown_address_list?.map((s, i) => (
                            <option key={i} value={s?.ID}>
                              {s?.UNIT_NAME}
                            </option>
                          ))}
                        </>
                      }
                    />
                  )}
                </div>
                <div
                  style={{
                    maxHeight: "20%",
                    overflow: "scroll",
                    display: "flex",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Table
                    headData={[
                      "PRODUCT",
                      "NUMBER OF CYLINDERS",
                      "NUMBER OF REGULATORS",
                      "CYLINDER DEPOSIT",
                      "REGULATOR DEPOSIT",
                      "ADMIN CHARGES",
                      "DGCC AMOUNT",
                      "GST AMOUNT",
                      // "PAYMENT TERMS",
                    ]}
                    body={
                      <tr>
                        <td>
                          <Dropdown
                            value={addDataHead?.product_code}
                            onChange={(e) => {
                              const data = uniqueEquipmentList?.filter(
                                (v) =>
                                  v?.CAT_NAME == "DOMESTIC" &&
                                  v?.PROD_CODE == e.target.value
                              )?.[0];
                              setAddDataHead({
                                ...addDataHead,
                                product_code: e.target.value,
                                p_gst: data ? data?.GSTP : "",
                                dgcc_amt: "",
                                gst_amount: "",
                                igst: "",
                                cgst: "",
                                sgst: "",
                                inv_amount: "",
                              });
                            }}
                            options={
                              <>
                                <option value=""> SELECT</option>
                                {uniqueEquipmentList
                                  ?.filter((v) => v?.CAT_NAME == "DOMESTIC")
                                  ?.map((v, i) => (
                                    <option key={i} value={v?.PROD_CODE}>
                                      {v?.CAT_NAME} - {v?.product_name}
                                    </option>
                                  ))}
                              </>
                            }
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.cylinder_no}
                            onChange={(e) => {
                              if (e.target.value?.length < 2) {
                                setAddDataHead({
                                  ...addDataHead,
                                  cylinder_no:
                                    /^\d+$/.test(e.target.value) ||
                                    e.target.value == ""
                                      ? e.target.value
                                      : "",
                                });
                              }
                            }}
                            placeholder={"No. of Cylinders"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.regulator_no}
                            onChange={(e) => {
                              if (e.target.value?.length < 2) {
                                setAddDataHead({
                                  ...addDataHead,
                                  regulator_no:
                                    /^\d+$/.test(e.target.value) ||
                                    e.target.value == ""
                                      ? e.target.value
                                      : "",
                                });
                              }
                            }}
                            placeholder={"No. of Regulators"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.cylinder_deposit}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !addDataHead?.cylinder_deposit
                              ) {
                                setAddDataHead({
                                  ...addDataHead,
                                  cylinder_deposit:
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )
                                      ? inputValue
                                      : "",
                                  inv_amount: "",
                                  gst_amount: "",
                                  igst: "",
                                  sgst: "",
                                  cgst: "",
                                });
                              }
                            }}
                            placeholder={"Cylinder Deposit"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.regulator_deposit}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                inputValue == 0 ||
                                !addDataHead?.cylinder_deposit
                              ) {
                                setAddDataHead({
                                  ...addDataHead,
                                  regulator_deposit:
                                    /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                      inputValue
                                    )
                                      ? inputValue
                                      : "",
                                  inv_amount: "",
                                  gst_amount: "",
                                  igst: "",
                                  sgst: "",
                                  cgst: "",
                                });
                              }
                            }}
                            placeholder={"Regulator Deposit"}
                          />
                        </td>
                        <td>
                          <Dropdown
                            value={addDataHead?.admin_charge}
                            onChange={(e) => {
                              setAddDataHead({
                                ...addDataHead,
                                admin_charge: e.target.value,
                                gst_amount: "",
                                igst: "",
                                cgst: "",
                                sgst: "",
                                inv_amount: "",
                              });
                            }}
                            options={
                              <>
                                <option value="">SELECT</option>
                                <option value="100">
                                  RECONNECTION CHARGES ( Rs. 100.00/-)
                                </option>
                                <option value="0">NILL ( Rs. 0.00/-)</option>
                              </>
                            }
                          />
                        </td>
                        <td>
                          <Input
                            disabled={true}
                            value={addDataHead?.dgcc_amt}
                            placeholder={"DGCC Amount"}
                          />
                        </td>
                        <td>
                          <Input
                            value={addDataHead?.gst_amount}
                            placeholder={"GST Amount"}
                            disabled={true}
                          />
                        </td>
                        {/* <td>
                          <Input
                            placeholder={"PAYMENT TERMS"}
                            disabled={true}
                            value={addDataHead?.payment_terms}
                          />
                        </td> */}
                        {/* <td>
                          <DeleteIcon />
                        </td> */}
                      </tr>
                    }
                  />
                </div>
                <div
                  className="flex aic gap10"
                  style={{ marginTop: 10, justifyContent: "flex-end" }}
                >
                  <Button
                    title={"FETCH INVOICE NO"}
                    onClick={fetch_invoice}
                    back
                  />
                  <Button
                    title={"FETCH DEPOSITS AND CHARGES"}
                    onClick={fetch_deposit}
                    back
                  />
                  <Button title={"CALCULATE"} onClick={calculate} back />
                  <SaveButton onClick={add_items} />
                </div>
              </div>
            </>
          }
        />
      )}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.CUSTOMER_NAME}-${viewItem?.SR_NO}`}
            />
          }
          isOpen={openView}
          title={viewItem?.CUSTOMER_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "NUMBER OF CYLINDERS",
                  "NUMBER OF REGULATORS",
                  "CYLINDER DEPOSIT",
                  "REGULATOR DEPOSIT	",
                  "ADMIN CHARGES	",
                  "DGCC AMOUNT",
                  "IGST AMOUNT",
                  "CGST AMOUNT",
                  "SGST AMOUNT",
                  "PAYMENT TERMS",
                ]}
                body={
                  <tr>
                    <td>{viewItem?.PRODUCT_NAME}</td>
                    <td>{viewItem?.NO_OF_CYL}</td>
                    <td>{viewItem?.NO_OF_REG}</td>
                    <td>{viewItem?.CYL_DEPOSIT}</td>
                    <td>{viewItem?.REG_DEPOSIT}</td>
                    <td>{viewItem?.ADMIN_CHARGES}</td>
                    <td>{viewItem?.DGCC_AMOUNT}</td>
                    <td>{viewItem?.IGST_AMOUNT}</td>
                    <td>{viewItem?.CGST_AMOUNT}</td>
                    <td>{viewItem?.SGST_AMOUNT}</td>
                    <td>Cash</td>
                  </tr>
                }
              />
            </>
          }
        ></Modal>
      )}
      <div style={{ display: "none" }}>
        <TaxInvoice5
          doc_name={"ITV/RC"}
          previewRef={previewRef}
          terms={false}
          listdata={{
            SR_NO: viewItem?.SR_NO,
            SR_NO_DATE: viewItem?.RC_DATE,
            CVO_NAME: viewItem?.CUSTOMER_NAME,
            CVO_ADDRESS: viewItem?.PLACE_OF_SUPPLY || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.STATE_CODE,
            B_STATE_NAME: viewItem?.B_STATE_NAME,
            CVO_PINCODE: viewItem?.B_PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.CUSTOMER_NAME,
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS || viewItem?.BILLING_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
          }}
          empty_arr_length={9}
          empty_row_height={0}
          table_header={
            <tr>
              <td> SL NO</td>
              <td> ITEM DESCRIPTION</td>
              <td> HSN</td>
              <td style={{ minWidth: "50px" }}>QTY </td>
              <td> RATE/UNIT</td>
              <td>ADMIN CHARGE </td>
              <td>DGCC CHARGE </td>
              <td>GST AMT</td>
              <td style={{ textAlign: "right" }}> AMOUNT</td>
            </tr>
          }
          table_body={
            <>
              <tr className="both">
                <td className="both">1</td>
                <td
                  className="both"
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    minWidth: "230px",
                  }}
                >{`${viewItem?.PRODUCT_NAME}`}</td>
                <td className="both">{viewItem?.CSTEH_NO}</td>
                <td className="both">
                  {viewItem?.NO_OF_CYL} {UnitFunction(viewItem?.UNITS, "title")}
                </td>
                <td className="both">{viewItem?.BASE_PRICE}</td>
                <td className="both">{viewItem?.ADMIN_CHARGES}</td>
                <td className="both">{viewItem?.DGCC_AMOUNT}</td>
                {/* <td className="both">{viewItem?.GSTP} </td> */}
                {/* <td className="both">
                  {((viewItem?.DGCC_AMOUNT + viewItem?.ADMIN_CHARGES) * 18) /
                    100 +
                    (viewItem?.BASE_PRICE * viewItem?.GSTP) / 100}{" "}
                </td> */}
                <td className="both">
                  {/* {(
                    ((Number(viewItem?.DGCC_AMOUNT) +
                      Number(viewItem?.ADMIN_CHARGES)) *
                      18) /
                      100 +
                    (Number(viewItem?.BASE_PRICE) * Number(viewItem?.GSTP)) /
                      100
                  ).toFixed(2)} */}
                  {Number(viewItem?.GST_AMOUNT).toFixed(2)}
                </td>

                <td className="both" style={{ textAlign: "right" }}>
                  {Number(viewItem?.TOTAL_AMOUNT).toFixed(2)}
                </td>
              </tr>
            </>
          }
          Total_tax={Number(
            (Number(viewItem?.IGST_AMOUNT) || 0) +
              (Number(viewItem?.CGST_AMOUNT) || 0) +
              (Number(viewItem?.SGST_AMOUNT) || 0)
          ).toFixed(2)}
          net_total={Number(viewItem?.TOTAL_AMOUNT).toFixed(2)}
          deposite={
            Number(viewItem?.CYL_DEPOSIT) + Number(viewItem?.REG_DEPOSIT)
          }
          taxable_amt={Number(
            Number(viewItem?.TOTAL_AMOUNT) - Number(viewItem?.GST_AMOUNT)
          ).toFixed(2)}
          igst={viewItem?.IGST_AMOUNT || "0.00"}
          cgst={viewItem?.CGST_AMOUNT || "0.00"}
          sgst={viewItem?.SGST_AMOUNT || "0.00"}
          other={"0.00"}
          total_amt={Number(viewItem?.RC_AMOUNT).toFixed(2)}
        />
      </div>

      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}

      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {open3 && (
        <SmallModal
          bname={"SUBMIT"}
          title={"Confirm"}
          isOpen={open3}
          setIsOpen={setOpen3}
          mess={
            "YOUR SV/TV ACCOUNT HAVE NO SUFFICIENT BALANCE TO COMPLETE THIS TRANSACTION. DO YOU WANT TO CONTINUE?"
          }
          onClick={() => {
            add_items(true);
          }}
        />
      )}
      {showLoader && <Loader loading={showLoader} />}
    </div>
  );
};
