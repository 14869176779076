import React, { useEffect, useMemo, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import Button from "../../../components/btn/Button";
import { HiOutlineArrowRight } from "react-icons/hi";
import RowTxt from "../../../components/RowTxt";
import { motion } from "framer-motion";
import Modal from "../../../components/modal/Modal";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import RadioBtn from "../../../components/radio/RadioBtn";
import { useSelector } from "react-redux";
import PageLoading from "../../../components/PageLoading";
import { BankCodeFinder, DeleteIcon, Radio } from "../../../utils/utils";
import moment from "moment";
import Table from "../../../components/table/Table";
import {
  AddButton,
  BackButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { other_purchase_sales_APi } from "../../../utils/apis";
import { toast } from "react-toastify";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { CVOList } from "../../OtherComponent/StaticLists/staticLists";
import { AHData, MHData, UOMData } from "../../../utils/data";
import { DateValidator2, HSNValidator } from "../../../utils/validation";
import store from "../../../redux/store";
import {
  get_bank_master_list,
  get_customer_vender_list,
  get_staff_list,
} from "../../MasterData/masterdataSlice";
import { DropInput } from "../../../components/inputs/DropInput";
import {
  get_other_purchase_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { get_hsn_list } from "../../../redux/slice/SlideSlice";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import { Alerts } from "../../../components/Alerts/Alerts";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";

export const OtherPurchase = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState([]);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const navigate = useNavigate();
  const newData = useLocation();

  const { showData, itc } = newData?.state;
  const { eff_date, user_id, today, hsn_list, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const { cust_vend_list, staff_list, bank_master_list } = useSelector(
    (state) => state?.masterdata
  );
  const {
    transition_complete_list,
    other_purchase_list,
    other_purchase_loading,
  } = useSelector((state) => state.transactions);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
    showData: showData,
    itc: itc,
  };
  const { godown_address_list, day_end_date, home_data } = useSelector(
    (state) => state.profile
  );

  const fetchData = () => {
    store.dispatch(get_other_purchase_list(obj));
  };
  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  useEffect(() => {
    store.dispatch(get_customer_vender_list());
    store.dispatch(get_staff_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_transition_complete_list());
  }, []);

  const onDelete_item = async () => {
    setLoading(true);
    setOpen(false);
    const body = new FormData();
    body.append("agencyid", user_id);
    body.append("id", id);
    if (showData == "2") {
      body.append("action", "delete");
      body.append("NO", "2");
      // body.append("itc_type", "-1");
    } else if (showData == "1") {
      body.append("action", "deleteother");
      body.append("YES", "1");
      if (itc == "0") {
        body.append("eligible", itc);
        // body.append("itc_type", "0");
      } else if (itc == "1") {
        body.append("ineligible", itc);
        // body.append("itc_type", "1");
      }
    }
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    const responce = await other_purchase_sales_APi(body);
    setLoading(false);
    if (responce.success) {
      toast.success(responce.message);
      store.dispatch(get_other_purchase_list(obj));
    } else if (!responce.success) {
      setMess(responce.message);
      setOpen1(true);
    }
  };

  const calculate = () => {
    if (!addDataHead?.vender_name) {
      setMess("Please select VENDOR NAME");
      setOpen1(true);
      return;
    }
    // if (
    //   (!addDataHead?.tds_applicable || addDataHead?.tds_applicable == "") &&
    //   showData == "2"
    // ) {
    //   setMess("Please select TDS APPLICABLE");
    //   setOpen1(true);
    //   return;
    // }
    if (
      addData.find(
        (v) =>
          !v.unit_rate ||
          (v.unit_rate && v.unit_rate.length == 0) ||
          !v.quantity ||
          (v.quantity && v.quantity.length == 0)
      )
    ) {
      setMess(`${showData == "1" ? "Please Select GST %" : ""}
  Please Enter UNIT RATE.
  Please Enter QUANTITY.`);
      setOpen1(true);
      return;
    }
    if (addDataHead?.c_type?.length == 0 && showData != "2") {
      setMess(`Please Select Sale Type`);
      setOpen1(true);
    } else {
      var total = 0;
      addData.forEach((data, index) => {
        const unit_rate = Number(data?.unit_rate);
        const quantity = Number(data?.quantity);
        const p_gst = Number(data?.p_gst || 0);

        const tax_amount = unit_rate * quantity;
        const gst_amount = tax_amount * (p_gst / 100);
        // const tds_amt =
        //   addDataHead?.tds_applicable == "1" && showData == "2"
        //     ? tax_amount * (0.1 / 100)
        //     : 0;
        const amount = gst_amount + tax_amount - 0;

        if (showData == "1") {
          if (addDataHead?.c_type === "isp") {
            setAddData((prevData) => {
              return prevData.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    tax_amount: Number(tax_amount).toFixed(2),
                    igst_amount: Number(gst_amount).toFixed(2),
                    cgst_amount: "0.0",
                    sgst_amount: "0.0",
                    amount: Number(amount).toFixed(2),
                  };
                }
                return item;
              });
            });
          } else if (addDataHead?.c_type === "lp") {
            setAddData((prevData) => {
              return prevData.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    tax_amount: Number(tax_amount).toFixed(2),
                    cgst_amount: Number(gst_amount / 2).toFixed(2),
                    sgst_amount: Number(gst_amount / 2).toFixed(2),
                    igst_amount: "0.0",
                    amount: Number(amount).toFixed(2),
                  };
                }
                return item;
              });
            });
          }
        } else {
          setAddData((prevData) => {
            return prevData.map((item, i) => {
              if (i === index) {
                return {
                  ...item,
                  tax_amount: Number(tax_amount).toFixed(2),
                  amount: Number(amount).toFixed(2),
                  // tds_amt: Number(0).toFixed(2),
                };
              }
              return item;
            });
          });
        }
        total += amount;
      });
      setAddDataHead({ ...addDataHead, inv_amount: Number(total).toFixed(2) });
    }
  };

  const validation_check = () => {
    if (showData == "2") {
      if (
        addData.find(
          (v) =>
            !v?.expance_item ||
            (v?.expance_item && v?.expance_item?.trim().length == 0) ||
            !v?.minor_head ||
            (v?.minor_head && v?.minor_head.length == 0) ||
            !v?.account_head ||
            (v?.account_head && v?.account_head.length == 0) ||
            !v?.uom ||
            (v?.uom && v?.uom.length == 0) ||
            !v?.unit_rate ||
            (v?.unit_rate && v?.unit_rate.length == 0) ||
            !v?.quantity ||
            (v?.quantity && v?.quantity.length == 0) ||
            !v?.tax_amount ||
            (v?.tax_amount && v?.tax_amount.length == 0) ||
            !v?.payment_terms ||
            (v?.payment_terms && v?.payment_terms.length == 0) ||
            (v?.payment_terms != "1" &&
              (!v?.bank_account ||
                (v?.bank_account && v?.bank_account.length == 0)))
        )
      ) {
        return true;
      }
    } else if (showData == "1") {
      if (
        addData.find(
          (v) =>
            !v?.expance_item ||
            (v?.expance_item && v?.expance_item?.trim().length == 0) ||
            !v?.hsn ||
            (v?.hsn && v?.hsn.length == 0) ||
            !v?.p_gst ||
            (v?.p_gst && v?.p_gst.length == 0) ||
            !v?.unit_rate ||
            (v?.unit_rate && v?.unit_rate.length == 0) ||
            !v?.quantity ||
            (v?.quantity && v?.quantity.length == 0) ||
            !v?.tax_amount ||
            (v?.tax_amount && v?.tax_amount.length == 0)
        )
      ) {
        return true;
      }
    }
  };

  const add_items = async (type) => {
    const data1 = await valiDate();
    const data = [...new Set(data1)];

    if (data.length == 0) {
      if (
        Number(home_data?.bank?.[0]?.ACC_CB) <
          Number(addDataHead?.inv_amount) &&
        !type &&
        showData == "1"
      ) {
        setOpen3(true);
        return;
      } else {
        setOpen3(false);
        try {
          setLoading(true);
          const body = new FormData();
          body.append("agencyid", user_id);
          if (showData == "1") {
            body.append("action", "other");
            body.append("rev", itc == "1" ? "2" : addDataHead?.rev_charge);
            body.append("connection_type", addDataHead?.c_type);
            body.append("vendor_id", addDataHead?.vender_id);
            body.append("vender_name", addDataHead?.vender_name);
            addData.forEach((v) => {
              body.append("gst[]", v?.p_gst);
              body.append("hsn[]", v?.hsn);
              body.append("bamt[]", v?.tax_amount);
              body.append("pamt[]", v?.amount);
              body.append("igst[]", v?.igst_amount);
              body.append("sgst[]", v?.sgst_amount);
              body.append("cgst[]", v?.cgst_amount);
              body.append("qty[]", v?.quantity);
              body.append("urate[]", v?.unit_rate);
            });
            body.append("YES", "1");
            if (itc == 0) {
              body.append("eligible", "0");
            } else if (itc == "1") {
              body.append("ineligible", "1");
            }
          } else if (showData == "2") {
            body.append("action", "create");
            // body.append("tds_applicable", addDataHead?.tds_applicable);
            // body.append("tcs_flag", addDataHead?.tds_applicable);

            addData.forEach((v) => {
              body.append("quantity[]", v?.quantity);
              body.append("pamt[]", v?.amount);
              // body.append("payment_mode[]", "cash");
              body.append("unit_rate[]", v?.unit_rate);
              // body.append("tds_amt[]", v?.tds_amt);
              // body.append("tcs_amt[]", v?.tds_amt);
              body.append("payment_mode[]", v?.payment_terms);
              body.append("narration[]", v?.narration);
              body.append("staff_id[]", v?.emp_code);
              body.append("bank_account[]", v?.bank_account);
            });
            body.append("NO", "2");
            body.append("vendor_id", "Expences");
          }
          body.append("ref_no", addDataHead?.ref_no);
          body.append(
            "inv_date",
            moment(addDataHead?.voucher_date).format("YYYYMMDD")
          );
          if (emp_id) {
            body.append("emp_code", emp_id);
          }

          addData.forEach((v) => {
            body.append("pname[]", v?.expance_item?.trim());
            body.append("mh[]", v?.minor_head);
            body.append("ah[]", v?.account_head);
            body.append("uom[]", v?.uom);
          });
          const res = await other_purchase_sales_APi(body);
          setLoading(false);
          if (res.success) {
            setIsAdd(false);
            store.dispatch(get_other_purchase_list(obj));
            setAddData([]);
            setAddDataHead({});
            toast(res.message);
          } else {
            setMess(res.message);
            setOpen1(true);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const HeadDataNoVoucher = [
    "EXPENSE ITEM",
    "MINOR HEAD",
    "ACCOUNT HEAD",
    "QTY",
    "UOM",
    "UNIT RATE",
    // "TDS",
    "TOTAL VALUE",
    "PAYMENT TERMS",
    "BANK ACCOUNT",
    "RECIEVED BY",
    "NARRATION",
    "CREATED BY",
    "ACTIONS",
  ];
  const HeadDataYesVoucher = [
    "PRODUCT",
    "HSN/SAC CODE",
    "GST%",
    "MINOR HEAD",
    "ACCOUNT HEAD",
    "QTY",
    "UOM",
    "UNIT RATE",
    "TAXABLE VALUE",
    "IGST AMOUNT",
    "CGST AMOUNT",
    "SGST AMOUNT",
    "CREATED BY",
    "ACTIONS",
  ];

  const FetchVoucherNumber = async () => {
    const data = () => {
      if (
        !addDataHead.voucher_date ||
        (addDataHead.voucher_date && addDataHead.voucher_date.length == 0)
      ) {
        return true;
      } else {
      }
    };

    if (data() != true) {
      const body = new FormData();
      body.append("action", "vouchernumber");
      body.append("agencyid", user_id);
      body.append("date", addDataHead?.voucher_date);
      const response = await other_purchase_sales_APi(body);
      if (response.success) {
        setAddDataHead({ ...addDataHead, ref_no: response.Voucher_Number });
      }
    } else {
      setOpen1(true);
      setMess("Please Enter Voucher Date");
    }
  };

  const valiDate = async () => {
    const data = [];

    if (
      !addDataHead?.ref_no ||
      (addDataHead?.ref_no && Number(addDataHead?.ref_no) == 0)
    ) {
      data.push("Please Enter Invoice Number");
    }
    const data1 = DateValidator2(
      moment(addDataHead?.voucher_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `${
        showData == "1" ? "INVOICE" : "VOUCHER"
      } date cant be before as day end date ${day_end_date}`,
      `${showData == "1" ? "INVOICE" : "VOUCHER"} date cant be a future date`
    );
    if (
      !addDataHead?.voucher_date ||
      (addDataHead?.voucher_date && addDataHead?.voucher_date?.length === 0)
    ) {
      data.push("Please Enter Invoice Date");
    } else if (data1 != true) {
      data.push(...data1);
    }
    if (
      !addDataHead?.vender_name ||
      (addDataHead?.vender_name && addDataHead?.vender_name?.length === 0)
    ) {
      data.push("Please Enter VENDOR NAME");
    }
    if (showData == "1" && itc == "1") {
      if (addDataHead?.ref_no?.length < 3) {
        data.push("INVOICE REFERENCE NO Must Contains Atleast 3 Characters");
      }
      if (
        !addDataHead?.rev_charge ||
        (addDataHead?.rev_charge && addDataHead?.rev_charge?.length === 0)
      ) {
        data.push("Please Select Reverse Charger");
      }
      if (
        !addDataHead?.c_type ||
        (addDataHead?.c_type && addDataHead?.c_type?.length === 0)
      ) {
        data.push("Please Select SALE TYPE");
      }
    }
    // if (
    //   showData == "2" &&
    //   (!addDataHead?.tds_applicable ||
    //     (addDataHead?.tds_applicable &&
    //       addDataHead?.tds_applicable?.length === 0))
    // ) {
    //   data.push("Please Select TDS Applicable or not");
    // }

    for (let v of addData) {
      if (
        !v?.expance_item ||
        (v?.expance_item && v?.expance_item?.trim()?.length === 0)
      ) {
        data.push("Please Select PRODUCT/Expences Item ");
      } else if (v?.expance_item?.trim()?.length < 5) {
        data.push("PRODUCT/Expences Item must contain min 5 Charectors ");
      }
      if (
        (itc != 0 || !itc) &&
        (!v?.minor_head || (v?.minor_head && v?.minor_head?.length === 0))
      ) {
        data.push("Please Select Minor Head");
      }
      if (
        !v?.account_head ||
        (v?.account_head && v?.account_head?.length === 0)
      ) {
        data.push("Please Select Account Head");
      }
      if (!v?.quantity || (v?.quantity && v?.quantity?.length === 0)) {
        data.push("Please Enter Quantity");
      } else if (v?.quantity == 0) {
        data.push("Please Enter valid Quantity");
      }
      if (!v?.uom || (v?.uom && v?.uom?.length === 0)) {
        data.push("Please Select UOM");
      }
      if (!v?.unit_rate || (v?.unit_rate && v?.unit_rate?.length === 0)) {
        data.push("Please Enter Unit Price");
      } else if (v?.discount_price == 0) {
        data.push("Please valid Unit Price");
      }

      if (showData == "1") {
        if (!v?.hsn) {
          data.push("Please Enter HSN/ SAC CODE");
        } else if (v?.hsn?.length < 4) {
          data.push("HSN/ SAC Must contain min 4 digit");
        } else if (v?.hsn?.length > 8) {
          data.push("HSN/ SAC Must contain Max 8 digit");
        } else if (v?.hsn?.length > 4 || v?.hsn?.length < 9) {
          await HSNValidator([v], "HSN/ SAC")
            .then((v) => {
              if (v != true) {
                data.push(...v);
              }
            })
            .catch((err) => {
              data.push(err);
            });
        }
        if (
          !v?.tax_amount ||
          (v?.tax_amount && v?.tax_amount?.length === 0) ||
          !v?.igst_amount ||
          (v?.igst_amount && v?.igst_amount?.length === 0) ||
          !v?.cgst_amount ||
          (v?.cgst_amount && v?.cgst_amount?.length === 0) ||
          !v?.sgst_amount ||
          (v?.sgst_amount && v?.sgst_amount?.length === 0) ||
          !v?.amount ||
          (v?.amount && v?.amount?.length === 0) ||
          !addDataHead?.inv_amount ||
          (addDataHead?.inv_amount && addDataHead?.inv_amount?.length == 0)
        ) {
          data.push("Please Click on Calculate First");
        }
      } else {
        if (!v?.tax_amount || (v?.tax_amount && v?.tax_amount?.length === 0)) {
          data.push("Please Click on Calculate First");
        }
        if (
          !v?.payment_terms ||
          (v?.payment_terms && v?.payment_terms?.length === 0)
        ) {
          data.push("Please Select Payment Terms");
        }

        if (
          v?.payment_terms != "1" &&
          (!v?.bank_account ||
            (v?.bank_account && v?.bank_account?.length === 0))
        ) {
          data.push("Please Select Bank Account");
        }
        if (!v?.emp_code || (v?.emp_code && v?.emp_code?.length === 0)) {
          data.push("Please Select Recieved By");
        }
        if (!v?.narration || (v?.narration && v?.narration?.length === 0)) {
          data.push("Please Enter NARRATION");
        }
      }
    }
    return data;
  };

  const bankCode = useMemo(() => {
    return (data) => BankCodeFinder(data);
  }, [bank_master_list]);

  return (
    <div className="container">
      <PageHeading
        title={
          <div style={{ display: "flex" }}>
            EXPENDITURE INVOICE ENTRY{" "}
            <p
              style={{
                display: "flex",
                alignItems: "flex-end",
                fontSize: "14px",
                marginLeft: "5px",
                color: "#83e8eb",
              }}
            >
              {showData == "1" && (
                <>{itc == "0" ? "(ITC Eligible)" : "(ITC InEligible)"}</>
              )}
            </p>
          </div>
        }
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [...prevData, {}]);
                setIsAdd(true);
                if (showData == "2") {
                  setAddDataHead({
                    ...addDataHead,
                    vender_name: "EXPENSES",
                  });
                }
                if (itc == "1") {
                  setAddDataHead({
                    ...addDataHead,
                    rev_charge: "2",
                  });
                }
              }}
            />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              Hindi={false}
              link={["https://www.youtube.com/watch?v=C4JmI9QRwG0"]}
            />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {other_purchase_loading ? (
          <PageLoading />
        ) : other_purchase_list?.length > 0 ? (
          <div className="grid">
            {other_purchase_list?.map((v, i) => (
              <div className="card" key={i}>
                <div className="card-heading">
                  {v?.CVO_NAME ? v?.CVO_NAME : "EXPENSES"}
                </div>
                <RowTxt
                  title={showData == "1" ? "Invoice No." : "Voucher No."}
                  value={v?.INV_REF_NO}
                />
                <RowTxt
                  title={showData == "1" ? "Invoice Date" : "Voucher No."}
                  value={moment(Number(v?.INV_DATE)).format("DD-MMM-YYYY")}
                />
                <RowTxt
                  title={"Total Amount"}
                  value={Number(v?.total_amount)?.toFixed(2)}
                />
                <RowTxt title={"Created By"} value={v?.CREATED_NAME || "-"} />
                <div
                  className="df jcsb mt10"
                  style={{
                    justifyContent:
                      user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                        ? "flex-end"
                        : "space-between",
                  }}
                >
                  {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                    <DeleteIcon
                      onClick={() => {
                        setId(showData == "2" ? v?.INV_REF_NO : v?.ID);
                        setOpen(true);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoData />
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button
                title={"CVO"}
                back
                onClick={() => setCvoClick(!cvoClick)}
              />
            </div>
          }
          title={"EXPENDITURE / OTHER PURCHASES INVOICE ENTRY"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={showData == "1" ? "INV DATE" : "VOUCHER DATE"}
                  type={"date"}
                  value={addDataHead.voucher_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        voucher_date: e.target.value,
                      });
                    }
                  }}
                />
                <Input
                  label={showData == "1" ? "INV REF NO" : "VOUCHER NO"}
                  placeholder={showData == "1" ? "INV REF NO" : "VOUCHER NO"}
                  value={addDataHead.ref_no}
                  disabled={showData == 2}
                  onChange={(e) => {
                    if (e.target.value.length <= 25) {
                      setAddDataHead({
                        ...addDataHead,
                        ref_no: e.target.value,
                      });
                    }
                  }}
                />
                <DropInput
                  label={"VENDOR NAME"}
                  disabled={showData == "2" ? true : false}
                  id="custvend"
                  placeholder={"VENDOR NAME"}
                  value={addDataHead?.vender_name}
                  onChange={(e) => {
                    const data = [
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                    ]?.filter((v) => v?.ID == e.target.value);
                    setAddDataHead({
                      ...addDataHead,
                      vender_name: data[0]?.cvo_name,
                      vender_id: e.target.value,
                      c_type:
                        e.target.value == "" && data?.length == 0
                          ? ""
                          : data[0]?.connection_type,
                      rev_charge:
                        e.target.value != "" && data[0]?.IS_GST_REG == "YES"
                          ? "2"
                          : e.target.value != "" && data[0]?.IS_GST_REG == "NO"
                          ? "1"
                          : "",
                      dis:
                        e.target.value != "" && data?.length > 0 ? true : false,
                    });
                  }}
                  list={cust_vend_list
                    ?.filter(
                      (item) => item?.CVO_CAT == "0" || item?.CVO_CAT == "2"
                    )
                    ?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.cvo_name?.toUpperCase()}
                      </option>
                    ))}
                />
                {showData == "1" && (
                  <Dropdown
                    width2=""
                    label={"REVERSE CHARGE"}
                    disabled={itc == "1" || addDataHead?.dis ? true : false}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        rev_charge: e.target.value,
                      });
                    }}
                    value={addDataHead?.rev_charge}
                    options={
                      <>
                        {itc != "1" && (
                          <>
                            <option value={""}>SELECT</option>
                            <option value={"1"}>YES</option>
                          </>
                        )}
                        <option value={"2"}>NO</option>
                      </>
                    }
                  />
                )}
                {/* {showData == "2" && (
                  <Dropdown
                    width2=""
                    label={"TDS APPLICABLE"}
                    value={addDataHead?.tds_applicable}
                    disabled={addDataHead?.tds_applicable_dis}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        tds_applicable: e.target.value,
                        inv_amount: "",
                      });
                      addData.forEach((data, index) => {
                        setAddData((prevData) => {
                          return prevData.map((item, i) => {
                            if (i === index) {
                              return {
                                ...item,
                                tax_amount: "",
                                amount: "",
                                tds_amt: "",
                              };
                            }
                            return item;
                          });
                        });
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        <option value={"1"}>YES</option>
                        <option value={"2"}>NO</option>
                      </>
                    }
                  />
                )} */}

                <Input
                  label={"INV AMOUNT"}
                  placeholder={"INV AMOUNT"}
                  disabled={true}
                  value={addDataHead.inv_amount}
                />
              </div>
              {showData == "1" && (
                <div className="flex aic gap10 mt10 ">
                  <RadioBtn label={"PURCHASE TYPE"} />
                  <Radio
                    title={"LOCAL PURCHASE"}
                    disabled={addDataHead?.dis}
                    show={addDataHead.c_type == "lp" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, c_type: "lp" })
                    }
                  />
                  <Radio
                    title={"INTER-STATE PURCHASE"}
                    disabled={addDataHead?.dis}
                    show={addDataHead.c_type == "isp" ? true : false}
                    setShow={() =>
                      setAddDataHead({ ...addDataHead, c_type: "isp" })
                    }
                  />
                </div>
              )}

              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={
                    showData == "1" ? HeadDataYesVoucher : HeadDataNoVoucher
                  }
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Input
                            value={val?.expance_item}
                            placeholder={"NAME"}
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        expance_item: e.target.value,
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                          />
                        }
                      </td>
                      {showData == "1" && (
                        <>
                          <td>
                            <DropInput
                              id="hsn"
                              placeholder={"HSN / SAC CODE"}
                              disabled={val?.hsn_disable}
                              onChange={(e) => {
                                if (e.target.value?.length > 1) {
                                  store.dispatch(
                                    get_hsn_list({
                                      search: e.target.value,
                                      type: "both",
                                    })
                                  );
                                }
                                if (e.target.value?.length < 9) {
                                  setAddData((pre) =>
                                    pre.map((v, i) =>
                                      i == ind
                                        ? {
                                            ...v,
                                            hsn: e.target.value,
                                          }
                                        : { ...v }
                                    )
                                  );
                                }
                              }}
                              value={val?.hsn}
                              list={
                                val?.hsn?.length > 1
                                  ? hsn_list?.map((v) => (
                                      <option value={v?.HSNCODE || v?.SACCODE}>
                                        {v?.HSNCODE || v?.SACCODE}
                                      </option>
                                    ))
                                  : ""
                              }
                            />
                          </td>
                          <td>
                            {
                              <Dropdown
                                options={
                                  <>
                                    <option value={""}>SELECT</option>
                                    <option value={"0"}>0</option>
                                    <option value={"5"}>5</option>
                                    <option value={"12"}>12</option>
                                    <option value={"18"}>18</option>
                                    <option value={"28"}>28</option>
                                  </>
                                }
                                onChange={(e) =>
                                  setAddData((pre) =>
                                    pre.map((v, i) =>
                                      i == ind
                                        ? {
                                            ...v,
                                            p_gst: e.target.value,
                                          }
                                        : { ...v }
                                    )
                                  )
                                }
                                value={val.p_gst}
                              />
                            }
                          </td>
                        </>
                      )}
                      <td>
                        {
                          <DropInput
                            id={"minorhead"}
                            placeholder={"Minor Head"}
                            list={
                              <>
                                <option value={""}>SELECT</option>
                                {MHData.map((item, i) => (
                                  <option value={item.MH} key={i}>
                                    {item.title}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) => {
                              const data = MHData.filter(
                                (v) => v?.MH == e.target.value
                              )?.[0];
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        minor_head: e.target.value,
                                        minor_name: data?.title,
                                        account_head: data ? data?.AH : "",
                                        account_head_dis: data ? true : false,
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            value={val.minor_name}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            disabled={val?.account_head_dis}
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {AHData.map((item) => (
                                  <option value={item.AH} key={item.id}>
                                    {item.title}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        account_head: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.account_head}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            value={val.quantity}
                            placeholder={"QUANTITY"}
                            onChange={(e) => {
                              // if (e.target.value?.length < 5) {
                              //   setAddData((pre) =>
                              //     pre.map((v, i) =>
                              //       i == ind
                              //         ? {
                              //             ...v,
                              //             quantity:
                              //               /^\d+$/.test(e.target.value) ||
                              //               e.target.value == "" ||
                              //               !val.quantity
                              //                 ? e.target.value
                              //                 : val.quantity,
                              //             tds_amt: "",
                              //             tax_amount: "",
                              //             amount: "",
                              //             igst_amount: "",
                              //             cgst_amount: "",
                              //             sgst_amount: "",
                              //           }
                              //         : { ...v }
                              //     )
                              //   );
                              // }
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length < 5 &&
                                  /^\d+$/.test(inputValue)) ||
                                inputValue == 0 ||
                                !val?.quantity
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          quantity: /^\d+$/.test(inputValue)
                                            ? inputValue
                                            : "",
                                          // tds_amt: "",
                                          tax_amount: "",
                                          amount: "",
                                          igst_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            options={
                              <>
                                <option value={""}>SELECT</option>
                                {UOMData.map((item) => (
                                  <option value={item.UOM} key={item.id}>
                                    {item.title}
                                  </option>
                                ))}
                              </>
                            }
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        uom: e.target.value,
                                      }
                                    : { ...v }
                                )
                              )
                            }
                            value={val.uom}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"UNIT RATE"}
                            value={val.unit_rate}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length <= 10 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                e.target.value == 0 ||
                                !val?.unit_rate
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          unit_rate:
                                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          // tds_amt: "",
                                          tax_amount: "",
                                          amount: "",
                                          igst_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      {/* {showData == "2" && (
                        <td>
                          {
                            <Input
                              placeholder={"TDS"}
                              value={val?.tds_amt}
                              disabled={true}
                            />
                          }
                        </td>
                      )} */}
                      <td>
                        {
                          <Input
                            placeholder={
                              showData == "1" ? "TAXABLE VALUE" : "TOTAL AMOUNT"
                            }
                            value={
                              showData == "1" ? val?.tax_amount : val?.amount
                            }
                            disabled={true}
                          />
                        }
                      </td>
                      {showData == "2" && (
                        <>
                          <td>
                            <Dropdown
                              onChange={(e) => {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          payment_terms: e.target.value,
                                          bank_account:
                                            e.target.value == "1"
                                              ? ""
                                              : val?.bank_account,
                                        }
                                      : { ...v }
                                  )
                                );
                                // setAddDataHead({
                                //   ...addDataHead,
                                //   tds_applicable:
                                //     e.target.value == "1"
                                //       ? "2"
                                //       : addDataHead?.tds_applicable,
                                //   tds_applicable_dis:
                                //     e.target.value == "1" ? true : false,
                                // });
                              }}
                              value={val?.payment_terms}
                              options={
                                <>
                                  <option value={""}>SELECT</option>
                                  <option value={"1"}>CASH</option>
                                  <option value={"2"}>CHEQUE</option>
                                  <option value={"3"}>ONLINE</option>
                                </>
                              }
                            />
                          </td>
                          <td>
                            <Dropdown
                              onChange={(e) =>
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? { ...v, bank_account: e.target.value }
                                      : { ...v }
                                  )
                                )
                              }
                              disabled={val?.payment_terms == "1"}
                              value={val.bank_account}
                              options={
                                <>
                                  <option value="">SELECT</option>
                                  {bank_master_list
                                    ?.filter(
                                      (v) =>
                                        v?.BANK_CODE == "CURRENT" ||
                                        v?.BANK_CODE == "SAVINGS" ||
                                        v?.BANK_CODE == "OVER DRAFT"
                                    )
                                    ?.map((v, i) => (
                                      <option
                                        disabled={val?.payment_terms == "1"}
                                        key={i}
                                        value={v?.ID}
                                      >
                                        {bankCode(v?.BANK_CODE)} -
                                        {v?.BANK_ACC_NO == "NA"
                                          ? user_id
                                          : v?.BANK_ACC_NO}
                                      </option>
                                    ))}
                                </>
                              }
                            />
                          </td>
                        </>
                      )}
                      {showData == "1" ? (
                        <>
                          <td>
                            {
                              <Input
                                placeholder={"IGST AMOUNT"}
                                value={val.igst_amount}
                                disabled={true}
                              />
                            }
                          </td>
                          <td>
                            {
                              <Input
                                placeholder={"CGST AMOUNT"}
                                value={val.cgst_amount}
                                disabled={true}
                              />
                            }
                          </td>
                          <td>
                            {
                              <Input
                                placeholder={"SGST AMOUNT"}
                                value={val.sgst_amount}
                                disabled={true}
                              />
                            }
                          </td>
                        </>
                      ) : (
                        <td>
                          <Dropdown
                            value={addData?.emp_code}
                            onChange={(e) =>
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? { ...v, emp_code: e.target.value }
                                    : { ...v }
                                )
                              )
                            }
                            options={
                              <>
                                <option value=""> SELECT</option>
                                {staff_list?.map((v, i) => (
                                  <option key={i} value={v?.ID}>
                                    {v?.EMP_NAME}
                                  </option>
                                ))}
                              </>
                            }
                          />
                        </td>
                      )}
                      {showData == "2" && (
                        <td>
                          {
                            <Input
                              placeholder={"NARRATION"}
                              value={val?.narration}
                              onChange={(e) =>
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? { ...v, narration: e.target.value }
                                      : { ...v }
                                  )
                                )
                              }
                            />
                          }
                        </td>
                      )}
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    validation_check() &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    validation_check()
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, {}]);
                  }}
                />
                {showData == "2" && (
                  <Button
                    title={"FETCH VOUCHER NUMBER"}
                    back
                    onClick={FetchVoucherNumber}
                  />
                )}
                <Button title={"CALCULATE"} back onClick={() => calculate()} />
                <SaveButton onClick={() => add_items()} />
              </div>
            </div>
          }
        ></Modal>
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {open3 && (
        <SmallModal
          bname={"SUBMIT"}
          title={"Confirm"}
          isOpen={open3}
          setIsOpen={setOpen3}
          mess={
            "YOUR LOAD ACCOUNT HAVE NO SUFFICIENT BALANCE TO COMPLETE THIS TRANSACTION. DO YOU WANT TO CONTINUE?"
          }
          onClick={() => {
            add_items(true);
          }}
        />
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}

      {openView && (
        <Modal
          isOpen={openView}
          title={"EXPENDITURE INVOICE ENTRY"}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                // nodata={custVendList.length != 0 && !showLoading ? false : true}
                headData={
                  showData == "2"
                    ? [
                        "EXPENSE ITEM",
                        "PAYMENT MODE",
                        "BANK ACCOUNT",
                        "RECIEVED BY",
                        "NARRATION",
                        "MINOR HEAD",
                        "ACCOUNT HEAD",
                        "QUANTITY",
                        "UOM",
                        "UNIT PRICE",
                        // "TDS",
                        "TOTAL VALUE",
                      ]
                    : [
                        "PRODUCT",
                        "HSN/SAC CODE",
                        "GST%",
                        "MINOR HEAD",
                        "ACCOUNT HEAD",
                        "QUANTITY",
                        "UOM",
                        "UNIT PRICE",
                        "TAXABLE VALUE",
                        "IGST AMOUNT",
                        "SGST AMOUNT",
                        "CGST AMOUNT",
                        "TOTAL AMOUNT	",
                      ]
                }
                body={
                  showData == "2" ? (
                    <>
                      {viewItem?.products.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.PNAME}</td>
                          <td>
                            {v?.PAYMENT_MODE == "1"
                              ? "CASH"
                              : v?.PAYMENT_MODE == "2"
                              ? "CHEQUE"
                              : v?.PAYMENT_MODE == "3"
                              ? "ONLINE"
                              : ""}
                          </td>
                          <td>{v?.BANK_ACC_NO || "NA"}</td>
                          <td>{v?.EMP_NAME}</td>
                          <td>{v?.NARRATION}</td>
                          <td>
                            {
                              MHData.filter((item) => item?.MH == v?.MH)[0]
                                ?.title
                            }
                          </td>
                          <td>
                            {
                              AHData.filter((item) => item?.AH == v?.AH)[0]
                                ?.title
                            }
                          </td>
                          <td>{v?.QUANTITY}</td>
                          <td>
                            {
                              UOMData.filter((item) => item?.UOM == v?.UOM)[0]
                                ?.title
                            }
                          </td>
                          <td>{v?.UNIT_RATE}</td>
                          {/* <td>{v?.TDS_AMT}</td> */}
                          {/* <td>{v?.TCS_AMOUNT}</td> */}
                          <td>{v?.P_AMOUNT}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={8} />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        {/* <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.TCS_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td> */}
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.P_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      {viewItem?.products.map((v, i) => (
                        <tr key={i}>
                          <td>{v?.PNAME}</td>
                          <td>{v?.HSN_CODE}</td>
                          <td>{v?.GSTP}</td>
                          <td>
                            {MHData.filter((item) => item?.MH == v?.MH)[0]
                              ?.title || ""}
                          </td>
                          <td>
                            {AHData.filter((item) => item?.AH == v?.AH)[0]
                              ?.title || ""}
                          </td>
                          <td>{v?.QUANTITY}</td>
                          <td>
                            {UOMData.filter((item) => item?.UOM == v?.UOM)[0]
                              ?.title || ""}
                          </td>
                          <td>{v?.UNIT_RATE}</td>
                          <td>{v?.BASIC_AMOUNT}</td>
                          <td>{v?.IGST_AMOUNT}</td>
                          <td>{v?.SGST_AMOUNT}</td>
                          <td>{v?.CGST_AMOUNT}</td>
                          <td>{v?.P_AMOUNT || "NA"}</td>
                        </tr>
                      ))}
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          TOTAL
                        </td>
                        <td />
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.IGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.SGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.CGST_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                        <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                          {viewItem?.products
                            .map((v) => v.P_AMOUNT)
                            .reduce((a, b) => Number(a) + Number(b), 0)
                            ?.toFixed(2)}
                        </td>
                      </tr>
                    </>
                  )
                }
              />
            </>
          }
        ></Modal>
      )}

      {loading && <Loader />}
    </div>
  );
};
