import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import {
  get_arb_list,
  get_customer_vender_list,
  get_equipment_list,
  get_service_master_list,
} from "../../MasterData/masterdataSlice";
import { purchase_order_sales_API } from "../../../utils/apis";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import PageLoading from "../../../components/PageLoading";
import RowTxt from "../../../components/RowTxt";
import { DeleteIcon, Radio } from "../../../utils/utils";
import Modal from "../../../components/modal/Modal";
import Button from "../../../components/btn/Button";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import RadioBtn from "../../../components/radio/RadioBtn";
import Table from "../../../components/table/Table";
import SmallModal from "../../../components/modal/SmallModal";
import moment from "moment";
import { motion } from "framer-motion";
import NoData from "../../../components/NoData";
import { HiOutlineArrowRight } from "react-icons/hi";
import {
  CVOList,
  EquipmentList,
  PriceList,
  StaffList,
} from "../../OtherComponent/StaticLists/staticLists";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import { PinVerify } from "../../OtherComponent/PinVerify";
import { DropInput } from "../../../components/inputs/DropInput";
import {
  get_customer_purchase_order_list,
  get_quotation_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import { DateValidator2, last90days, today } from "../../../utils/validation";
import useUniqueEquipmentList from "../../../utils/Hook/useUniqueEquipmentList";
import { Alerts } from "../../../components/Alerts/Alerts";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import { EmptyDataHook } from "../../../utils/Hook/EmptyDataHook";

export const PurchaseOrder = () => {
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [mess, setMess] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [cvoClick, setCvoClick] = useState(false);
  const [EquipClick, setEquipClick] = useState(false);
  const [PriceClick, setPriceClick] = useState(false);
  const [StaffClick, setStaffClick] = useState(false);
  const [showPinVerify, setShowPinVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [viewItem, setViewItem] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [SerchInput, setSearchInput] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const { eff_date, user_id, today, emp_id, user_type } = useSelector((state) => state.other);
  const {
    customer_purchase_list,
    customer_purchase_loading,
    transition_complete_list,
    quotation_list,
  } = useSelector((state) => state.transactions);
  const { day_end_date } = useSelector((state) => state.profile);
  const { equipment_list, service_master_list, arb_list, cust_vend_list } =
    useSelector((state) => state.masterdata);
  const uniqueEquipmentList = useUniqueEquipmentList(equipment_list);

  useEffect(() => {
    store.dispatch(get_equipment_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_service_master_list());
    store.dispatch(get_customer_vender_list());
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_quotation_list());
  }, []);

  const obj = {
    page: pageNo,
    limit: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };

  const fetchPurchaseList = async () => {
    store.dispatch(get_customer_purchase_order_list(obj));
  };
  SearchApiHook(fetchPurchaseList, SerchInput?.trim(), prevSearch, [
    entries,
    pageNo,
  ]);

  const onDelete_item = () => {
    setOpen(false);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", id);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    purchase_order_sales_API(body).then((v) => {
      if (v.success) {
        fetchPurchaseList();
        toast(v.message);
      } else {
        toast(v.message);
      }
    });
  };

  const fetchGST = async () => {
    if (
      !addDataHead?.po_date ||
      (addDataHead?.po_date && addDataHead?.po_date?.length == 0)
    ) {
      setOpen1(true);
      setMess("Please Select Purchase Order Date");
    } else if (
      !addData[addData.length - 1]?.product_code ||
      (addData[addData.length - 1]?.product_code &&
        addData[addData.length - 1]?.product_code?.length == 0)
    ) {
      setOpen1(true);
      setMess("Please Select PRODUCT and Click FETCH GST");
    } else {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "fetchgst");
        body.append("agencyid", user_id);
        body.append("podate", addDataHead?.po_date);
        addData.forEach((v) => body.append("type", v.product_category));
        addData.forEach((v) => body.append("pcode", v.product_code));
        const response = await purchase_order_sales_API(body);
        setShowLoader(false);
        if (response.success) {
          setAddData((prev) =>
            prev.map((v, i) =>
              i === addData.length - 1
                ? {
                    ...v,
                    p_gst:
                      response?.GSTP == "NA"
                        ? 0
                        : response?.GSTP || response?.GST_AMT || 0,
                    unit_rate: response?.BASE_PRICE || response?.PROD_CHARGES,
                  }
                : v
            )
          );
        } else if (
          !response.success &&
          response.message == "Product code not found"
        ) {
          setOpen1(true);
          setMess(
            "please define the price of product for sale invoice month in it's price master and continue"
          );
        }
      } catch (error) {
        setOpen1(true);
        setMess(error.message);
      }
    }
  };

  const calculate = () => {
    if (!addDataHead.po_number) {
      setMess("Please Enter Purchase Number");
      setOpen1(true);
      return;
    }
    if (addData?.length > 0) {
      var total = 0;
      addData?.forEach((product, index) => {
        if (
          !product?.quantity ||
          product?.quantity == 0 ||
          (product?.quantity && product?.quantity.length == 0) ||
          !product?.discount_price ||
          (product?.discount_price && product?.discount_price.length == 0) ||
          parseFloat(product?.discount_price) >= parseFloat(product?.unit_rate)
        ) {
          setMess(
            `${
              !product?.quantity ||
              product?.quantity == 0 ||
              (product?.quantity && product?.quantity.length == 0)
                ? "Please Enter QUANTITY."
                : ""
            } \n
                    ${
                      !product?.discount_price ||
                      (product?.discount_price &&
                        product?.discount_price.length == 0)
                        ? "Please Enter Discount Price."
                        : ""
                    }
                      ${
                        parseFloat(product?.discount_price) >=
                        parseFloat(product?.unit_rate)
                          ? "Discount Price Always less on Unit Price."
                          : ""
                      }
                      `
          );
          setOpen1(true);
          return;
        } else {
          if (
            !addDataHead?.customer_name ||
            (addDataHead?.customer_name &&
              addDataHead?.customer_name?.trim()?.length == 0)
          ) {
            setMess(`Please Enter Customer Name`);
            setOpen1(true);
          } else if (
            !addDataHead?.c_type ||
            (addDataHead?.c_type && addDataHead?.c_type?.length == 0)
          ) {
            setMess(`Please Select Sale Type`);
            setOpen1(true);
          } else {
            const unit_rate = Number(product?.unit_rate);
            const discount_price = Number(product?.discount_price);
            const quantity = Number(product?.quantity);
            const p_gst = Number(product?.p_gst);

            const tax_amount = (unit_rate - discount_price) * quantity;
            const gst_amount = tax_amount * (p_gst / 100);
            const amount = gst_amount + tax_amount;

            if (addDataHead?.c_type === "isp") {
              setAddData((prevData) => {
                const updatedData = [...prevData];
                updatedData[index] = {
                  ...updatedData[index],
                  tax_amount: Number(tax_amount).toFixed(2),
                  igst_amount: Number(gst_amount).toFixed(2),
                  cgst_amount: "0.0",
                  sgst_amount: "0.0",
                  amount: Number(amount).toFixed(2),
                };
                return updatedData;
              });
            } else if (addDataHead?.c_type === "lp") {
              setAddData((prevData) => {
                const updatedData = [...prevData];
                updatedData[index] = {
                  ...updatedData[index],
                  tax_amount: Number(tax_amount).toFixed(2),
                  cgst_amount: Number(gst_amount / 2).toFixed(2),
                  sgst_amount: Number(gst_amount / 2).toFixed(2),
                  igst_amount: "0.0",
                  amount: Number(amount).toFixed(2),
                };
                return updatedData;
              });
            }
            total += amount;
          }
        }
      });
      setAddDataHead({ ...addDataHead, inv_amount: total.toFixed(2) });
    }
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product_code ||
          (v?.product_code && v?.product_code?.length == 0) ||
          // !v?.p_gst ||
          // (v?.p_gst && v?.p_gst?.length == 0) ||
          !v?.unit_rate ||
          (v?.unit_rate && v?.unit_rate?.length == 0) ||
          !v?.quantity ||
          v?.quantity == 0 ||
          (v?.quantity && v?.quantity?.length == 0) ||
          !v?.discount_price ||
          (v?.discount_price && v?.discount_price?.length == 0) ||
          !v?.tax_amount ||
          (v?.tax_amount && v?.tax_amount?.length == 0) ||
          !v?.igst_amount ||
          (v?.igst_amount && v?.igst_amount?.length == 0) ||
          !v?.sgst_amount ||
          (v?.sgst_amount && v?.sgst_amount?.length == 0) ||
          !v?.cgst_amount ||
          (v?.cgst_amount && v?.cgst_amount?.length == 0) ||
          !v?.amount ||
          (v?.amount && v?.amount?.length == 0)
      )
    ) {
      return true;
    }
    return false;
  };

  const valiDate = async () => {
    const data = [];
    if (
      !addDataHead?.po_number ||
      (addDataHead?.po_number && addDataHead?.po_number.length === 0)
    ) {
      data.push("Please Enter Purchase Order Number");
    } else if (addDataHead?.po_number?.length < 8) {
      data.push("Purchase Order Number contain min 8 Charactors");
    }
    const data1 = await DateValidator2(
      moment(addDataHead?.po_date).format("DD/MM/YYYY"),
      today,
      day_end_date,
      `PO Date cant be before ${day_end_date} `,
      `PO Date cant be a future date`
    );
    if (
      !addDataHead?.po_date ||
      (addDataHead?.po_date && addDataHead?.po_date.length === 0)
    ) {
      data.push("Please Enter Purchase Date");
    } else if (data1 !== true) {
      data.push(...data1);
    }
    if (
      !addDataHead?.customer_name ||
      (addDataHead?.customer_name && addDataHead?.customer_name.length === 0)
    ) {
      data.push("Please Enter Customer Name");
    }
    if (
      !addDataHead?.valid_upto ||
      (addDataHead?.valid_upto && addDataHead?.valid_upto.length === 0)
    ) {
      data.push("Please Enter Valid Upto Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.valid_upto).format("DD/MM/YYYY"),
        last90days,
        moment(addDataHead?.po_date).format("DD/MM/YYYY")
      ) !== true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.valid_upto).format("DD/MM/YYYY"),
          last90days,
          moment(addDataHead?.po_date).format("DD/MM/YYYY"),
          `Valid Upto Date cant be before PO Date`,
          `Valid Upto Date cant be a  ${last90days} `
        )
      );
    }
    if (
      !addDataHead?.foot_note ||
      (addDataHead?.foot_note && addDataHead?.foot_note.length === 0)
    ) {
      data.push("Please Enter Foot Note");
    }

    // if (
    //   !addDataHead?.quo_number ||
    //   (addDataHead?.quo_number && addDataHead?.quo_number.length === 0)
    // ) {
    //   data.push("Please Enter Quotation Number");
    // }
    // if (
    //   !addDataHead?.price_flex ||
    //   (addDataHead?.price_flex && addDataHead?.price_flex.length === 0)
    // ) {
    //   data.push("Please Price Flexibility");
    // }
    if (
      !addDataHead?.c_type ||
      (addDataHead?.c_type && addDataHead?.c_type.length === 0)
    ) {
      data.push("Please Select SALE TYPE");
    }

    for (let v of addData) {
      if (
        !v.product_category ||
        (v.product_category && v.product_category.length === 0)
      ) {
        data.push("Please Select PRODUCT CATEGORY");
      } else if (
        !v.product_code ||
        (v.product_code && v.product_code.length === 0)
      ) {
        data.push("Please Select PRODUCT");
      } else if (
        !v.unit_rate ||
        (v.unit_rate && v.unit_rate.length === 0)
        // !v.p_gst ||
        // (v.p_gst && v.p_gst.length === 0)
      ) {
        data.push("First Click on FETCH GST ");
      }

      if (!v.quantity || (v.quantity && v.quantity.length === 0)) {
        data.push("Please Enter Quantity");
      } else if (v.quantity == 0) {
        data.push("Please Enter valid Quantity");
      }
      if (
        !v.discount_price ||
        (v.discount_price && v.discount_price.length === 0)
      ) {
        data.push("Please Enter Discount Price");
      }
      if (Number(v.discount_price) >= Number(v.unit_rate)) {
        data.push("Discount Price Cant be greater then or equal to Unit Price");
      }
      if (!v.tax_amount || (v.tax_amount && v.tax_amount.length === 0)) {
        data.push("Please Click on Calculate");
      } else if (
        !v.igst_amount ||
        (v.igst_amount && v.igst_amount.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (
        !v.sgst_amount ||
        (v.sgst_amount && v.sgst_amount.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (
        !v.cgst_amount ||
        (v.cgst_amount && v.cgst_amount.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (!v.amount || (v.amount && v.amount.length === 0)) {
        data.push("Please Click on Calculate");
      } else if (
        !addDataHead?.inv_amount ||
        (addDataHead?.inv_amount && addDataHead?.inv_amount.length === 0)
      ) {
        data.push("Please Click on Calculate");
      }
    }
    return data;
  };

  const add_items = async () => {
    const data1 = await valiDate();
    const data = [...new Set(data1)];

    if (data.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("ponumber", addDataHead?.po_number);
        body.append("podate", addDataHead?.po_date);
        body.append("customerid", addDataHead?.customer_id);
        body.append("customername", addDataHead?.customer_name);
        body.append("foot_note", addDataHead?.foot_note);
        body.append("valid_upto", addDataHead?.valid_upto);
        body.append("quotation_number", addDataHead?.quo_number || "NA");
        // body.append("price_flex", addDataHead?.price_flex);
        body.append("amount", addDataHead?.inv_amount);
        body.append("connection_type", addDataHead?.c_type);
        addData.forEach((item) => {
          if (item) {
            body.append("productcode[]", item?.product_code);
            body.append("gst[]", item?.p_gst);
            body.append("quantity[]", item?.quantity);
            body.append("unit_rate[]", item?.unit_rate);
            body.append("dis_unit_rate[]", item?.discount_price);
            body.append("basic_amount[]", item?.tax_amount);
            body.append("igst_amount[]", item?.igst_amount);
            body.append("cgst_amount[]", item?.cgst_amount);
            body.append("sgst_amount[]", item?.sgst_amount);
            body.append("prod_amount[]", item?.amount);
          }
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const responce = await purchase_order_sales_API(body);
        setShowLoader(false);
        if (responce.success) {
          fetchPurchaseList();
          setAddData([]);
          setAddDataHead({});
          toast(responce.message);
          setIsAdd(false);
        } else {
          setMess(responce.message);
          setOpen1(true);
        }
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    } else {
      setMess(<Alerts data={data} />);
      setOpen1(true);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"CUSTOMER PURCHASE ORDER"}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData([{}]);
                setAddDataHead({ ...addDataHead, inv_amount: 0.0 });
                setIsAdd(true);
              }}
            />
            <BackButton />
          </>
        }
      />
      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {customer_purchase_loading ? (
          <PageLoading />
        ) : customer_purchase_list?.length == 0 ? (
          <NoData />
        ) : (
          <div className="grid">
            {customer_purchase_list?.map((v, i) => (
              <div className="card" key={i}>
                <div>
                  <div className="card-heading">{v?.CUSTOMER_NAME}</div>
                  <RowTxt title={"PO No."} value={v?.PO_NO} />
                  <RowTxt
                    title={"PO Date"}
                    value={moment(v?.PO_Date).format("DD-MMM-YYYY")}
                  />
                  <RowTxt
                    title={"PO Valid Upto"}
                    value={moment(v?.PO_VALID_UPTO).format("DD-MMM-YYYY")}
                  />
                  <RowTxt title={"PO Amount"} value={v?.PO_AMOUNT} />
                  <RowTxt title={"Foot Notes"} value={v?.FOOT_NOTES} />
                  {v?.QUOTATION_NUM?.length > 0 && v?.QUOTATION_NUM != 0 && (
                    <>
                      <RowTxt
                        title={"Quotation No."}
                        value={v?.QUOTATION_NUM}
                      />
                    </>
                  )}
                  <RowTxt title={"Created By"} value={v?.CREATED_NAME || "-"} />
                </div>
                <div
                  className="df jcsb mt10"
                  style={{
                    justifyContent:
                      user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                        ? "flex-end"
                        : "space-between",
                  }}
                >
                  {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                    <DeleteIcon
                      onClick={() => {
                        setId(v?.ID);
                        setOpen(true);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenView(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight size={20} color="#1b64af" />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Pagination3>

      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          header_right={
            <div className="flex aic gap10">
              <Button title={"CVO"} back onClick={() => setCvoClick(true)} />
              <Button
                title={"EQUIPMENT"}
                back
                onClick={() => setEquipClick(true)}
              />
              <Button
                title={"PRICE"}
                back
                onClick={() => {
                  setShowPinVerify(true);
                }}
              />
              <Button
                title={"STAFF"}
                back
                onClick={() => setStaffClick(true)}
              />
            </div>
          }
          title={"CUSTOMER PURCHASE ORDER"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"PO NUMBER"}
                  placeholder={"PO NUMBER"}
                  value={addDataHead.po_number}
                  onChange={(e) => {
                    const input = e.target.value?.toUpperCase();
                    if (
                      input?.length < 16 ||
                      // alfaNumericWithoutSpace.test(input)) ||
                      input == ""
                    ) {
                      setAddDataHead({
                        ...addDataHead,
                        // po_number: alfaNumericWithoutSpace.test(input)
                        po_number: input,
                        //   ? input
                        //   : "",
                      });
                    }
                  }}
                />
                <Input
                  label={"PO DATE"}
                  type={"date"}
                  value={addDataHead.po_date}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        po_date: e.target.value,
                        inv_amount: "",
                        quo_number: "",
                      });
                      const resetData1 = EmptyDataHook(addData?.[0]);
                      setAddData([resetData1]);
                    }
                  }}
                />
                <DropInput
                  label={"CUSTOMER NAME"}
                  id="cust"
                  placeholder={"CUSTOMER NAME"}
                  value={addDataHead?.customer_name}
                  onChange={(e) => {
                    const data = cust_vend_list?.filter(
                      (item) =>
                        item?.CVO_CAT == "1" && item?.ID == e.target.value
                    );
                    const data1 = transition_complete_list?.Customer?.filter(
                      (v) => v.ID == e.target.value
                    )[0];
                    setAddDataHead({
                      ...addDataHead,
                      customer_name: data[0]?.cvo_name || e.target.value,
                      customer_id: e.target.value || "",
                      c_type: data1 ? data1?.connection_type : "",
                      c_type_dis: data1 ? true : false,
                      quo_number: "",
                    });
                    const resetData1 = EmptyDataHook(addData?.[0]);
                    setAddData([resetData1]);
                  }}
                  list={cust_vend_list
                    ?.filter((item) => item?.CVO_CAT == "1")
                    ?.map((v, i) => (
                      <option key={i} value={v?.ID}>
                        {v?.cvo_name?.toUpperCase()}
                      </option>
                    ))}
                />
                <Input
                  label={"VALID UPTO"}
                  type={"date"}
                  value={addDataHead?.valid_upto}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead({
                        ...addDataHead,
                        valid_upto: e.target.value,
                      });
                    }
                  }}
                />
                <Dropdown
                  width2=""
                  label={"QUOTATION NUMBER"}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      {quotation_list
                        ?.filter(
                          (v) => v?.CUSTOMER_ID == addDataHead?.customer_id
                        )
                        .filter((v) => {
                          const quotationDate = new Date(v?.quotation_date);
                          const poDate = new Date(
                            addDataHead?.po_date || new Date()
                          );
                          quotationDate.setDate(quotationDate.getDate() + 180);
                          return (
                            quotationDate >= poDate && // Ensure the quotation is still valid 180 days from the quotation date
                            new Date(v?.quotation_date) <= poDate // Ensure the quotation was created before or on the PO date
                          );
                        })
                        ?.map((v, i) => (
                          <option key={i} value={v?.SR_NO}>
                            {v?.SR_NO} - {v?.CUSTOMER_NAME}
                          </option>
                        ))}
                    </>
                  }
                  onChange={(e) => {
                    const data = quotation_list
                      ?.filter(
                        (v) => v?.CUSTOMER_ID == addDataHead?.customer_id
                      )
                      ?.filter((v) => v?.SR_NO == e.target.value);

                    setAddDataHead({
                      ...addDataHead,
                      quo_number: e.target.value,
                    });
                    const resetData1 = EmptyDataHook(addData?.[0]);
                    setAddData([resetData1]);
                  }}
                  value={addDataHead?.quo_number}
                />

                {/* <Dropdown
                  width2=""
                  label={"PRICE FLEXIBILITY"}
                  options={
                    <>
                      <option value={""}>SELECT</option>
                      <option value={"1"}>YES</option>
                      <option value={"0"}>NO</option>
                    </>
                  }
                  onChange={(e) => {
                    setAddDataHead({
                      ...addDataHead,
                      price_flex: e.target.value,
                    });
                  }}
                  value={addDataHead?.price_flex}
                /> */}
                <Input
                  label={"FOOT NOTES"}
                  placeholder={"FOOT NOTES"}
                  value={addDataHead.foot_note}
                  onChange={(e) =>
                    setAddDataHead({
                      ...addDataHead,
                      foot_note: e.target.value,
                    })
                  }
                />
                <Input
                  label={"PURCHASE ORDER VALUE"}
                  placeholder={"PURCHASE ORDER AMOUNT"}
                  value={addDataHead.inv_amount}
                  disabled={true}
                />
              </div>
              <div className="flex aic gap10 mt10">
                <RadioBtn label={"SALE TYPE"} />
                <Radio
                  disabled={addDataHead?.c_type_dis}
                  title={"LOCAL SALE"}
                  show={addDataHead.c_type == "lp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "lp" })
                  }
                />
                <Radio
                  disabled={addDataHead?.c_type_dis}
                  title={"INTER-STATE SALE"}
                  show={addDataHead.c_type == "isp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "isp" })
                  }
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={[
                    "PRODUCT CATEGORY",
                    "PRODUCT",
                    "GST%",
                    "UNIT RATE ",
                    "DISCOUNT ON UNIT PRICE",
                    "QUANTITY",
                    "TAXABLE AMOUNT",
                    "IGST AMOUNT",
                    "CGST AMOUNT",
                    "SGST AMOUNT	",
                    "TOTAL AMOUNT",
                    "ACTIONS",
                  ]}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            disabled={addData?.length > ind + 1}
                            options={
                              <>
                                <option value={""}>{"SELECT"}</option>
                                <option value={"1"}>{"EQUIPMENT"}</option>
                                <option value={"2"}>{"BLPG/ARB/NFR"}</option>
                                <option value={"3"}>{"SERVICES"}</option>
                              </>
                            }
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        product_category: e.target.value,
                                        product_code: "",
                                        p_gst: "",
                                        unit_rate: "",
                                        igst_amount: "",
                                        cgst_amount: "",
                                        sgst_amount: "",
                                        amount: "",
                                        tax_amount: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            value={val.product_category}
                          />
                        }
                      </td>
                      <td>
                        <Dropdown
                          disabled={addData?.length > ind + 1}
                          onChange={(e) => {
                            setAddData((pre) =>
                              pre.map((v, i) =>
                                i == ind
                                  ? {
                                      ...v,
                                      product_code: e.target.value,
                                      p_gst: "",
                                      unit_rate: "",
                                      igst_amount: "",
                                      cgst_amount: "",
                                      sgst_amount: "",
                                      amount: "",
                                      tax_amount: "",
                                    }
                                  : { ...v }
                              )
                            );
                          }}
                          options={
                            <>
                              <option value={""}>{"SELECT"}</option>
                              {addDataHead?.quo_number ? (
                                // quotation_list
                                //   ?.filter(
                                //     (v) =>
                                //       v?.CUSTOMER_ID == addDataHead?.customer_id
                                //   )
                                //   ?.filter(
                                //     (v) => v?.SR_NO == addDataHead?.quo_number
                                //   )?.[0]
                                //   ?.products?.filter(
                                //     (v) =>
                                //       addData[ind]?.product_category == "1" &&
                                //       (v?.CAT_NAME == "REGULATOR" ||
                                //         v?.CAT_NAME == "COMMERCIAL")
                                //   )
                                //   ?.map((v, i) => (
                                //     <option
                                //       disabled={
                                //         addData?.filter(
                                //           (s) => s?.product_code == v.PROD_CODE
                                //         )?.length > 0
                                //       }
                                //       value={v.PROD_CODE}
                                //       key={v.ID}
                                //     >
                                //       {v.CAT_NAME} - {v.CAT_DESC}
                                //     </option>
                                //   ))
                                quotation_list
                                  ?.filter(
                                    (v) =>
                                      v?.CUSTOMER_ID == addDataHead?.customer_id
                                  )
                                  ?.filter(
                                    (v) => v?.SR_NO == addDataHead?.quo_number
                                  )?.[0]
                                  ?.products?.filter((v) => {
                                    if (addData[ind]?.product_category == "1") {
                                      return (
                                        v?.CAT_NAME == "REGULATOR" ||
                                        v?.CAT_NAME == "COMMERCIAL"
                                      );
                                    } else if (
                                      addData[ind]?.product_category == "2"
                                    ) {
                                      return v?.PROD_CAT == "ARB";
                                    } else if (
                                      addData[ind]?.product_category == "3"
                                    ) {
                                      return v?.PROD_CAT == "SERVICE";
                                    }
                                    return false;
                                  })
                                  ?.map((v, i) => {
                                    let label;
                                    if (addData[ind]?.product_category == "1") {
                                      label = `${v.CAT_NAME} - ${v.CAT_DESC}`;
                                    } else if (
                                      addData[ind]?.product_category == "2"
                                    ) {
                                      label = `${v.CAT_DESC} - ${v.CAT_NAME} - ${v.PROD_NAME}`;
                                    } else {
                                      label = `${v.CAT_NAME} - ${v.CAT_DESC}`; // Default label
                                    }

                                    return (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (s) =>
                                              s?.product_code == v.PROD_CODE
                                          )?.length > 0
                                        }
                                        value={v.PROD_CODE}
                                        key={v.ID}
                                      >
                                        {label}
                                      </option>
                                    );
                                  })
                              ) : (
                                <>
                                  {addData[ind]?.product_category == "1" &&
                                    uniqueEquipmentList
                                      ?.filter(
                                        (v) =>
                                          v?.CAT_NAME == "REGULATOR" ||
                                          v?.CAT_NAME == "COMMERCIAL"
                                      )
                                      ?.map((v, i) => (
                                        <option
                                          disabled={
                                            addData?.filter(
                                              (s) =>
                                                s?.product_code == v.PROD_CODE
                                            )?.length > 0
                                          }
                                          value={v.PROD_CODE}
                                          key={v.ID}
                                        >
                                          {v.CAT_NAME} - {v.product_name}
                                        </option>
                                      ))}

                                  {addData[ind]?.product_category === "2" &&
                                    arb_list?.map((v, i) => (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (s) =>
                                              s?.product_code == v.PROD_CODE
                                          )?.length > 0
                                        }
                                        value={v.ID}
                                        key={v.ID}
                                      >
                                        {v.category_description} -{" "}
                                        {v.PROD_BRAND} - {v.PROD_NAME}
                                      </option>
                                    ))}

                                  {addData[ind]?.product_category == "3" &&
                                    service_master_list?.map((v, i) => (
                                      <option
                                        disabled={
                                          addData?.filter(
                                            (s) =>
                                              s?.product_code == v.PROD_CODE
                                          )?.length > 0
                                        }
                                        value={v.PROD_CODE || v?.ID}
                                        key={v.ID}
                                      >
                                        {v.CAT_DESC}
                                      </option>
                                    ))}
                                </>
                              )}
                            </>
                          }
                        />
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val.p_gst}
                            placeholder={"GST%"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"UNIT RATE"}
                            value={val.unit_rate}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"DISCOUNT ON UNIT PRICE"}
                            value={val.discount_price}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length < 11 &&
                                  /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.discount_price
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          discount_price:
                                            /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          tax_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                          igst_amount: "",
                                          amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"QUANTITY"}
                            value={val?.quantity}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                (inputValue?.length < 5 &&
                                  /^\d+$/.test(inputValue)) ||
                                inputValue == 0 ||
                                !val?.quantity
                              ) {
                                setAddData((pre) =>
                                  pre.map((v, i) =>
                                    i == ind
                                      ? {
                                          ...v,
                                          quantity: /^\d+$/.test(inputValue)
                                            ? inputValue
                                            : "",
                                          tax_amount: "",
                                          cgst_amount: "",
                                          sgst_amount: "",
                                          igst_amount: "",
                                          amount: "",
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"TAXABLE AMOUNT"}
                            value={val.tax_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"IGST AMOUNT"}
                            value={val.igst_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"CGST AMOUNT"}
                            value={val.cgst_amount}
                            disabled={true}
                          />
                        }
                      </td>{" "}
                      <td>
                        {
                          <Input
                            placeholder={"SGST AMOUNT"}
                            value={val.sgst_amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"TOTAL AMOUNT"}
                            value={val.amount}
                            disabled={true}
                          />
                        }
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                inv_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    validation_check() &&
                      setMess(
                        "Please enter all the values in current row,calculate and then add next row"
                      );
                    validation_check()
                      ? setOpen1(true)
                      : setAddData((pre) => [...pre, {}]);
                  }}
                />
                <Button title={"FETCH GST"} back onClick={() => fetchGST()} />
                <Button
                  title={"CALCULATE"}
                  back
                  disable={
                    !addData[addData.length - 1]?.product_code ||
                    addData[addData.length - 1]?.p_gst?.length == 0
                      ? true
                      : false
                  }
                  onClick={() =>
                    !addData[addData.length - 1].product_code ||
                    addData[addData.length - 1].p_gst?.length == 0
                      ? ""
                      : calculate()
                  }
                />
                <SaveButton onClick={() => add_items()} />
              </div>
            </div>
          }
        ></Modal>
      )}

      {openView && (
        <Modal
          onCancel={() => {
            setViewItem({});
          }}
          isOpen={openView}
          title={viewItem?.CUSTOMER_NAME}
          setIsOpen={setOpenView}
          body={
            <>
              <Table
                mt={10}
                headData={[
                  "PRODUCT",
                  "GST %	",
                  "UNIT PRICE",
                  "DISCOUNT AMOUNT",
                  "QUANTITY",
                  "TAXABLE AMOUNT",
                  "IGST",
                  "CGST",
                  "SGST",
                  "TOTAL AMOUNT",
                ]}
                body={
                  <>
                    {viewItem?.products?.map((v, i) => (
                      <tr key={i}>
                        <td>
                          {v?.CAT_NAME && v?.CAT_DESC && v?.PROD_NAME
                            ? `${v?.CAT_DESC} - ${v?.CAT_NAME} - ${v?.PROD_NAME}`
                            : `${v?.CAT_NAME} ${
                                v?.CAT_DESC ? `- ${v?.CAT_DESC}` : ""
                              }`}
                        </td>
                        <td>{v?.VATP}</td>
                        <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                        <td>{Number(v?.DISC_UNIT_RATE).toFixed(2)}</td>
                        <td>{v?.QUANTITY}</td>
                        <td>{Number(v?.BASIC_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                        <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                        <td>{v?.PROD_AMOUNT}</td>
                      </tr>
                    ))}
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        TOTAL
                      </td>
                      <td />
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v?.BASIC_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.IGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.SGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {viewItem?.products
                          .map((v) => v.CGST_AMOUNT)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                          ?.toFixed(2)}
                      </td>
                      <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                        {Number(viewItem?.PO_AMOUNT)?.toFixed(2)}
                      </td>
                    </tr>
                  </>
                }
              />
            </>
          }
        ></Modal>
      )}

      {cvoClick && <CVOList cvoClick={cvoClick} setCvoClick={setCvoClick} />}

      {EquipClick && (
        <EquipmentList EquipClick={EquipClick} setEquipClick={setEquipClick} />
      )}

      {StaffClick && (
        <StaffList StaffClick={StaffClick} setStaffClick={setStaffClick} />
      )}

      {PriceClick && (
        <PriceList PriceClick={PriceClick} setPriceClick={setPriceClick} />
      )}

      {showPinVerify && (
        <PinVerify
          showPinVerify={showPinVerify}
          setShowPinVerify={setShowPinVerify}
          setShowLoader={setShowLoader}
          setAlertOpen={setOpen}
          setAlertMess={setMess}
        />
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => onDelete_item()}
        />
      )}
      {open1 && (
        <SmallModal
          title={"Alerts"}
          isOpen={open1}
          setIsOpen={setOpen1}
          mess={mess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};
