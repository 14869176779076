import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import CustomList14 from "./CustomList14";
import { FiChevronRight } from "react-icons/fi";
import { IoReceiptOutline } from "react-icons/io5";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { RiFileList3Line } from "react-icons/ri";
import { LiaClipboardListSolid } from "react-icons/lia";
import { useSelector } from "react-redux";

const PayRollList = ({ close, path }) => {
  const [focused, setFocused] = useState([]);
  const navigate = useNavigate();
  const { user_type, emp_data } = useSelector((state) => state.other);

  const payrollMenu = [
    {
      title: "Staff Master",
      icon: <RiFileList3Line color="#6e82a5" size={15} />,
      route: "/Payroll/StaffMaster",
      activePath: "/Payroll/StaffMaster",
      permission: "PAYROLL_STAFF_PERMISSION", // remaining
    },
    {
      title: "Pay Runs",
      icon: <MdOutlineCurrencyRupee color="#6e82a5" size={15} />,
      route: "/Payroll/PayRuns",
      activePath: "/Payroll/PayRuns",
      permission: "PAY_RUNS_PERMISSION",
    },
    {
      title: "Loans",
      icon: <IoReceiptOutline color="#6e82a5" size={15} />,
      route: "/Payroll/Loans",
      activePath: "/Payroll/Loans",
      permission: "LOANS_PERMISSION",
    },
    {
      title: "Reports",
      icon: <IoReceiptOutline color="#6e82a5" size={15} />,
      route: "/Payroll/Reports",
      activePath: "/Payroll/Reports",
      permission: "REPORTS_PERMISSION",
    },
  ];
  const permission_handle = (permissions = []) => {
    return (
      user_type === "admin" || permissions.some((perm) => emp_data?.[perm])
    );
  };

  return (
    <>
      {permission_handle(payrollMenu?.map((v) => v?.permission)) && (
        <div
          className="list-main mt10"
          onClick={() =>
            setFocused((pre) =>
              pre.find((v) => v == "Payroll")
                ? pre.filter((v) => v != "Payroll")
                : ["Payroll"]
            )
          }
        >
          <div className="list-icon">
            <LiaClipboardListSolid color="#6e82a5" size={16} />

            {/* <LiaClipboardListSolid color="#6e82a5" size={16} /> */}
          </div>
          <div className="list-title fs15">Payroll</div>
          <div className="list-arrow">
            <FiChevronRight
              size={18}
              className={
                focused.includes("Payroll") ? "rotate-down" : "rotate-right"
              }
            />
          </div>
        </div>
      )}
      <AnimatePresence initial={false}>
        {focused.includes("Payroll") && (
          <motion.section
            style={{ marginLeft: 15, overflow: "hidden" }}
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 1, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {payrollMenu.map((v, i) => (
              <>
                {permission_handle([v?.permission]) && (
                  <CustomList14
                    key={i}
                    title={v?.title}
                    style={{
                      backgroundColor: path == v?.route ? "white" : "",
                    }}
                    type={v?.permission}
                    onClick={() => {
                      navigate(v?.route);
                      close();
                    }}
                    icon={v?.icon}
                  />
                )}
              </>
            ))}
            {/* <CustomList14
              title={"Staff Master"}
              icon={<RiFileList3Line color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/Payroll/StaffMaster" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Payroll/StaffMaster");
                close();
              }}
            />
            <CustomList14
              title={"Pay Runs"}
              icon={<MdOutlineCurrencyRupee color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/Payroll/PayRuns" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Payroll/PayRuns");
                close();
              }}
            />
            <CustomList14
              title={"Loans"}
              icon={<IoReceiptOutline color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/Payroll/Loans" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Payroll/Loans");
                close();
              }}
            />
            <CustomList14
              title={"Reports"}
              icon={<IoReceiptOutline color="#6e82a5" size={15} />}
              style={{
                backgroundColor: path == "/Payroll/Reports" ? "white" : "",
              }}
              onClick={() => {
                navigate("/Payroll/Reports");
                close();
              }}
            /> */}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  );
};

export default PayRollList;
