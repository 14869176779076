import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  EditButton,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { useSelector } from "react-redux";
import { Pagination2 } from "../../../components/pagination/pagination";
import PageLoading from "../../../components/PageLoading";
import Table from "../../../components/table/Table";
import moment from "moment";
import { base_url, DeleteIcon } from "../../../utils/utils";
import Dropdown from "../../../components/inputs/Dropdown";
import Input from "../../../components/inputs/Input";
import SmallModal from "../../../components/modal/SmallModal";
import { get_employee_list } from "../masterdataSlice";
import store from "../../../redux/store";
import Modal from "../../../components/modal/Modal";
import { EmailValidation, MobileValidation } from "../../../utils/validator";
import { Alerts } from "../../../components/Alerts/Alerts";
import RadioBtn from "../../../components/radio/RadioBtn";
import { CheckBox } from "../../../components/inputs/CheckBox";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { validateMobile } from "../../../utils/validation";

const EmployeeMaster = () => {
  const [open1, setOpen1] = useState(false);
  const [open, setOpen] = useState(false);
  const [mess, setMess] = useState("");
  const [addData, setAddData] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const [type, setType] = useState("");
  const [id, setId] = useState("");

  const { employee_list, employee_loading } = useSelector(
    (state) => state.masterdata
  );
  const { user_id } = useSelector((state) => state.other);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };

  function getStaffCategory(value) {
    const categories = {
      1: "MANAGER",
      2: "GODOWN MANAGER",
      3: "ACCOUNTS",
    };

    return categories[value] || "Invalid category";
  }

  useEffect(() => {
    store.dispatch(get_employee_list(obj));
  }, [pageNo, entries]);

  const validate = () => {
    const data = [];
    if (
      !addData.EMPLOYEE_CODE ||
      (addData.EMPLOYEE_CODE && addData.EMPLOYEE_CODE.length == 0)
    ) {
      data.push("Please Enter Employee code");
    } else if (
      addData.EMPLOYEE_CODE.length > 10 ||
      addData.EMPLOYEE_CODE.length < 6
    ) {
      data.push("Employee code must 6 to 10 digit ");
    }
    if (!addData.NAME || (addData.NAME && addData.NAME.length == 0)) {
      data.push("Please Enter Employee Name");
    }
    if (!addData.ROLE || (addData.ROLE && addData.ROLE.length == 0)) {
      data.push("Please Select Role");
    }
    if (!addData.EMAIL || (addData.EMAIL && addData.EMAIL.length == 0)) {
      data.push("Please Enter Employee Email");
    } else if (!EmailValidation.test(addData.EMAIL)) {
      data.push("Please enter Valid Employee Email");
    }
    if (!addData.MOBILE || (addData.MOBILE && addData.MOBILE.length == 0)) {
      data.push("Please Enter Employee Mobile");
    } else if (!validateMobile(addData.MOBILE)) {
      data.push("Please enter Valid Employee Mobile No.");
    }
    return data;
  };

  const update_data = async () => {
    const data = validate();
    if (data?.length == 0) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("action", type == "add" ? "create" : "update");
        formData.append("agencyid", user_id);
        Object.keys(addData).forEach((key) => {
          formData.append(key, addData[key]);
        });
        const response = await fetch(base_url + "employee.php", {
          method: "POST",
          body: formData,
        });
        const res = await response.json();
        setLoading(false);
        if (res.success) {
          toast.success(res.message);
          setIsAdd(false);
          setAddData({});
          store.dispatch(get_employee_list(obj));
        } else if (!res?.success) {
          setMess(res?.message);
          setOpen1(true);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setOpen1(true);
      setMess(<Alerts data={data} />);
    }
  };

  const delete_item = async () => {
    try {
      setOpen(false);
      const body = new FormData();
      body.append("action", "delete");
      body.append("agencyid", user_id);
      body.append("ID", id);
      const response = await fetch(base_url + "employee.php", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      if (res.success) {
        toast.success(res.message);
        setOpen(false);
        store.dispatch(get_employee_list(obj));
      } else if (!response?.success) {
        setMess(response?.message);
        setOpen1(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   Master Data

  const allPermissionsSelected = (data) => {
    return data.EQUIPMENT_MASTER_PERMISSION &&
      data.ARB_MASTER_PERMISSION &&
      data.SERVICE_MASTER_PERMISSION &&
      data.AREA_CODE_MASTER_PERMISSION &&
      data.BOM_PERMISSION &&
      data.REFILL_PRICE_PERMISSION &&
      data.ARB_PRICE_PERMISSION &&
      data.CUST_VEND_PERMISSION &&
      data.VEHICLE_PERMISSION &&
      data.STAFF_PERMISSION &&
      data.DISC_CREDIT_PERMISSION &&
      data.BANK_PERMISSION &&
      data.STOCK_ADJ_PERMISSION &&
      data.ALERT_PERMISSION
      ? 1
      : 0;
  };
  const handleSelectAllChange = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All: selectAllChecked,
      EQUIPMENT_MASTER_PERMISSION: selectAllChecked,
      ARB_MASTER_PERMISSION: selectAllChecked,
      SERVICE_MASTER_PERMISSION: selectAllChecked,
      AREA_CODE_MASTER_PERMISSION: selectAllChecked,
      BOM_PERMISSION: selectAllChecked,
      REFILL_PRICE_PERMISSION: selectAllChecked,
      ARB_PRICE_PERMISSION: selectAllChecked,
      CUST_VEND_PERMISSION: selectAllChecked,
      VEHICLE_PERMISSION: selectAllChecked,
      STAFF_PERMISSION: selectAllChecked,
      DISC_CREDIT_PERMISSION: selectAllChecked,
      BANK_PERMISSION: selectAllChecked,
      STOCK_ADJ_PERMISSION: selectAllChecked,
      ALERT_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChange = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All = allPermissionsSelected(updatedData);
      return updatedData;
    });
  };

  //   Transactions

  const allPermissionsSelectedTrans = (data) => {
    return data.VENDOR_PO_PERMISSION &&
      data.CP_PERMISSION &&
      data.ARB_PURCHASE_PERMISSION &&
      data.OP_PERMISSION &&
      data.DEF_PUR_PERMISSION &&
      data.QUOTATIONS_PERMISSION &&
      data.CUSTOMER_PO_PERMISSION &&
      data.DOM_PERMISSION &&
      data.COM_PERMISSION &&
      data.BLPG_PERMISSION &&
      data.DC_PERMISSION &&
      data.SALES_RETURN_PERMISSION &&
      data.NC_PERMISSION &&
      data.RC_PERMISSION &&
      data.TV_PERMISSION &&
      data.EXP_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangeTrans = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_trans: selectAllChecked,
      VENDOR_PO_PERMISSION: selectAllChecked,
      CP_PERMISSION: selectAllChecked,
      ARB_PURCHASE_PERMISSION: selectAllChecked,
      OP_PERMISSION: selectAllChecked,
      DEF_PUR_PERMISSION: selectAllChecked,
      QUOTATIONS_PERMISSION: selectAllChecked,
      CUSTOMER_PO_PERMISSION: selectAllChecked,
      DOM_PERMISSION: selectAllChecked,
      COM_PERMISSION: selectAllChecked,
      BLPG_PERMISSION: selectAllChecked,
      DC_PERMISSION: selectAllChecked,
      SALES_RETURN_PERMISSION: selectAllChecked,
      NC_PERMISSION: selectAllChecked,
      RC_PERMISSION: selectAllChecked,
      TV_PERMISSION: selectAllChecked,
      EXP_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangeTrans = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_trans = allPermissionsSelectedTrans(updatedData);
      return updatedData;
    });
  };

  //   Cash & Bank

  const allPermissionsSelectedCash = (data) => {
    return data.RECEIPTS_PERMISSION &&
      data.PAYMENTS_PERMISSION &&
      data.CHEQUE_CLEARANCE_PERMISSION &&
      data.BT_PERMISSION &&
      data.CN_PERMISSION &&
      data.DN_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangeCash = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_cash: selectAllChecked,
      RECEIPTS_PERMISSION: selectAllChecked,
      PAYMENTS_PERMISSION: selectAllChecked,
      CHEQUE_CLEARANCE_PERMISSION: selectAllChecked,
      BT_PERMISSION: selectAllChecked,
      CN_PERMISSION: selectAllChecked,
      DN_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangeCash = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_cash = allPermissionsSelectedCash(updatedData);
      return updatedData;
    });
  };

  //   Cash & Bank

  const allPermissionsSelectedGSTR = (data) => {
    return data.GSTR1_PERMISSION &&
      data.GSTR2_PERMISSION &&
      data.GSTR3_PERMISSION &&
      data.GSTR9_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangeGSTR = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_gstr: selectAllChecked,
      GSTR1_PERMISSION: selectAllChecked,
      GSTR2_PERMISSION: selectAllChecked,
      GSTR3_PERMISSION: selectAllChecked,
      GSTR9_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangeGSTR = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_gstr = allPermissionsSelectedGSTR(updatedData);
      return updatedData;
    });
  };

  //   TDS

  const allPermissionsSelectedTdsTcs = (data) => {
    return data.TDS_PERMISSION &&
      data.TCS_PERMISSION &&
      data.TDS_TCS_RETURN_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangeTdsTcs = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_tds: selectAllChecked,
      TDS_PERMISSION: selectAllChecked,
      TCS_PERMISSION: selectAllChecked,
      TDS_TCS_RETURN_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangeTdsTcs = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_tds = allPermissionsSelectedTdsTcs(updatedData);
      return updatedData;
    });
  };

  //   Transaction Reports

  const allPermissionsSelectedTransReport = (data) => {
    return data.PURCHASE_REPORT_PERMISSION &&
      data.SALES_REPORT_PERMISSION &&
      data.NCDBC_REPORT_PERMISSION &&
      data.STOCK_REPORT_PERMISSION &&
      data.BANK_BOOK_PERMISSION &&
      data.LEDGER_REPORT_PERMISSION &&
      data.RECEIVABLE_PERMISSION &&
      data.PAYABLE_PERMISSION &&
      data.PAYMENT_RECEIPT_REPORT_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangeTransReport = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_tr_report: selectAllChecked,
      PURCHASE_REPORT_PERMISSION: selectAllChecked,
      SALES_REPORT_PERMISSION: selectAllChecked,
      NCDBC_REPORT_PERMISSION: selectAllChecked,
      STOCK_REPORT_PERMISSION: selectAllChecked,
      BANK_BOOK_PERMISSION: selectAllChecked,
      LEDGER_REPORT_PERMISSION: selectAllChecked,
      RECEIVABLE_PERMISSION: selectAllChecked,
      PAYABLE_PERMISSION: selectAllChecked,
      PAYMENT_RECEIPT_REPORT_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangeTransReport = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_tr_report =
        allPermissionsSelectedTransReport(updatedData);
      return updatedData;
    });
  };

  //   Financial Reports

  const allPermissionsSelectedFyReport = (data) => {
    return data.PARTNERS_DATA_PERMISSION &&
      data.PARTNERS_TRANSACTION_PERMISSION &&
      data.CAPITAL_ACC_SEARCH_PERMISSION &&
      data.CAPITAL_ACC_REPORT_PERMISSION &&
      data.ASSETS_MANAGEMENT_PERMISSION &&
      data.DEPRECIATION_REPORT_PERMISSION &&
      data.PROFIT_LOSS_ACC_PERMISSION &&
      data.BALANCE_SHEET_PERMISSION &&
      data.PROFITABILITY_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangeFyReport = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_fy_report: selectAllChecked,
      PARTNERS_DATA_PERMISSION: selectAllChecked,
      PARTNERS_TRANSACTION_PERMISSION: selectAllChecked,
      CAPITAL_ACC_SEARCH_PERMISSION: selectAllChecked,
      CAPITAL_ACC_REPORT_PERMISSION: selectAllChecked,
      ASSETS_MANAGEMENT_PERMISSION: selectAllChecked,
      DEPRECIATION_REPORT_PERMISSION: selectAllChecked,
      PROFIT_LOSS_ACC_PERMISSION: selectAllChecked,
      BALANCE_SHEET_PERMISSION: selectAllChecked,
      PROFITABILITY_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangeFyReport = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_fy_report =
        allPermissionsSelectedFyReport(updatedData);
      return updatedData;
    });
  };

  //   Payroll

  const allPermissionsSelectedPayroll = (data) => {
    return data.PAY_RUNS_PERMISSION &&
      data.LOANS_PERMISSION &&
      data.REPORTS_PERMISSION &&
      data.PAYROLL_STAFF_PERMISSION
      ? 1
      : 0;
  };

  const handleSelectAllChangePayroll = (e) => {
    const selectAllChecked = e.target.checked ? 1 : 0;
    setAddData((prevData) => ({
      ...prevData,
      Select_All_payroll: selectAllChecked,
      PAY_RUNS_PERMISSION: selectAllChecked,
      LOANS_PERMISSION: selectAllChecked,
      REPORTS_PERMISSION: selectAllChecked,
      PAYROLL_STAFF_PERMISSION: selectAllChecked,
    }));
  };

  const handleIndividualChangePayroll = (field) => {
    setAddData((prevData) => {
      const updatedData = {
        ...prevData,
        [field]: prevData[field] ? 0 : 1,
      };
      updatedData.Select_All_payroll =
        allPermissionsSelectedPayroll(updatedData);
      return updatedData;
    });
  };

  return (
    <div className="container">
      {/* HEADER DATA */}
      <PageHeading
        title={"EMPLOYEE MASTER"}
        right={
          <>
            {addData?.length != 1 && (
              <AddButton
                onClick={async () => {
                  setIsAdd(true);
                  setAddData({});
                  setType("add");
                }}
              />
            )}
            <BackButton />
            <HelpButton
              link={[
                "https://youtu.be/4FzTz5cY6bc",
                "https://youtu.be/JcUiQNwM4QY",
              ]}
            />
          </>
        }
      />

      {/* LIST DATA  */}

      {totalCount > 5 && (
        <Pagination2
          top={true}
          search={false}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}
      {employee_loading ? (
        <PageLoading />
      ) : (
        <Table
          mt={5}
          nodata={
            employee_list?.length !== 0 && !employee_loading ? false : true
          }
          headData={[
            "EMPLOYEE CODE",
            "EMPLOYEE NAME",
            "ROLE",
            "EMAIL",
            "MOBILE",
            "ACTIONS",
          ]}
          body={
            <>
              {employee_list?.length != 0 &&
                employee_list?.map((item, i) => (
                  <tr key={i}>
                    <td
                      style={{ cursor: "pointer" }}
                      //   onClick={() => {
                      //     setOpen(true);
                      //   }}
                    >
                      {item?.EMPLOYEE_CODE}
                    </td>
                    <td>{item?.NAME}</td>
                    <td>{getStaffCategory(item?.ROLE)}</td>
                    <td>{item?.EMAIL}</td>
                    <td>{item?.MOBILE}</td>
                    <td className="flex gap10 aic jcc">
                      <DeleteIcon
                        onClick={() => {
                          setId(item?.ID);
                          setOpen(true);
                        }}
                      />
                      <EditButton
                        onClick={() => {
                          setIsAdd(true);

                          const obj = {
                            ...item,
                            Select_All: allPermissionsSelected(item),
                            Select_All_trans: allPermissionsSelectedTrans(item),
                            Select_All_cash: allPermissionsSelectedCash(item),
                            Select_All_gstr: allPermissionsSelectedGSTR(item),
                            Select_All_tds: allPermissionsSelectedTdsTcs(item),
                            Select_All_tr_report:
                              allPermissionsSelectedTransReport(item),
                            Select_All_fy_report:
                              allPermissionsSelectedFyReport(item),
                            Select_All_payroll:
                              allPermissionsSelectedPayroll(item),
                          };

                          setAddData({ ...addData, ...obj });

                          setType("edit");
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </>
          }
        />
      )}
      {totalCount > 5 && (
        <Pagination2
          bottom={true}
          totalCount={totalCount}
          SerchInput={SerchInput}
          setSearchInput={setSearchInput}
          pageNo={pageNo}
          setPageNo={setPageNo}
          entries={entries}
          setEntries={setEntries}
        />
      )}

      {/* ADD DATA */}
      {isAdd && (
        <Modal
          onCancel={() => {
            setAddData({});
            setType("");
          }}
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          title={`${type.toUpperCase()} EMPLOYEE DETAILS`}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Input
                  label={"EMPLOYEE CODE"}
                  placeholder={"EMPLOYEE CODE"}
                  disabled={type == "edit"}
                  value={addData.EMPLOYEE_CODE}
                  onChange={(e) => {
                    const input = e.target.value
                      .toUpperCase()
                      .replace(/[^a-zA-Z0-9]/g, "");
                    if (input.length <= 10) {
                      setAddData({
                        ...addData,
                        EMPLOYEE_CODE: input?.trim(),
                      });
                    }
                  }}
                />
                <Input
                  label={"EMPLOYEE NAME"}
                  placeholder={"EMPLOYEE NAME"}
                  value={addData.NAME}
                  onChange={(e) => {
                    const input = e.target.value.replace(/\s\s+/g, "");
                    setAddData({ ...addData, NAME: input });
                  }}
                />
                <Dropdown
                  width2=""
                  label={"ROLE"}
                  value={addData.ROLE}
                  onChange={(e) => {
                    setAddData({ ...addData, ROLE: e.target.value });
                  }}
                  options={
                    <>
                      <option value="">SELECT</option>
                      <option value="1">MANAGER</option>
                      <option value="2">GODOWN MANAGER</option>
                      <option value="3">ACCOUNTS</option>
                    </>
                  }
                />
                <Input
                  label={"EMAIL"}
                  placeholder={"EMAIL"}
                  value={addData.EMAIL}
                  onChange={(e) => {
                    setAddData({ ...addData, EMAIL: e.target.value?.trim() });
                  }}
                />
                <Input
                  label={"MOBILE"}
                  placeholder={"MOBILE"}
                  value={addData.MOBILE}
                  onChange={(e) => {
                    const input = e.target.value?.replace(/\D/g, "");
                    if (input?.length <= 10) {
                      setAddData({ ...addData, MOBILE: input });
                    }
                  }}
                />
              </div>

              {/* Master Data */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Master Data"}
                  Checked={addData.Select_All}
                  onChange={handleSelectAllChange}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"equipment master"}
                    Checked={addData.EQUIPMENT_MASTER_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("EQUIPMENT_MASTER_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"ARB master"}
                    Checked={addData.ARB_MASTER_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("ARB_MASTER_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Service master"}
                    Checked={addData.SERVICE_MASTER_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("SERVICE_MASTER_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Area Code master"}
                    Checked={addData.AREA_CODE_MASTER_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("AREA_CODE_MASTER_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"NC/DBC Packaging"}
                    Checked={addData.BOM_PERMISSION}
                    onChange={() => handleIndividualChange("BOM_PERMISSION")}
                  />
                  <CheckBox
                    lable={"Refill Price"}
                    Checked={addData.REFILL_PRICE_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("REFILL_PRICE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"BLPG/ARB/NFR master"}
                    Checked={addData.ARB_PRICE_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("ARB_PRICE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Cust/Vend master"}
                    Checked={addData.CUST_VEND_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("CUST_VEND_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Vehicle master"}
                    Checked={addData.VEHICLE_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("VEHICLE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Staff master"}
                    Checked={addData.STAFF_PERMISSION}
                    onChange={() => handleIndividualChange("STAFF_PERMISSION")}
                  />
                  <CheckBox
                    lable={"Discount/credit master"}
                    Checked={addData.DISC_CREDIT_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("DISC_CREDIT_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"bank master"}
                    Checked={addData.BANK_PERMISSION}
                    onChange={() => handleIndividualChange("BANK_PERMISSION")}
                  />
                  <CheckBox
                    lable={"Stock Adjustment master"}
                    Checked={addData.STOCK_ADJ_PERMISSION}
                    onChange={() =>
                      handleIndividualChange("STOCK_ADJ_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Set Alerts"}
                    Checked={addData.ALERT_PERMISSION}
                    onChange={() => handleIndividualChange("ALERT_PERMISSION")}
                  />
                </div>
              </div>

              {/* Transaction Data */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Transactions"}
                  Checked={addData.Select_All_trans}
                  onChange={handleSelectAllChangeTrans}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"Vendor PO"}
                    Checked={addData.VENDOR_PO_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("VENDOR_PO_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Cylinder Purchase"}
                    Checked={addData.CP_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("CP_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"ARB Purchase"}
                    Checked={addData.ARB_PURCHASE_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("ARB_PURCHASE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Other Purchase"}
                    Checked={addData.OP_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("OP_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Defective Pur Return"}
                    Checked={addData.DEF_PUR_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("DEF_PUR_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Quotations"}
                    Checked={addData.QUOTATIONS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("QUOTATIONS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Customer PO"}
                    Checked={addData.CUSTOMER_PO_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("CUSTOMER_PO_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"DOM Sales"}
                    Checked={addData.DOM_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("DOM_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"COM Sales"}
                    Checked={addData.COM_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("COM_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"ARB Sales"}
                    Checked={addData.BLPG_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("BLPG_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Delivery Challan"}
                    Checked={addData.DC_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("DC_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Sales Return"}
                    Checked={addData.SALES_RETURN_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("SALES_RETURN_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"NC/DBC Sales"}
                    Checked={addData.NC_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("NC_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"RC Sales"}
                    Checked={addData.RC_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("RC_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"TV Sales"}
                    Checked={addData.TV_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("TV_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Expencess"}
                    Checked={addData.EXP_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTrans("EXP_PERMISSION")
                    }
                  />
                </div>
              </div>

              {/* Cash & Bank */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Cash & Bank"}
                  Checked={addData.Select_All_cash}
                  onChange={handleSelectAllChangeCash}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"Receipts"}
                    Checked={addData.RECEIPTS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeCash("RECEIPTS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Payments"}
                    Checked={addData.PAYMENTS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeCash("PAYMENTS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Cheque Clearance"}
                    Checked={addData.CHEQUE_CLEARANCE_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeCash("CHEQUE_CLEARANCE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Bank Transaction"}
                    Checked={addData.BT_PERMISSION}
                    onChange={() => handleIndividualChangeCash("BT_PERMISSION")}
                  />
                  <CheckBox
                    lable={"Credit Note"}
                    Checked={addData.CN_PERMISSION}
                    onChange={() => handleIndividualChangeCash("CN_PERMISSION")}
                  />
                  <CheckBox
                    lable={"Debit Note"}
                    Checked={addData.DN_PERMISSION}
                    onChange={() => handleIndividualChangeCash("DN_PERMISSION")}
                  />
                </div>
              </div>

              {/* GSTR */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"GSTR"}
                  Checked={addData.Select_All_gstr}
                  onChange={handleSelectAllChangeGSTR}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"GSTR1"}
                    Checked={addData.GSTR1_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeGSTR("GSTR1_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"GSTR2"}
                    Checked={addData.GSTR2_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeGSTR("GSTR2_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"GSTR3"}
                    Checked={addData.GSTR3_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeGSTR("GSTR3_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"GSTR9"}
                    Checked={addData.GSTR9_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeGSTR("GSTR9_PERMISSION")
                    }
                  />
                </div>
              </div>

              {/* TDS/TCS  */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"TDS/TCS"}
                  Checked={addData.Select_All_tds}
                  onChange={handleSelectAllChangeTdsTcs}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"TDS"}
                    Checked={addData.TDS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTdsTcs("TDS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"TCS"}
                    Checked={addData.TCS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTdsTcs("TCS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"TDS/TCS Returns"}
                    Checked={addData.TDS_TCS_RETURN_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTdsTcs("TDS_TCS_RETURN_PERMISSION")
                    }
                  />
                </div>
              </div>

              {/*Transaction Report  */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Transaction Report"}
                  Checked={addData.Select_All_tr_report}
                  onChange={handleSelectAllChangeTransReport}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"Purchase Report"}
                    Checked={addData.PURCHASE_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport(
                        "PURCHASE_REPORT_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"Sales Report"}
                    Checked={addData.SALES_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport(
                        "SALES_REPORT_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"NCDBC / RC / TV Report"}
                    Checked={addData.NCDBC_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport(
                        "NCDBC_REPORT_PERMISSION"
                      )
                    }
                  />

                  <CheckBox
                    lable={"Stock Report"}
                    Checked={addData.STOCK_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport(
                        "STOCK_REPORT_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"Bank Book Report"}
                    Checked={addData.BANK_BOOK_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport("BANK_BOOK_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"ledger Report"}
                    Checked={addData.LEDGER_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport(
                        "LEDGER_REPORT_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"Receivables Report"}
                    Checked={addData.RECEIVABLE_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport("RECEIVABLE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Payables Report"}
                    Checked={addData.PAYABLE_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport("PAYABLE_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Payment and Receipt Report"}
                    Checked={addData.PAYMENT_RECEIPT_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeTransReport(
                        "PAYMENT_RECEIPT_REPORT_PERMISSION"
                      )
                    }
                  />
                </div>
              </div>

              {/*Financial Report  */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Financial Report"}
                  Checked={addData.Select_All_fy_report}
                  onChange={handleSelectAllChangeFyReport}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  {/* <CheckBox
                    lable={"Capital Account"}
                    Checked={addData.CAPITAL_ACC_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport("CAPITAL_ACC_REPORT_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Depreciation Account"}
                    Checked={addData.DEPRECIATION_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport(
                        "DEPRECIATION_REPORT_PERMISSION"
                      )
                    }
                  /> */}

                  <CheckBox
                    lable={"PARTNERS DATA"}
                    Checked={addData.PARTNERS_DATA_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport("PARTNERS_DATA_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"PARTNERS TRANSACTION "}
                    Checked={addData.PARTNERS_TRANSACTION_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport(
                        "PARTNERS_TRANSACTION_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"CAPITAL ACC SEARCH "}
                    Checked={addData.CAPITAL_ACC_SEARCH_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport(
                        "CAPITAL_ACC_SEARCH_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"CAPITAL ACCOUNT "}
                    Checked={addData.CAPITAL_ACC_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport("CAPITAL_ACC_REPORT_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"ASSETS MANAGEMENT "}
                    Checked={addData.ASSETS_MANAGEMENT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport(
                        "ASSETS_MANAGEMENT_PERMISSION"
                      )
                    }
                  />
                  <CheckBox
                    lable={"DEPRECIATION_REPORT_PERMISSION"}
                    Checked={addData.DEPRECIATION_REPORT_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport(
                        "DEPRECIATION_REPORT_PERMISSION"
                      )
                    }
                  />

                  <CheckBox
                    lable={"Profit and Loss Account"}
                    Checked={addData.PROFIT_LOSS_ACC_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport(
                        "PROFIT_LOSS_ACC_PERMISSION"
                      )
                    }
                  />

                  <CheckBox
                    lable={"Balance Sheet"}
                    Checked={addData.BALANCE_SHEET_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport("BALANCE_SHEET_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Profitability Analysis"}
                    Checked={addData.PROFITABILITY_PERMISSION}
                    onChange={() =>
                      handleIndividualChangeFyReport("PROFITABILITY_PERMISSION")
                    }
                  />
                </div>
              </div>

              {/* Payroll */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Payroll"}
                  Checked={addData.Select_All_payroll}
                  onChange={handleSelectAllChangePayroll}
                />

                <div className="flex jcfs aifs gap10 wrap">
                  <CheckBox
                    lable={"STAFF MASTER"}
                    Checked={addData.PAYROLL_STAFF_PERMISSION}
                    onChange={() =>
                      handleIndividualChangePayroll("PAYROLL_STAFF_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Pay Runs"}
                    Checked={addData.PAY_RUNS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangePayroll("PAY_RUNS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Loans"}
                    Checked={addData.LOANS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangePayroll("LOANS_PERMISSION")
                    }
                  />
                  <CheckBox
                    lable={"Reports"}
                    Checked={addData.REPORTS_PERMISSION}
                    onChange={() =>
                      handleIndividualChangePayroll("REPORTS_PERMISSION")
                    }
                  />
                </div>
              </div>

              {/* Day End Report */}

              <div
                className="column flex gap10 aifs jcfs"
                style={{
                  flexWrap: "wrap",
                  marginTop: "20px",
                }}
              >
                <CheckBox
                  lable={"Day End Report"}
                  Checked={addData.DAY_END_PERMISSION}
                  onChange={() => {
                    setAddData({
                      ...addData,
                      DAY_END_PERMISSION: addData?.DAY_END_PERMISSION ? 0 : 1,
                    });
                  }}
                />
              </div>

              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <SaveButton
                  title={type == "add" ? "Save" : "Update"}
                  back
                  onClick={update_data}
                />
              </div>
            </div>
          }
        />
      )}

      {open1 && (
        <SmallModal
          isOpen={open1}
          setIsOpen={setOpen1}
          title={"alert"}
          mess={mess}
          okbtn={setOpen1}
        />
      )}
      {open && (
        <SmallModal
          title={"Confirm"}
          isOpen={open}
          setIsOpen={setOpen}
          mess={"Are you sure? You want to delete"}
          onClick={() => delete_item()}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default EmployeeMaster;

// New Appre
//  const permissionsData = [
//   {
//     category: "Master Data",
//     selectAllKey: "Select_All",
//     onChange: "handleSelectAllChange",
//     permissions: [
//       { label: "Equipment Master", key: "EQUIPMENT_MASTER_PERMISSION" },
//       { label: "ARB Master", key: "ARB_MASTER_PERMISSION" },
//       { label: "Service Master", key: "SERVICE_MASTER_PERMISSION" },
//       { label: "Area Code Master", key: "AREA_CODE_MASTER_PERMISSION" },
//       { label: "NC/DBC Packaging", key: "BOM_PERMISSION" },
//       { label: "Refill Price", key: "REFILL_PRICE_PERMISSION" },
//       { label: "BLPG/ARB/NFR Master", key: "ARB_PRICE_PERMISSION" },
//       { label: "Cust/Vend Master", key: "CUST_VEND_PERMISSION" },
//       { label: "Vehicle Master", key: "VEHICLE_PERMISSION" },
//       { label: "Staff Master", key: "STAFF_PERMISSION" },
//       { label: "Discount/Credit Master", key: "DISC_CREDIT_PERMISSION" },
//       { label: "Bank Master", key: "BANK_PERMISSION" },
//       { label: "Stock Adjustment Master", key: "STOCK_ADJ_PERMISSION" },
//       { label: "Set Alerts", key: "ALERT_PERMISSION" },
//     ],
//   },
//   {
//     category: "Transactions",
//     selectAllKey: "Select_All_trans",
//     onChange: "handleSelectAllChangeTrans",
//     permissions: [
//       { label: "Vendor PO", key: "VENDOR_PO_PERMISSION" },
//       { label: "Cylinder Purchase", key: "CP_PERMISSION" },
//       { label: "ARB Purchase", key: "ARB_PURCHASE_PERMISSION" },
//       { label: "Other Purchase", key: "OP_PERMISSION" },
//       { label: "Defective Pur Return", key: "DEF_PUR_PERMISSION" },
//       { label: "Quotations", key: "QUOTATIONS_PERMISSION" },
//       { label: "Customer PO", key: "CUSTOMER_PO_PERMISSION" },
//       { label: "DOM Sales", key: "DOM_PERMISSION" },
//       { label: "COM Sales", key: "COM_PERMISSION" },
//       { label: "ARB Sales", key: "BLPG_PERMISSION" },
//       { label: "Delivery Challan", key: "DC_PERMISSION" },
//       { label: "Sales Return", key: "SALES_RETURN_PERMISSION" },
//       { label: "NC/DBC Sales", key: "NC_PERMISSION" },
//       { label: "RC Sales", key: "RC_PERMISSION" },
//       { label: "TV Sales", key: "TV_PERMISSION" },
//       { label: "Expenses", key: "EXP_PERMISSION" },
//     ],
//   },
//   {
//     category: "Cash & Bank",
//     selectAllKey: "Select_All_cash",
//     onChange: "handleSelectAllChangeCash",
//     permissions: [
//       { label: "Receipts", key: "RECEIPTS_PERMISSION" },
//       { label: "Payments", key: "PAYMENTS_PERMISSION" },
//       { label: "Cheque Clearance", key: "CHEQUE_CLEARANCE_PERMISSION" },
//       { label: "Bank Transaction", key: "BT_PERMISSION" },
//       { label: "Credit Note", key: "CN_PERMISSION" },
//       { label: "Debit Note", key: "DN_PERMISSION" },
//     ],
//   },
//   {
//     category: "GSTR",
//     selectAllKey: "Select_All_gstr",
//     onChange: "handleSelectAllChangeGSTR",
//     permissions: [
//       { label: "GSTR1", key: "GSTR1_PERMISSION" },
//       { label: "GSTR2", key: "GSTR2_PERMISSION" },
//       { label: "GSTR3", key: "GSTR3_PERMISSION" },
//       { label: "GSTR9", key: "GSTR9_PERMISSION" },
//     ],
//   },
//   {
//     category: "TDS/TCS",
//     selectAllKey: "Select_All_tds",
//     onChange: "handleSelectAllChangeTdsTcs",
//     permissions: [
//       { label: "TDS", key: "TDS_PERMISSION" },
//       { label: "TCS", key: "TCS_PERMISSION" },
//       { label: "TDS/TCS Returns", key: "TDS_TCS_RETURN_PERMISSION" },
//     ],
//   },
//   {
//     category: "Transaction Report",
//     selectAllKey: "Select_All_tr_report",
//     onChange: "handleSelectAllChangeTransReport",
//     permissions: [
//       { label: "Purchase Report", key: "PURCHASE_REPORT_PERMISSION" },
//       { label: "Sales Report", key: "SALES_REPORT_PERMISSION" },
//       { label: "NCDBC / RC / TV Report", key: "NCDBC_REPORT_PERMISSION" },
//       { label: "Stock Report", key: "STOCK_REPORT_PERMISSION" },
//       { label: "Bank Book Report", key: "BANK_BOOK_PERMISSION" },
//       { label: "Ledger Report", key: "LEDGER_REPORT_PERMISSION" },
//       { label: "Receivables Report", key: "RECEIVABLE_PERMISSION" },
//       { label: "Payables Report", key: "PAYABLE_PERMISSION" },
//       { label: "Payment and Receipt Report", key: "PAYMENT_RECEIPT_REPORT_PERMISSION" },
//     ],
//   },
//   {
//     category: "Financial Report",
//     selectAllKey: "Select_All_fy_report",
//     onChange: "handleSelectAllChangeFyReport",
//     permissions: [
//       { label: "Capital Account", key: "CAPITAL_ACC_REPORT_PERMISSION" },
//       { label: "Depreciation Account", key: "DEPRECIATION_REPORT_PERMISSION" },
//       { label: "Profit and Loss Account", key: "PROFIT_LOSS_ACC_PERMISSION" },
//       { label: "Balance Sheet", key: "BALANCE_SHEET_PERMISSION" },
//       { label: "Profitability Analysis", key: "PROFITABILITY_PERMISSION" },
//     ],
//   },
//   {
//     category: "Payroll",
//     selectAllKey: "Select_All_payroll",
//     onChange: "handleSelectAllChangePayroll",
//     permissions: [
//       { label: "Staff Master", key: "PAYROLL_STAFF_PERMISSION" },
//       { label: "Pay Runs", key: "PAY_RUNS_PERMISSION" },
//       { label: "Loans", key: "LOANS_PERMISSION" },
//       { label: "Reports", key: "REPORTS_PERMISSION" },
//     ],
//   },
//   {
//     category: "Day End Report",
//     permissions: [
//       { label: "Day End Report", key: "DAY_END_PERMISSION" },
//     ],
//   },
// ];
