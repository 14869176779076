import React, { useEffect, useRef, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { DeleteIcon, Radio } from "../../../utils/utils";
import { defective_pur_return_API } from "../../../utils/apis";
import PageLoading from "../../../components/PageLoading";
import RowTxt from "../../../components/RowTxt";
import moment from "moment";
import { motion } from "framer-motion";
import { HiOutlineArrowRight } from "react-icons/hi";
import { toast } from "react-toastify";
import {
  Pagination2,
  Pagination3,
} from "../../../components/pagination/pagination";
import Modal from "../../../components/modal/Modal";
import Table from "../../../components/table/Table";
import SmallModal from "../../../components/modal/SmallModal";
import Loader from "../../../components/Loader";
import RadioBtn from "../../../components/radio/RadioBtn";
import Input from "../../../components/inputs/Input";
import Dropdown from "../../../components/inputs/Dropdown";
import { useSelector } from "react-redux";
import store from "../../../redux/store";
import {
  get_arb_list,
  get_bank_master_list,
  get_equipment_list,
  get_vehical_list,
} from "../../MasterData/masterdataSlice";
import {
  get_arb_purchase_list,
  get_cylinder_purchase_list,
  get_defective_purchase_list,
  get_transition_complete_list,
} from "../trasactionsSlice";
import {
  DateValidator2,
  UnitFunction,
  VehicalValidator,
  gst_calculate,
} from "../../../utils/validation";
import {
  ARBPurchaseList,
  CylinderPurchaseList,
} from "../../OtherComponent/StaticLists/staticLists";
import NoData from "../../../components/NoData";
import { alfaNumericWithoutSpace } from "../../../utils/Rejex";
import ReactToPrint from "react-to-print";
import { TaxInvoice } from "../../OtherComponent/TaxInvoice/TaxInvoice";
import { Alerts } from "../../../components/Alerts/Alerts";
import TaxInvoice2 from "../../OtherComponent/TaxInvoice/TaxInvoice2";
import { UOMData } from "../../../utils/data";
import SearchApiHook from "../../../CustomHooks/SearchApiHook";
import { DropInput } from "../../../components/inputs/DropInput";
import { EmptyDataHook } from "../../../utils/Hook/EmptyDataHook";
import TaxInvoice3 from "../../OtherComponent/TaxInvoice/TaxInvoice3";
import { ReduceFunction } from "../../../utils/Hook/ReduceFunction";

const tax_invoice_table_header = [
  { title: "Sl No.", cols: false },
  { title: "Description of Goods", cols: true },
  { title: "HSN/SAC", cols: false },
  { title: "GST Rate", cols: false },
  { title: "Quantity", cols: false },
  { title: "Rate Incl. GST", cols: false },
  { title: "Rate", cols: false },
  { title: "per", cols: false },
  // { title:"Disc.% Cash",cols:false},
  { title: "Discount", cols: false },
  { title: "Net Weight", cols: false },
  // { title: "Transport Charge" ,cols:false},
  { title: "Amount", cols: false },
];

export const DefectiveReturn = () => {
  const previewRef = useRef(null);
  const [pageNo, setPageNo] = useState(1);
  const [SerchInput, setSearchInput] = useState("");
  const [entries, setEntries] = useState("5");
  const [totalCount, setTotalCount] = useState("");
  const [prevSearch, setPrevSearch] = useState("");
  const [SearchCount, setSearchCount] = useState(0);
  const [viewItem, setViewItem] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [addData, setAddData] = useState([]);
  const [addDataHead, setAddDataHead] = useState({});
  const [CPClick, setCPClick] = useState(false);
  const [ARBPClick, setARBPClick] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMess, setAlertMess] = useState("");

  const { godown_address_list } = useSelector((state) => state.profile);

  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
    setSearchCount: setSearchCount,
    search: SerchInput,
  };
  const { arb_list, bank_master_list, vehical_list } = useSelector(
    (state) => state.masterdata
  );
  const { user_id, today, emp_id, user_type } = useSelector(
    (state) => state.other
  );
  const {
    transition_complete_list,
    cylinder_purchase_list,
    arb_purchase_list,
    defective_purchase_list,
    defective_purchase_loading,
  } = useSelector((state) => state.transactions);

  useEffect(() => {
    store.dispatch(get_transition_complete_list());
    store.dispatch(get_arb_list());
    store.dispatch(get_arb_purchase_list());
    store.dispatch(get_cylinder_purchase_list());
    store.dispatch(get_equipment_list());
    store.dispatch(get_bank_master_list());
    store.dispatch(get_vehical_list());
  }, []);

  const fetchData = () => {
    store.dispatch(get_defective_purchase_list(obj));
  };

  SearchApiHook(fetchData, SerchInput?.trim(), prevSearch, [entries, pageNo]);

  const calculate = () => {
    if (!addDataHead.vender_name) {
      setAlertMess("Please select VENDOR NAME");
      setOpenAlert(true);
      return;
    }
    if (
      addData.find(
        (v) =>
          !v?.rtn_qty ||
          v?.rtn_qty == 0 ||
          (v?.rtn_qty && v?.rtn_qty.length == 0) ||
          !v?.net_weight ||
          v?.net_weight == 0 ||
          (v?.net_weight && v?.net_weight.length == 0)
      )
    ) {
      setAlertMess(`Please Enter RETURN QUANTITY.
                  Please Enter Net Weight.`);

      setOpenAlert(true);
      return;
    }
    var total = 0;
    addData.forEach((data, index) => {
      const unit_rate = Number(data.unit_rate);
      const quantity = Number(data.rtn_qty);
      const p_gst = Number(data.gstp);

      const tax_amount = unit_rate * quantity;
      const gst_amount = tax_amount * (p_gst / 100);
      const amount = gst_amount + tax_amount;

      setAddData((pre) =>
        pre.map((v, i) => {
          if (i == index) {
            if (addDataHead?.c_type === "isp") {
              return {
                ...v,
                taxable_amt: Number(tax_amount).toFixed(2),
                igst_amount: Number(gst_amount).toFixed(2),
                cgst_amount: "0.0",
                sgst_amount: "0.0",
                amount: Number(amount).toFixed(2),
              };
            } else if (addDataHead?.c_type === "lp") {
              return {
                ...v,
                taxable_amt: Number(tax_amount).toFixed(2),
                cgst_amount: Number(gst_amount / 2).toFixed(2),
                sgst_amount: Number(gst_amount / 2).toFixed(2),
                igst_amount: "0.0",
                amount: Number(amount).toFixed(2),
              };
            }
          }
          return { ...v };
        })
      );
      total += amount;
    });
    setAddDataHead({
      ...addDataHead,
      total_amount: Number(total).toFixed(2),
    });
  };

  const handleDeleteProduct = async (deleteid) => {
    setShowLoader(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteid);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }
    const response = await defective_pur_return_API(body);
    setShowLoader(false);
    if (response.success) {
      setShowDeleteAlert(false);
      toast(response.message);
      store.dispatch(get_defective_purchase_list(obj));
    }
  };

  const validation_check = () => {
    if (
      addData.find(
        (v) =>
          !v?.product ||
          (v?.product && v?.product?.length == 0) ||
          !v?.gstp ||
          (v?.gstp && v?.gstp?.length == 0) ||
          !v?.unit_rate ||
          (v?.unit_rate && v?.unit_rate?.length == 0) ||
          !v?.rtn_qty ||
          v?.rtn_qty == 0 ||
          (v?.rtn_qty && v?.rtn_qty?.length == 0) ||
          !v?.net_weight ||
          v?.net_weight == 0 ||
          (v?.net_weight && v?.net_weight?.length == 0) ||
          (addDataHead?.type == "CP" &&
            (!v?.tare_weight ||
              v?.tare_weight == 0 ||
              (v?.tare_weight && v?.tare_weight?.length == 0) ||
              !v?.gross_weight ||
              v?.gross_weight == 0 ||
              (v?.gross_weight && v?.gross_weight?.length == 0) ||
              !v?.cyl_sr_no ||
              v?.cyl_sr_no == 0 ||
              (v?.cyl_sr_no && v?.cyl_sr_no?.length == 0))) ||
          !v?.taxable_amt ||
          (v?.taxable_amt && v?.taxable_amt?.length == 0) ||
          !v?.igst_amount ||
          (v?.igst_amount && v?.igst_amount?.length == 0) ||
          !v?.sgst_amount ||
          (v?.sgst_amount && v?.sgst_amount?.length == 0) ||
          !v?.cgst_amount ||
          (v?.cgst_amount && v?.cgst_amount?.length == 0) ||
          !v?.amount ||
          (v?.amount && v?.amount?.length == 0)
      )
    ) {
      return true;
    }
  };

  const valiDate = () => {
    const data = [];
    if (
      !addDataHead?.purchase_invoice ||
      (addDataHead?.purchase_invoice.trim() &&
        addDataHead?.purchase_invoice.trim()?.length === 0)
    ) {
      data.push("Please Enter Purchase Invoice Details");
    }

    if (
      !addDataHead?.vender_name ||
      (addDataHead?.vender_name && addDataHead?.vender_name?.length === 0)
    ) {
      data.push("Please Select VENDOR NAME");
    }
    if (
      !addDataHead?.total_amount ||
      (addDataHead?.total_amount && addDataHead?.total_amount?.length === 0)
    ) {
      data.push("Please Click on Calculate");
    }
    if (
      !addDataHead?.narration ||
      (addDataHead?.narration && addDataHead?.narration?.trim()?.length === 0)
    ) {
      data.push("Please Enter Narration");
    }
    if (
      !addDataHead?.return_date ||
      (addDataHead?.return_date && addDataHead?.return_date?.length === 0)
    ) {
      data.push("Please Enter Return Date");
    } else if (
      DateValidator2(
        moment(addDataHead?.return_date).format("DD/MM/YYYY"),
        today,
        moment(addDataHead?.purchase_inv_date).format("DD/MM/YYYY")
      ) != true
    ) {
      data.push(
        ...DateValidator2(
          moment(addDataHead?.return_date).format("DD/MM/YYYY"),
          today,
          moment(addDataHead?.purchase_inv_date).format("DD/MM/YYYY"),
          `Return Date cant be before ${moment(
            addDataHead?.purchase_inv_date
          ).format("DD-MMM-YYYY")} `,
          `Return Date cant be a future date`
        )
      );
    }
    // if (
    //   addDataHead?.godown_req &&
    //   godown_address_list?.length > 1 &&
    //   (!addDataHead?.godown ||
    //     (addDataHead?.godown && addDataHead?.godown?.length === 0))
    // ) {
    //   data.push("Please Select Dispatch Godown Address");
    // }

    for (let v of addData) {
      if (!v?.product || (v?.product && v?.product?.length === 0)) {
        data.push("Please Select PRODUCT");
      }
      if (
        !v?.unit_rate ||
        (v?.unit_rate && v?.unit_rate?.length === 0) ||
        !v?.gstp ||
        (v?.gstp && v?.gstp?.length === 0)
      ) {
        data.push("Please Clck on FETCH GST ");
      }
      if (!v?.rtn_qty || (v?.rtn_qty && v?.rtn_qty?.length === 0)) {
        data.push("Please Enter Return Quantity");
      } else if (v?.rtn_qty == 0) {
        data.push("Please Enter valid Quantity");
      } else if (v?.rtn_qty > v?.quantity) {
        data.push("Return Quantity cant be greater then Purchase Quantity");
      }
      // if (!v?.net_weight || (v?.net_weight && v?.net_weight?.length === 0)) {
      //   data.push("Please Enter Net Weight");
      // } else
      //  if (v?.net_weight == 0) {
      //   data.push("Please valid Net Weight");
      // }

      if (addDataHead?.type == "CP") {
        if (
          !v?.tare_weight ||
          (v?.tare_weight && v?.tare_weight?.length === 0)
        ) {
          data.push("Please Enter Cylinder Tare Weight");
        } else if (v?.tare_weight == 0) {
          data.push("Please valid Cylinder Tare Weight");
        }
        if (
          !v?.gross_weight ||
          (v?.gross_weight && v?.gross_weight?.length === 0)
        ) {
          data.push("Please Enter Cylinder Gross Weight");
        } else if (v?.gross_weight == 0) {
          data.push("Please valid Cylinder Gross Weight");
        }
        if (v?.gross_weight && v?.tare_weight) {
          if (Number(v?.gross_weight) < Number(v?.tare_weight)) {
            data.push("Tare Weight cant be greater then to gross weight ");
          }
        }
        if (!v?.cyl_sr_no || (v?.cyl_sr_no && v?.cyl_sr_no?.length === 0)) {
          data.push("Please Enter Cylinder Serial Number");
        } else if (v?.cyl_sr_no == 15) {
          data.push("Please valid Cylinder Serial Number");
        }
      }
      if (
        !addDataHead?.total_amount ||
        (addDataHead?.total_amount && addDataHead?.total_amount?.length == 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (
        !v?.taxable_amt ||
        (v?.taxable_amt && v?.taxable_amt?.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (
        !v?.igst_amount ||
        (v?.igst_amount && v?.igst_amount?.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (
        !v?.sgst_amount ||
        (v?.sgst_amount && v?.sgst_amount?.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (
        !v?.cgst_amount ||
        (v?.cgst_amount && v?.cgst_amount?.length === 0)
      ) {
        data.push("Please Click on Calculate");
      } else if (!v?.amount || (v?.amount && v?.amount?.length === 0)) {
        data.push("Please Click on Calculate");
      }
      if (
        !v?.vahicle_number ||
        (v?.vahicle_number && v?.vahicle_number?.length === 0)
      ) {
        data.push("Please Enter Vehicle Number first if not Enter NA.");
      } else if (
        v?.vahicle_number != "NA" &&
        VehicalValidator(v?.vahicle_number) !== true
      ) {
        const err = VehicalValidator(v?.vahicle_number);
        console.log("err ====", err);

        if (err?.length > 0) {
          data.push(...err);
        }
      }
    }
    return data;
  };

  const add_items = async () => {
    const data = valiDate();
    if (data?.length == 0) {
      try {
        setShowLoader(true);
        const body = new FormData();
        body.append("action", "create");
        body.append("agencyid", user_id);
        body.append("connection_type", addDataHead?.c_type);
        body.append("inv_ref_no", addDataHead?.inv_num.trim());
        body.append("inv_date", addDataHead?.purchase_inv_date);
        body.append("cvo_id", addDataHead?.vender_name);
        body.append("narration", addDataHead?.narration.trim());
        body.append("inv_amt", addDataHead?.total_amount);
        body.append("return_date", addDataHead?.return_date);
        // if (addDataHead?.godown_req) {
        //   body.append(
        //     "godown_id",
        //     addDataHead?.godown || godown_address_list?.[0]?.ID || 0
        //   );
        // }

        addData.forEach((item) => {
          if (item) {
            body.append("procode[]", item?.product);
            body.append("reason[]", item?.reason);
            body.append("gstp[]", item?.gstp);
            body.append("quantity[]", item?.rtn_qty);
            body.append("unit_rate[]", item?.unit_rate);
            body.append(
              "sr_no[]",
              item?.cyl_sr_no == "NA" ? "" : item?.cyl_sr_no
            );
            body.append(
              "cyl_tare_weight[]",
              item?.tare_weight == "NA" ? "" : item?.tare_weight
            );
            body.append(
              "cyl_gross_weight[]",
              item?.gross_weight == "NA" ? "" : item?.gross_weight
            );
            body.append(
              "net_weight[]",
              item?.net_weight == "NA" ? "" : item?.net_weight
            );
            body.append("amount[]", item?.taxable_amt);
            body.append("igst_amount[]", item?.igst_amount);
            body.append("cgst_amount[]", item?.cgst_amount);
            body.append("sgst_amount[]", item?.sgst_amount);
            body.append("Aamount[]", item?.amount);
            body.append("vechical_no[]", item?.vahicle_number);

            body.append(
              "godown_id[]",
              item?.godown || godown_address_list?.[0]?.ID || 0
            );
          }
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }
        const responce = await defective_pur_return_API(body);
        setShowLoader(false);
        if (responce.success) {
          store.dispatch(get_defective_purchase_list(obj));
          setAddData([]);
          setAddDataHead({});
          toast(responce.message);
          setIsAdd(false);
        } else {
          setAlertMess(responce.message);
          setOpenAlert(true);
        }
      } catch (error) {
        setShowLoader(false);
        console.log(error);
      }
    } else {
      setAlertMess(<Alerts data={data} />);
      setOpenAlert(true);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"DEFECTIVE/PURCHASE RETURN DATA"}
        right={
          <>
            <AddButton
              onClick={() => {
                setAddData((prevData) => [{}]);
                setIsAdd(true);
              }}
            />
            <BackButton />
            <HelpButton
              link={[
                "https://www.youtube.com/watch?v=gERufpqwGFg",
                "https://youtu.be/RN7HelIX6xg",
              ]}
            />
          </>
        }
      />

      {isAdd && (
        <Modal
          isOpen={isAdd}
          setIsOpen={setIsAdd}
          onCancel={() => {
            setAddData([]);
            setAddDataHead({});
          }}
          header_right={
            <div className="flex aic gap10">
              <Button title={"CP"} back onClick={() => setCPClick(!CPClick)} />
              <Button
                title={"ARBP"}
                onClick={() => setARBPClick(!ARBPClick)}
                back
              />
            </div>
          }
          title={"DEFECTIVE/PURCHASE RETURN DATA"}
          body={
            <div>
              <div className="flex aic gap10 credit-limit">
                <Dropdown
                  width2=""
                  onChange={(e) => {
                    const data = [
                      ...transition_complete_list?.Vendor,
                      ...transition_complete_list?.OMC,
                    ]?.filter((v) => v?.ID == e.target.value)[0];
                    setAddDataHead((pre) => ({
                      ...pre,
                      vender_name: e.target.value,
                      vender_name_2: data ? data?.cvo_name : "",
                      c_type:
                        e.target.value == ""
                          ? ""
                          : [
                              ...transition_complete_list?.Vendor,
                              ...transition_complete_list?.OMC,
                            ]?.filter((v) => v?.ID == e.target.value)[0]
                              .connection_type,
                      purchase_inv_date: "",
                      purchase_invoice: "",
                      dis_2: e.target.value == "" ? false : true,
                    }));
                    const emptyData = EmptyDataHook(addData?.[0]);
                    setAddData([emptyData]);
                  }}
                  value={addDataHead?.vender_name}
                  label={"VENDOR NAME"}
                  options={
                    <>
                      <option value={""}> SELECT</option>
                      {[
                        ...transition_complete_list?.Vendor,
                        ...transition_complete_list?.OMC,
                      ]?.map((item, i) => (
                        <option key={i} value={item.ID}>
                          {item.cvo_name}
                        </option>
                      ))}
                    </>
                  }
                />

                <Dropdown
                  width2=""
                  value={addDataHead?.purchase_invoice}
                  label={"PURCHASE INVOICE"}
                  onChange={(e) => {
                    const data = [
                      ...cylinder_purchase_list,
                      ...arb_purchase_list,
                    ]?.filter((item, i) => item?.ID == e.target.value);
                    const data1 =
                      data?.length > 0
                        ? [
                            ...transition_complete_list?.Vendor,
                            ...transition_complete_list?.OMC,
                          ]?.filter((v) => v?.cvo_name == data?.[0]?.cvo_name)
                        : [];
                    setAddDataHead((pre) => ({
                      ...pre,
                      purchase_invoice: e.target.value,
                      inv_num: data?.length > 0 ? data?.[0]?.INV_REF_NO : "",
                      purchase_inv_date:
                        data?.length > 0
                          ? moment(
                              data?.[0]?.invoice_date || data?.[0]?.Invoice_date
                            ).format("YYYY-MM-DD")
                          : "",
                      dis: data?.length > 0 ? true : false,
                      dis_2: data1?.length > 0 ? true : false,

                      type:
                        data?.[0]?.products?.[0]?.CAT_Name == "DOMESTIC" ||
                        data?.[0]?.products?.[0]?.CAT_Name == "COMMERCIAL"
                          ? "CP"
                          : e.target.value != ""
                          ? "ARB"
                          : "",
                      godown_req:
                        data?.length > 0
                          ? data?.[0]?.products?.[0]?.CAT_Name == "DOMESTIC" ||
                            data?.[0]?.products?.[0]?.CAT_Name == "COMMERCIAL"
                          : false,
                    }));
                    const emptyData = EmptyDataHook(addData?.[0]);
                    setAddData([emptyData]);
                  }}
                  options={
                    <>
                      <option value={""}> SELECT</option>
                      {[...cylinder_purchase_list, ...arb_purchase_list]
                        ?.filter(
                          (v, i) => v?.cvo_name == addDataHead?.vender_name_2
                        )
                        ?.map((item, i) => (
                          <option key={i} value={item.ID}>
                            {item?.INV_REF_NO} - {item.cvo_name}
                          </option>
                        ))}
                    </>
                  }
                />
                <Input
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead((pre) => ({
                        ...pre,
                        purchase_inv_date: e.target.value,
                      }));
                    }
                  }}
                  value={addDataHead?.purchase_inv_date}
                  label={"INVOICE DATE"}
                  type={"date"}
                  disabled={addDataHead?.dis}
                />

                <Input
                  label={"TOTAL AMOUNT"}
                  placeholder={"INVOICE AMOUNT"}
                  disabled={true}
                  value={addDataHead?.total_amount}
                />
                <Input
                  onChange={(e) => {
                    setAddDataHead((pre) => ({
                      ...pre,
                      narration: e.target.value,
                    }));
                  }}
                  label={"NARRATION"}
                  placeholder={"NARRATION"}
                  value={addDataHead?.narration}
                />
                <Input
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setAddDataHead((pre) => ({
                        ...pre,
                        return_date: e.target.value,
                      }));
                    }
                  }}
                  value={addDataHead?.return_date}
                  label={"RETURN DATE"}
                  type={"date"}
                />
                {/* {godown_address_list?.length > 1 && addDataHead?.godown_req && (
                  <Dropdown
                    width2=""
                    label={"Dispatch Godown"}
                    value={addDataHead?.godown}
                    onChange={(e) => {
                      setAddDataHead({
                        ...addDataHead,
                        godown: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option value={""}>SELECT</option>
                        {godown_address_list?.map((s, i) => (
                          <option key={i} value={s?.ID}>
                            {s?.UNIT_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                )} */}
              </div>
              <div className="flex aic gap10 mt10">
                <RadioBtn label={"PURCHASE TYPE"} />
                <Radio
                  title={"LOCAL PURCHASE"}
                  disabled={addDataHead?.dis_2}
                  show={addDataHead.c_type == "lp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "lp" })
                  }
                />
                <Radio
                  title={"INTER-STATE PURCHASE"}
                  disabled={addDataHead?.dis_2}
                  show={addDataHead.c_type == "isp" ? true : false}
                  setShow={() =>
                    setAddDataHead({ ...addDataHead, c_type: "isp" })
                  }
                />
              </div>
              <div
                style={{
                  maxHeight: "20%",
                  overflow: "scroll",
                  display: "flex",
                  height: "100%",
                  position: "relative",
                }}
              >
                <Table
                  headData={[
                    "PRODUCT",
                    "REASON",
                    "UNIT RATE",
                    "GST %",
                    "RETURN QUANTITY",
                    "CYL. GROSS WEIGHT",
                    "CYL. TARE  WEIGHT",
                    "CYL. SR. No.",
                    "NET WEIGHT(KGS)",
                    "TAXABLE AMOUNT",
                    "IGST AMOUNT",
                    "CGST AMOUNT",
                    "SGST AMOUNT",
                    "AMOUNT",
                    "GODOWN ADDRESS",
                    "VEHICLE NUMBER",
                    "ACTIONS",
                  ]}
                  body={addData?.map((val, ind) => (
                    <tr key={ind}>
                      <td>
                        {
                          <Dropdown
                            disabled={addData?.length > ind + 1}
                            onChange={(e) => {
                              const data = [
                                ...cylinder_purchase_list,
                                ...arb_purchase_list,
                              ]
                                ?.filter(
                                  (item) =>
                                    item?.ID == addDataHead?.purchase_invoice
                                )?.[0]
                                ?.products?.filter(
                                  (item) => item?.CODE == e.target.value
                                );
                              const check_data =
                                (data?.[0]?.CAT_Name == "DOMESTIC" ||
                                  data?.[0]?.CAT_Name == "COMMERCIAL") &&
                                e.target.value != "";

                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        product: e.target.value,
                                        godown:
                                          data?.length > 0 && data[0]?.GODOWN_ID
                                            ? data[0]?.GODOWN_ID
                                            : "",
                                        gstp:
                                          data?.length > 0
                                            ? data?.[0]?.GSTP || data?.[0]?.GST
                                            : "",
                                        unit_rate:
                                          data?.length > 0
                                            ? data?.[0]?.UNIT_PRICE
                                            : "",
                                        quantity:
                                          data?.length > 0
                                            ? data?.[0]?.QUANTITY
                                            : "",

                                        net_weight: check_data ? "" : "NA",
                                        gross_weight: check_data ? "" : "NA",
                                        tare_weight: check_data ? "" : "NA",
                                        cyl_sr_no: check_data ? "" : "NA",
                                        reason: check_data
                                          ? "1"
                                          : e.target.value != ""
                                          ? "2"
                                          : "",
                                        dis: data?.length > 0 ? true : false,
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            value={val?.product}
                            options={
                              <>
                                <option value={""}> SELECT</option>
                                {[
                                  ...cylinder_purchase_list,
                                  ...arb_purchase_list,
                                ]
                                  ?.filter(
                                    (item) =>
                                      item?.ID == addDataHead?.purchase_invoice
                                  )?.[0]
                                  ?.products?.map((v, i) => (
                                    <option
                                      disabled={
                                        addData?.filter(
                                          (d) => d?.product == v?.CODE
                                        )?.length > 0
                                      }
                                      key={i}
                                      value={v?.CODE}
                                    >
                                      {v?.CAT_Name == "DOMESTIC" ||
                                      v?.CAT_Name == "COMMERCIAL" ||
                                      v?.CAT_Name == "REGULATOR"
                                        ? ` ${v?.CAT_Name} - ${v?.CAT_DESC}`
                                        : `${v?.CAT_DESC} -  ${v?.PROD_BRAND} - ${v?.PROD_NAME}`}
                                    </option>
                                  ))}
                              </>
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <Dropdown
                            onChange={(e) => {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        reason: e.target.value,
                                      }
                                    : { ...v }
                                )
                              );
                            }}
                            disabled={val?.dis}
                            value={val?.reason}
                            options={
                              <>
                                <option value={""}> SELECT</option>
                                <option value={"1"}> DEFECTIVE ONLY</option>
                                <option value={"2"}>
                                  DEFECTIVE AND RETURN UNSOLD
                                </option>
                              </>
                            }
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.unit_rate}
                            placeholder={"UNIT RATE"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            placeholder={"GST %"}
                            value={val?.gstp}
                            disabled={true}
                          />
                        }
                      </td>
                      {/* <td>
                        {
                          <Input
                            placeholder={"PURCHASE QUANTITY"}
                            value={val?.quantity}
                            disabled={true}
                          />
                        }
                      </td> */}
                      <td>
                        <Input
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (
                              (inputValue?.length < 5 &&
                                /^\d+$/.test(inputValue)) ||
                              e.target.value == 0 ||
                              !val?.rtn_qty
                            ) {
                              setAddData((prev) =>
                                prev?.map((v, i) =>
                                  i === ind
                                    ? {
                                        ...v,
                                        rtn_qty: /^\d+$/.test(inputValue)
                                          ? inputValue
                                          : "",
                                        taxable_amt: "",
                                        igst_amount: "",
                                        cgst_amount: "",
                                        sgst_amount: "",
                                        amount: "",
                                      }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          placeholder={"RETURN QUANTITY"}
                          value={val?.rtn_qty}
                        />
                      </td>
                      <td>
                        {
                          <Input
                            disabled={
                              arb_list?.filter((v, i) => v?.ID == val?.product)
                                ?.length > 0
                            }
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length < 12 &&
                                  /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.gross_weight
                              ) {
                                setAddData((prev) =>
                                  prev?.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          gross_weight:
                                            /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          net_weight:
                                            Number(inputValue) -
                                            Number(val?.tare_weight),
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                            value={val?.gross_weight}
                            placeholder={"CYL. GROSS WEIGHT"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={
                              arb_list?.filter((v, i) => v?.ID == val?.product)
                                ?.length > 0
                            }
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              if (
                                (inputValue?.length < 12 &&
                                  /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                                    inputValue
                                  )) ||
                                event.target.value == 0 ||
                                !val?.tare_weight
                              ) {
                                setAddData((prev) =>
                                  prev?.map((v, i) =>
                                    i === ind
                                      ? {
                                          ...v,
                                          tare_weight:
                                            /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                                              inputValue
                                            )
                                              ? inputValue
                                              : "",
                                          net_weight:
                                            Number(val?.gross_weight) -
                                            Number(inputValue),
                                        }
                                      : { ...v }
                                  )
                                );
                              }
                            }}
                            value={val?.tare_weight}
                            placeholder={"CYL. TARE  WEIGHT"}
                          />
                        }
                      </td>

                      <td>
                        <Input
                          placeholder={"CYL. SR. No."}
                          disabled={
                            arb_list?.filter((v, i) => v?.ID == val?.product)
                              ?.length > 0
                          }
                          onChange={(e) => {
                            const inputValue = e.target.value?.toUpperCase();
                            if (
                              inputValue.length <= 15 &&
                              (alfaNumericWithoutSpace.test(inputValue) ||
                                inputValue === "")
                            ) {
                              setAddData((prev) =>
                                prev?.map((v, i) =>
                                  i === ind
                                    ? {
                                        ...v,
                                        cyl_sr_no: alfaNumericWithoutSpace.test(
                                          inputValue
                                        )
                                          ? inputValue
                                          : "",
                                      }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          value={val?.cyl_sr_no}
                        />
                      </td>
                      <td>
                        {
                          <Input
                            // disabled={
                            //   arb_list?.filter((v, i) => v?.ID == val?.product)
                            //     ?.length > 0
                            // }
                            // onChange={(event) => {
                            //   const inputValue = event.target.value;
                            //   if (
                            //     (inputValue?.length < 12 &&
                            //       /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                            //         inputValue
                            //       )) ||
                            //     event.target.value == 0 ||
                            //     !val?.net_weight
                            //   ) {
                            //     setAddData((prev) =>
                            //       prev?.map((v, i) =>
                            //         i === ind
                            //           ? {
                            //               ...v,
                            //               net_weight:
                            //                 /^(0|[1-9]\d{0,7})(\.\d{0,2})?$/.test(
                            //                   inputValue
                            //                 )
                            //                   ? inputValue
                            //                   : "",
                            //             }
                            //           : { ...v }
                            //       )
                            //     );
                            //   }
                            // }}
                            disabled={true}
                            value={val?.net_weight}
                            placeholder={"NET WEIGHT(KGS)"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.taxable_amt}
                            placeholder={"TAXABLE AMOUNT"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.igst_amount}
                            placeholder={"IGST AMOUNT"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.cgst_amount}
                            placeholder={"CGST AMOUNT"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.sgst_amount}
                            placeholder={"SGST AMOUNT"}
                          />
                        }
                      </td>
                      <td>
                        {
                          <Input
                            disabled={true}
                            value={val?.amount}
                            placeholder={"TOTAL AMOUNT"}
                          />
                        }
                      </td>
                      <td>
                        <Dropdown
                          width2=""
                          value={val?.godown}
                          disabled={true}
                          options={
                            <>
                              <option value={""}>SELECT</option>
                              {godown_address_list?.map((s, i) => (
                                <option key={i} value={s?.ID}>
                                  {s?.UNIT_NAME}
                                </option>
                              ))}
                            </>
                          }
                        />
                      </td>
                      <td>
                        <DropInput
                          width2=""
                          value={val?.vahicle_number?.toUpperCase()}
                          placeholder={"VEHICLE NUMBER"}
                          onChange={(e) => {
                            const data = vehical_list?.filter(
                              (v) => v?.ID == e.target.value
                            );
                            if (e.target.value.length <= 10) {
                              setAddData((pre) =>
                                pre.map((v, i) =>
                                  i == ind
                                    ? {
                                        ...v,
                                        vahicle_number_id: e.target.value,
                                        vahicle_number:
                                          data?.length > 0
                                            ? data?.[0]?.VEHICLE_NO
                                            : e.target.value,
                                      }
                                    : { ...v }
                                )
                              );
                            }
                          }}
                          list={
                            <>
                              {vehical_list?.map((s, i) => (
                                <option key={i} value={s?.ID}>
                                  {s?.VEHICLE_NO}
                                </option>
                              ))}
                            </>
                          }
                        />
                      </td>
                      <td>
                        {
                          <DeleteIcon
                            onClick={() => {
                              setAddData((pre) =>
                                pre.filter((v, i) => i != ind)
                              );
                              setAddDataHead({
                                ...addDataHead,
                                total_amount: "",
                              });
                            }}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
              </div>
              <div
                className="flex aic gap10"
                style={{ marginTop: 10, justifyContent: "flex-end" }}
              >
                <Button
                  title={"ADD"}
                  onClick={() => {
                    if (addData?.length <= 6) {
                      validation_check() &&
                        setAlertMess(
                          "Please enter all the values in current row,calculate and then add next row"
                        );
                      validation_check()
                        ? setOpenAlert(true)
                        : setAddData((pre) => [...pre, {}]);
                    } else {
                      setOpenAlert(true);
                      setAlertMess("You Can add Max 6 Product at a time");
                    }
                  }}
                />
                <Button
                  title={"CALCULATE"}
                  back
                  disable={
                    !addData[addData.length - 1]?.product ||
                    !addData[addData.length - 1].gstp ||
                    !addData[addData.length - 1].unit_rate
                      ? true
                      : false
                  }
                  onClick={() => {
                    if (addDataHead.vender_name !== "") {
                      if (
                        !addData[addData.length - 1]?.product ||
                        !addData[addData.length - 1]?.gstp ||
                        !addData[addData.length - 1]?.unit_rate ||
                        !addData[addData.length - 1]?.rtn_qty ||
                        !addData[addData.length - 1]?.net_weight
                      ) {
                        setAlertMess(
                          "Please enter all the values in current row"
                        );
                        setOpenAlert(true);
                      } else if (
                        addData[addData.length - 1]?.rtn_qty == 0 ||
                        addData[addData.length - 1]?.net_weight == 0
                      ) {
                        setAlertMess("Please enter Correct Values");
                        setOpenAlert(true);
                      } else {
                        calculate();
                      }
                    } else {
                      setAlertMess("Please Select VENDOR NAME");
                      setOpenAlert(true);
                    }
                  }}
                />
                <SaveButton onClick={() => add_items()} />
              </div>
            </div>
          }
        ></Modal>
      )}

      <Pagination3
        totalCount={totalCount > SearchCount ? SearchCount : totalCount}
        SerchInput={SerchInput}
        serchChange={(e) => {
          setSearchInput(e.target.value);
          setPrevSearch(SerchInput);
          setPageNo(1);
        }}
        pageNo={pageNo}
        setPageNo={setPageNo}
        entries={entries}
        setEntries={setEntries}
      >
        {defective_purchase_loading ? (
          <PageLoading />
        ) : defective_purchase_list?.length > 0 ? (
          <div className="grid">
            {defective_purchase_list.map((v, i) => (
              <div className="card" key={i}>
                <div className="card-heading">{v?.cvo_name}</div>
                <RowTxt
                  title={"Invoice No."}
                  value={
                    <ReactToPrint
                      trigger={() => (
                        <div
                          onMouseEnter={() => {
                            setViewItem(v);
                          }}
                          onTouchStart={() => {
                            setViewItem(v);
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setViewItem(v);
                          }}
                        >
                          {v?.SID}
                        </div>
                      )}
                      onBeforePrint={() => {
                        setViewItem(v);
                      }}
                      content={() => previewRef.current}
                      documentTitle={`${v?.cvo_name}-${v?.SID}`}
                    />
                  }
                />
                <RowTxt
                  title={"Invoice Date"}
                  value={moment(v?.invoice_date).format("DD-MMM-YYYY")}
                />
                <RowTxt title={"Return Amount"} value={v?.INV_AMT} />
                <RowTxt
                  title={"Return Date"}
                  value={moment(v?.invoice_date).format("DD-MMM-YYYY")}
                />
                <RowTxt title={"Created By"} value={v?.CREATED_NAME || "-"} />
                <div
                  className="df jcsb mt10"
                  style={{
                    justifyContent:
                      user_type != "admin" && v?.EMPLOYEE_CODE != emp_id
                        ? "flex-end"
                        : "space-between",
                  }}
                >
                  {v?.STATUS_FLAG == "0" ? (
                    <>
                      {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                        <DeleteIcon
                          onClick={() => {
                            setShowDeleteAlert(true);
                            setDeleteId(v?.ID);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <div></div>
                  )}
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpenViewModal(true);
                        setViewItem(v);
                      }}
                    >
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => {
                        setOpenViewModal(true);
                        setViewItem(v);
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoData />
        )}
      </Pagination3>

      {openViewModal && (
        <Modal
          isOpen={openViewModal}
          setIsOpen={setOpenViewModal}
          title={viewItem?.cvo_name}
          header_right={
            <ReactToPrint
              trigger={() => (
                <Button style={{ cursor: "pointer" }} title={"PRINT"} />
              )}
              content={() => previewRef.current}
              documentTitle={`${viewItem?.cvo_name}-${viewItem?.SID}`}
            />
          }
          onCancel={() => {
            setViewItem({});
          }}
          body={
            <Table
              mt={0.1}
              headData={[
                "PRODUCT",
                "REASON",
                "VEHICLE NUMBER",
                "UNIT RATE",
                "GST %",
                "RETURN QUANTITY",
                "CYL. GROSS WEIGHT",
                "CYL. TARE WEIGHT",
                "CYL. SR. No.",
                "NET WEIGHT(KGS)",
                "TAXABLE AMOUNT",
                "IGST AMOUNT",
                "CGST AMOUNT",
                "SGST AMOUNT",
                "AMOUNT",
              ]}
              body={
                <>
                  {viewItem?.products?.map((v, i) => (
                    <tr key={i}>
                      <td>
                        {v?.CAT_NAME
                          ? `${v?.CAT_NAME} - ${v?.CAT_DESC}`
                          : `${v?.PROD_BRAND} - ${v?.PROD_NAME}`}
                      </td>
                      <td>
                        {v?.REASON == "1"
                          ? " DEFECTIVE ONLY"
                          : v?.REASON == "2"
                          ? "DEFECTIVE AND RETURN UNSOLD"
                          : ""}
                      </td>
                      <td>{v?.VEHICLE_NO}</td>
                      <td>{Number(v?.UNIT_RATE).toFixed(2)}</td>
                      <td>{v?.GSTP}</td>
                      <td>{v?.RTN_QTY}</td>
                      <td>{v?.CYL_GROSS_WEIGHT || "NA"}</td>
                      <td>{v?.CYL_TARE_WEIGHT || "NA"}</td>
                      <td>{v?.SR_NO || "NA"}</td>
                      <td>{v?.NET_WEIGHT || "NA"}</td>
                      <td>{v?.AMOUNT}</td>
                      <td>{Number(v?.IGST_AMOUNT).toFixed(2)}</td>
                      <td>{Number(v?.SGST_AMOUNT).toFixed(2)}</td>
                      <td>{Number(v?.CGST_AMOUNT).toFixed(2)}</td>
                      <td>{v?.AAMOUNT}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={8} />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      TOTAL
                    </td>
                    <td />
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {viewItem?.products
                        .map((v) => v?.AMOUNT)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                        ?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {viewItem?.products
                        .map((v) => v?.IGST_AMOUNT)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                        ?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {viewItem?.products
                        .map((v) => v?.SGST_AMOUNT)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                        ?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {viewItem?.products
                        .map((v) => v?.CGST_AMOUNT)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                        ?.toFixed(2)}
                    </td>
                    <td style={{ fontWeight: "bold", fontSize: "15px" }}>
                      {viewItem?.products
                        .map((v) => v?.AAMOUNT)
                        .reduce((a, b) => Number(a) + Number(b), 0)
                        ?.toFixed(2)}
                    </td>
                  </tr>
                </>
              }
            ></Table>
          }
        />
      )}

      <div style={{ display: "none" }}>
        <TaxInvoice3
          empty_row_height={180 - viewItem?.products?.length * 25}
          previewRef={previewRef}
          doc_name="Defective Return"
          listdata={{
            SR_NO: viewItem?.SID,
            SR_NO_DATE: viewItem?.RETURN_DATE,
            REF_NO: viewItem?.INV_REF_NO,
            REF_NO_DATE: viewItem?.invoice_date,
            CVO_NAME: viewItem?.cvo_name,
            CVO_ADDRESS: viewItem?.PLACE_OF_SUPPLY || viewItem?.BILLING_ADDRESS,
            CVO_DISTRICT: viewItem?.B_DISTRICT,
            CVO_LOCATION: viewItem?.B_LOCATION,
            B_STATE_CODE: viewItem?.STATE_CODE,
            B_STATE_NAME: viewItem?.STATE,
            CVO_PINCODE: viewItem?.B_PINCODE,
            CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
            EMAIL: viewItem?.CVO_EMAIL,
            MOBILE: viewItem?.CVO_CONTACT,
            SHIP_CVO_NAME: viewItem?.cvo_name,
            SHIP_CVO_ADDRESS: viewItem?.S_ADDRESS || viewItem?.BILLING_ADDRESS,
            SHIP_CVO_DISTRICT: viewItem?.S_DISTRICT,
            SHIP_CVO_LOCATION: viewItem?.S_LOCATION,
            SHIP_B_STATE: viewItem?.S_STATE,
            SHIP_B_STATE_NAME: viewItem?.S_STATE_NAME,
            SHIP_CVO_PINCODE: viewItem?.S_PINCODE,
            SHIP_CVO_TIN:
              viewItem?.CVO_TIN ||
              (viewItem?.CUST_GSTIN == "0" ? "" : viewItem?.CUST_GSTIN) ||
              "NA",
          }}
          other_type={"Defective Purchase Return"}
          cols={3}
          table_header={
            <tr>
              <td> SL NO</td>
              <td> ITEM DESCRIPTION</td>
              <td> HSN</td>
              <td>QTY </td>
              <td> RATE/UNIT</td>
              <td>NET WEIGHT</td>
              <td>GST </td>
              <td style={{ textAlign: "right" }}> AMOUNT</td>
            </tr>
          }
          table_body={
            <>
              {viewItem?.products?.map((v, i) => (
                <tr key={i} className="both">
                  <td className="both">{i + 1}</td>
                  <td
                    className="both"
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      minWidth: "250px",
                    }}
                  >
                    {v?.CAT_NAME && v?.CAT_DESC
                      ? `${v?.CAT_NAME} - ${v?.CAT_DESC}`
                      : v?.PROD_BRAND && v?.PROD_NAME
                      ? `${v?.PROD_BRAND} - ${v?.PROD_NAME}`
                      : ""}
                  </td>
                  <td className="both">{v?.HSN_CODE}</td>
                  <td className="both">
                    {v?.RTN_QTY} {UnitFunction(v?.UNITS || v?.UOM, "title")}
                  </td>
                  <td className="both">{v?.UNIT_RATE}</td>
                  <td className="both">{v?.NET_WEIGHT || 0} Kgs</td>
                  <td className="both">{v?.GSTP}</td>
                  <td className="both" style={{ textAlign: "right" }}>
                    {v?.AMOUNT}
                  </td>
                </tr>
              ))}
            </>
          }
          Total_tax={ReduceFunction(viewItem?.hsn_groups, "TOTAL_AMOUNT")}
          net_total={Number(viewItem?.INV_AMT).toFixed(2)}
          taxable_amt={Number(
            ReduceFunction(viewItem?.products, "AMOUNT")
          ).toFixed(2)}
          igst={ReduceFunction(viewItem?.products, "IGST_AMOUNT")}
          cgst={ReduceFunction(viewItem?.products, "CGST_AMOUNT")}
          sgst={ReduceFunction(viewItem?.products, "SGST_AMOUNT")}
          other={"0.00"}
        />
      </div>

      {ARBPClick && (
        <ARBPurchaseList ARBPClick={ARBPClick} setARBPClick={setARBPClick} />
      )}

      {CPClick && (
        <CylinderPurchaseList CPClick={CPClick} setCPClick={setCPClick} />
      )}

      {showDeleteAlert && (
        <SmallModal
          isOpen={showDeleteAlert}
          setIsOpen={setShowDeleteAlert}
          onClick={() => handleDeleteProduct(DeleteId)}
          title={"Confirm"}
          mess={"Are you sure? You want to delete"}
        />
      )}
      {openAlert && (
        <SmallModal
          title={"Alerts"}
          isOpen={openAlert}
          setIsOpen={setOpenAlert}
          mess={alertMess}
          okbtn={true}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};
