import React, { useEffect, useState } from "react";
import PageHeading from "../../../components/PageHeading";
import {
  AddButton,
  BackButton,
  Button,
  HelpButton,
  SaveButton,
} from "../../../components/btn/Buttons";
import { motion } from "framer-motion";
import Table from "../../../components/table/Table";
import Modal from "../../../components/modal/Modal";
import Dropdown from "../../../components/inputs/Dropdown";
import { DeleteIcon, RadioNP, Text } from "../../../utils/utils";
import { RowInput } from "../../../components/inputs/Inputs";
import { createDBCAPI, master_data_api } from "../../../utils/apis";
import "../../../CSS/masterData.css";
import axios from "axios";
import SmallModal from "../../../components/modal/SmallModal";
import { toast } from "react-toastify";
import RowTxt from "../../../components/RowTxt";
import { HiOutlineArrowRight } from "react-icons/hi";
import PageLoading from "../../../components/PageLoading";
import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { Pagination2 } from "../../../components/pagination/pagination";
import NoData from "../../../components/NoData";
import store from "../../../redux/store";
import {
  get_arb_list,
  get_nc_dbc_list,
  get_service_master_list,
} from "../masterdataSlice";
import { useSelector } from "react-redux";
import { alfaNumeric } from "../../../utils/Rejex";
import { Alerts } from "../../../components/Alerts/Alerts";

const NDPackaging = () => {
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMess, setAllertMess] = useState("");
  const [masterData, setMasterData] = useState({
    Equipment: [],
    REGULATOR: [],
    SERVICE: [],
    ARB: [],
  });
  const [state, setState] = useState({
    Equipment: "",
    REGULATOR: "",
    SERVICE: "",
    ARB: "",
  });

  const [addClick, setAddClick] = useState({
    Equipment: "",
    Regulator: "",
    Service: "",
    ARB: "",
  });
  const [bodyData, setBodyData] = useState({
    bomname: "",
    bomtype: "",
    productEqu: [],
    productREGU: [],
    productSERV: [],
    productARB: [],
  });

  const [ShowValue, setShowValue] = useState("");
  const product = [
    ...bodyData?.productEqu,
    ...bodyData?.productREGU,
    ...bodyData?.productSERV,
    ...bodyData?.productARB,
  ];
  const [viewItem, setViewItem] = useState([]);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [entries, setEntries] = useState("5");
  const [pageNo, setPageNo] = useState(1);
  const [totalCount, setTotalCount] = useState();
  const [SerchInput, setSearchInput] = useState("");
  const obj = {
    pageNo: pageNo,
    entries: entries,
    setTotalCount: setTotalCount,
  };
  const { nc_dbc_list, nc_dbc_loading, arb_list, service_master_list } =
    useSelector((state) => state.masterdata);

  const { omc_data, today, user_id, emp_id, user_type } = useSelector(
    (state) => state.other
  );

  useEffect(() => {
    handleCloseandCleanData();
  }, [open]);

  const handleCloseandCleanData = () => {
    setBodyData({
      ...bodyData,
      bomname: "",
      bomtype: "",
      productARB: [],
      productEqu: [],
      productREGU: [],
      productSERV: [],
    });

    setState({
      ...state,
      Equipment: "",
      REGULATOR: "",
      SERVICE: "",
      ARB: "",
    });

    setAddClick({
      ...addClick,
      Equipment: "",
      Regulator: "",
      Service: "",
      ARB: "",
    });
    setShowValue("");
  };
  useEffect(() => {
    store.dispatch(get_arb_list());
    store.dispatch(get_service_master_list());
  }, []);
  useEffect(() => {
    fetchData();
  }, [omc_data]);

  useEffect(() => {
    store.dispatch(get_nc_dbc_list(obj));
  }, [pageNo, entries]);

  const fetchData = async () => {
    const body = new FormData();
    body.append("OMC", omc_data);
    body.append("action", "arblist");
    const data = await master_data_api(body);
    if (data?.success) {
      setMasterData({
        ...masterData,
        ARB: data?.ARB,
        Equipment: data?.Equipment,
        REGULATOR: data?.REGULATOR,
        SERVICE: service_master_list,
      });
    }
  };

  const handleView = (item) => {
    setViewItem(item);
    setOpenViewModal(true);
  };

  useEffect(() => {
    fetchData2();
  }, [ShowValue]);

  useEffect(() => {
    setMasterData({
      ...masterData,
      SERVICE: service_master_list,
    });
  }, [service_master_list]);

  const fetchData2 = () => {
    if (ShowValue == "GEN NC") {
      setBodyData({ ...bodyData, bomtype: 1 });
    } else if (ShowValue == "DBC") {
      setBodyData({ ...bodyData, bomtype: 2 });
    } else if (ShowValue == "BPL") {
      setBodyData({ ...bodyData, bomtype: 4 });
    } else if (ShowValue == "LOAN") {
      setBodyData({ ...bodyData, bomtype: 3 });
    } else if (ShowValue == "CASH") {
      setBodyData({ ...bodyData, bomtype: 5 });
    }
  };
  const validate = () => {
    const arr = [];

    var OnlyBplUrjSrv = bodyData?.productSERV?.filter(
      (s) =>
        s?.productcode == 26 ||
        s?.productcode == 27 ||
        s?.productcode == 30 ||
        s?.productcode == 31
    );

    var OnlyBplSrv = bodyData?.productSERV?.filter(
      (s) => s?.productcode == 26 || s?.productcode == 27
    );

    var OnlyUrjSrv = bodyData?.productSERV?.filter(
      (s) => s?.productcode == 30 || s?.productcode == 31
    );

    var checkifdgcc = bodyData?.productARB?.filter((s) => s?.productcode == 34);
    var checkfordgccservice = bodyData?.productARB?.filter(
      (s) => s?.productcode == 23
    );
    var checkfordgccserviceUj = bodyData?.productARB?.filter(
      (s) => s?.productcode == 31
    );
    var hasRegulator = bodyData?.productREGU?.filter(
      (s) => s.productcode == 12
    );
    var hasFtlCylinder = bodyData?.productEqu?.filter(
      (s) => s.productcode == 4
    );

    if (
      product.length == 0 &&
      bodyData?.productEqu.length == 0 &&
      (bodyData?.productREGU.length == 0 ||
        bodyData?.productARB.length == 0 ||
        bodyData?.productSERV.length == 0)
    ) {
      if (product.length <= 0) {
        arr.push("Please add data.");
      } else if (
        bodyData?.productEqu.length == 0 ||
        bodyData?.productREGU.length == 0
      ) {
        arr.push("BOM must contain atleast one EQUIPMENT And others..");
      }
    } else {
      var atlOneEq = bodyData?.productEqu?.length;
      if (atlOneEq == 0) arr.push("Add alteast one Equipment.");

      if (atlOneEq.length > 1)
        arr.push("You can add only One Equipment for a single BOM.");

      var OnlyOneReg = bodyData?.productREGU?.length;

      if (OnlyOneReg.length > 1)
        arr.push("You can add only One Regulator for a single BOM.");

      if (bodyData?.productREGU?.length >= 1 && bodyData?.bomtype == 2) {
        arr.push(
          "For DBC, there cannot be any Regulator.Please verify it and save"
        );
      }
      if (
        (bodyData?.bomtype == 1 || bodyData?.bomtype == 2) &&
        OnlyBplUrjSrv?.length > 0
      ) {
        arr.push(
          "BPL  and Ujjwala CONNECTION Charges are not allowed for NC and DBC type Connections"
        );
      }

      if (
        bodyData?.bomtype == 3 ||
        bodyData?.bomtype == 4 ||
        bodyData?.bomtype == 5
      ) {
        if (
          (bodyData?.productEqu[0]?.productcode != 1 ||
            bodyData?.productEqu[0]?.productcode != 3) &&
          bodyData?.productREGU[0]?.productcode != 10
        ) {
          if (
            bodyData?.productEqu[0]?.productcode != 1 ||
            bodyData?.productEqu[0]?.productcode != 3
          ) {
            arr.push(
              "You can only have DOMESTIC-5 Kg SUB and DOMESTIC-14.2 Kg NS CYLINDER For BPL and Ujjwala. "
            );
          }
          if (bodyData?.productREGU.length == 0) {
            arr.push("Please add Regulator-SC TYPE For Ujjwala and BPL.");
          } else if (bodyData?.productREGU[0]?.productcode != 10) {
            arr.push(
              "You can only have Regulator-SC TYPE For BPL and Ujjwala. "
            );
          }
        } else if (
          bodyData?.bomtype == 3 ||
          bodyData?.bomtype == 4 ||
          bodyData?.bomtype == 5
        ) {
          if (
            bodyData?.productEqu[0]?.deposite != "NO" ||
            bodyData?.productREGU[0]?.deposite != "NO"
          ) {
            arr.push(
              "You can only have DEPOSIT REQUIRED No For Equipment and Regulator in Ujjwala and Bpl."
            );
          }
        }
        if (
          OnlyUrjSrv.length == 0 &&
          (bodyData?.bomtype == 3 || bodyData?.bomtype == 5)
        ) {
          arr.push(
            "You can either have Installation Charges For Ujjwala or DGCC Book Charges For Ujjwala"
          );
        }
        if (OnlyBplSrv.length == 0 && bodyData?.bomtype == 4) {
          arr.push(
            "You can either have Admin Charges For BPL or DGCC Book Admin Charges For BPL"
          );
        }
        // var OnlyUjwalaARBSur = bodyData?.productARB?.filter(
        //   (s) => s?.productcode == 14
        // );
        var OnlyUjwalaARBSur = bodyData?.productARB?.filter(
          (s) => s?.sr_name == "SURAKSHA"
        );

        var OnlyUjwalaARBKIT = bodyData?.productARB?.filter(
          (s) => s?.productcode == 16
        );
        var OnlyUjwalaARBLIGH = bodyData?.productARB?.filter(
          (s) => s?.productcode == 15
        );
        var OnlyUjwalaARBOTH = bodyData?.productARB?.filter(
          (s) => s?.productcode == 17
        );

        if (!(OnlyUjwalaARBSur.length >= 1)) {
          arr.push("You should have SURAKSHA For Ujjwala and Bpl.");
        } else if (
          OnlyUjwalaARBLIGH.length >= 1 ||
          OnlyUjwalaARBKIT.length >= 1 ||
          OnlyUjwalaARBOTH.length >= 1
        ) {
          arr.push(
            "You can have only STOVE along with SURAKSHA For Ujjwala and Bpl"
          );
        }

        if (
          checkifdgcc.length > 0 &&
          checkfordgccservice.length < 1 &&
          checkfordgccserviceUj.length < 1
        ) {
          arr.push(
            "Add DGCC BOOK Services for the selected DGCC BOOK ARB product."
          );
        } else if (
          (checkfordgccservice.length > 0 ||
            checkfordgccserviceUj.length > 0) &&
          checkifdgcc.length < 1
        ) {
          arr.push(
            "Add DGCC BOOK ARB product for the selected DGCC BOOK Services ."
          );
        }
        if (hasFtlCylinder?.length > 0 && hasRegulator?.length == 0) {
          arr.push(
            "You can add only REGULATOR-FTL along with COMMERCIAL 5KG FTL CYLINDER."
          );
        }
      }
    }
    if (
      bodyData?.productEqu?.length > 0 &&
      bodyData?.productEqu[0].CAT_NAME == "DOMESTIC" &&
      bodyData?.productEqu[0].quantity > 2
    ) {
      arr.push(
        "Domestic Cylinder QUANTITY can be either 1 or 2 for a single BOM"
      );
    } else if (
      bodyData?.productEqu?.length > 0 &&
      bodyData?.productEqu[0].CAT_NAME == "COMMERCIAL" &&
      bodyData?.productEqu[0].quantity > 999
    ) {
      arr.push("Commercial Cylinder QUANTITY must be less than 1000.");
    } else if (
      bodyData?.productEqu?.length > 0 &&
      bodyData?.productEqu[0].quantity == 0
    ) {
      arr.push("Cylinder Quantity is not be 0");
    }

    const hotplateCount = bodyData?.productSERV?.filter(
      (v) => v?.productcode == 22
    );
    const installchargeCount = bodyData?.productSERV?.filter(
      (v) => v?.productcode == 25
    );

    if (hotplateCount.length > 0 && installchargeCount.length > 0) {
      arr.push(
        "Select either HOTPLATE INSP or INSTALLATION CHARGES for NC type connection. "
      );
    }
    return arr;
  };

  const handleSubmit = async () => {
    // return;
    const data = validate();
    if (data?.length == 0) {
      if (
        bodyData?.bomname.trim().length !== 0 &&
        bodyData?.bomname.trim().length > 4 &&
        bodyData?.bomtype !== "" &&
        product.every(
          (item) =>
            item && item?.deposite !== undefined && item?.deposite !== ""
        )
      ) {
        setShowLoader(true);
        // setShowLoader(false);
        // return;
        const body = new FormData();
        body.append("action", "create");
        body.append("bomname", bodyData?.bomname.trim());
        body.append("bomtype", bodyData?.bomtype);
        body.append("agencyid", user_id);

        product.forEach((item) => {
          if (item) {
            body.append("productcode[]", item?.productcode);
            body.append("quantity[]", item?.quantity);
            body.append("deposit[]", item?.deposite);
          }
        });
        if (emp_id) {
          body.append("emp_code", emp_id);
        }

        const response = await axios.post(createDBCAPI, body);
        setShowLoader(false);
        if (response?.data.success === true) {
          store.dispatch(get_nc_dbc_list(obj));
          toast("Data Saved Successfully", {
            position: "top-center",
          });
          setBodyData({
            ...bodyData,
            bomname: "",
            bomtype: "",
            productARB: [],
            productEqu: [],
            productREGU: [],
            productSERV: [],
          });

          setState({
            ...state,
            Equipment: "",
            REGULATOR: "",
            SERVICE: "",
            ARB: "",
          });

          setAddClick({
            ...addClick,
            Equipment: "",
            Regulator: "",
            Service: "",
            ARB: "",
          });

          setOpen(false);
        } else if (response?.data.success === false) {
          setAllertMess(response?.data?.message);
          setOpenAlert(true);
        }
      } else {
        if (!product.every((item) => item !== undefined)) {
          setAllertMess("Product Selection is Required");
          setOpenAlert(true);
        } else if (bodyData?.bomname.trim().length === 0) {
          setAllertMess("Please enter data for the BOM NAME");
          setOpenAlert(true);
        } else if (bodyData?.bomname.trim().length <= 4) {
          setAllertMess("Packaging Name must be at least 5 characters");
          setOpenAlert(true);
        } else if (bodyData?.bomtype === "") {
          setAllertMess("Connection Type is Required");
          setOpenAlert(true);
        } else if (
          !product.every(
            (item) =>
              item && item?.deposite !== undefined && item?.deposite !== ""
          )
        ) {
          setAllertMess("Deposite Selection is Required");
          setOpenAlert(true);
        }
      }
    } else {
      setAllertMess(<Alerts data={data} />);
      setOpenAlert(true);
    }
  };

  const handleDeleteProduct = async (deleteid) => {
    setShowLoader(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("agencyid", user_id);
    body.append("id", deleteid);
    if (emp_id) {
      body.append("emp_code", emp_id);
    }

    const response = await axios.post(createDBCAPI, body);
    setShowLoader(false);
    if (response.data.success === true) {
      setDeleteAlert(false);
      toast("Product Deleted", {
        position: "top-center",
      });
      if (nc_dbc_list?.length < 2) {
        setPageNo(pageNo - 1);
        store.dispatch(
          get_nc_dbc_list({
            pageNo: pageNo - 1,
            entries: entries,
            setTotalCount: setTotalCount,
          })
        );
      } else {
        store.dispatch(get_nc_dbc_list(obj));
      }
    } else {
      setShowLoader(false);
    }
  };

  return (
    <div className="container">
      <PageHeading
        title={"BOM/NC PACKAGING PAGE"}
        right={
          <>
            <AddButton onClick={() => setOpen(true)} />
            <BackButton onClick={() => navigate(-1)} />
            <HelpButton
              link={[
                "https://youtu.be/KFp-sqfwZQM",
                "https://youtu.be/81WsX2sclRU",
              ]}
            />
          </>
        }
      />

      {nc_dbc_loading ? (
        <PageLoading />
      ) : nc_dbc_list?.length == 0 ? (
        <NoData />
      ) : (
        <>
          <Pagination2
            top={true}
            search={false}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
          <div className="grid">
            {nc_dbc_list?.map((v, i) => (
              <div className="card" key={i}>
                <div className="card-heading">{v?.Bom_name} </div>
                <RowTxt title={"Connection Type"} value={v?.bom_type} />
                <RowTxt title={"Created By"} value={v?.CREATED_NAME || "-"} />
                <div
                  style={{
                    display: "flex",
                    justifyContent:
                    user_type != "admin" && v?.EMPLOYEE_CODE != emp_id ? "flex-end" : "space-between",
                    marginTop: "1rem",
                  }}
                >
                  {user_type == "admin" || v?.EMPLOYEE_CODE == emp_id ? (
                    <DeleteIcon
                      onClick={() => {
                        setDeleteAlert(true);
                        setDeleteId(v?.id);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 1.05 }}
                    className="flex aic gap10 jce cursor-pointer"
                  >
                    <div className="view-btn" onClick={() => handleView(v)}>
                      View
                    </div>
                    <HiOutlineArrowRight
                      size={20}
                      color="#1b64af"
                      onClick={() => handleView(v)}
                    />
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
          <Pagination2
            bottom={true}
            totalCount={totalCount}
            SerchInput={SerchInput}
            setSearchInput={setSearchInput}
            pageNo={pageNo}
            setPageNo={setPageNo}
            entries={entries}
            setEntries={setEntries}
          />
        </>
      )}
      {open && (
        <Modal
          width={"90vh"}
          isOpen={open}
          setIsOpen={setOpen}
          // title={"NC/DBC PACKAGING"}
          title={"BOM/NC PACKAGING"}
          body={
            <div>
              <div className="flex aic gap10 nd-item">
                <div style={{ flex: 1 }}>
                  <Dropdown
                    onChange={(e) =>
                      setState({ ...state, Equipment: e.target.value })
                    }
                    options={
                      <>
                        <option hidden>SELECT</option>
                        {masterData?.Equipment?.filter(
                          (v, i) => v?.CAT_NAME != "REGULATOR"
                        )?.map((item, index) => (
                          <option value={item?.ID} key={index}>
                            {item?.CAT_NAME} - {item?.CAT_DESC}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <Button
                    onClick={() => {
                      setAddClick({ ...addClick, Equipment: "Equipment" });
                      if (state.Equipment === "") {
                        setAllertMess(
                          "Please select Cylinders and then add again"
                        );
                        setOpenAlert(true);
                      } else {
                        const filtData = masterData?.Equipment.filter(
                          (item) => item?.ID == state.Equipment
                        );
                        if (bodyData?.productEqu?.length == 0) {
                          setBodyData({
                            ...bodyData,
                            productEqu: [
                              {
                                productcode: filtData[0]?.ID,
                                CAT_NAME: filtData[0]?.CAT_NAME,
                                deposite: "",
                                quantity: 1,
                                productname: filtData[0]?.CAT_DESC,
                                edit: true,
                              },
                            ],
                          });
                        } else {
                          setAllertMess(
                            "You can add only One Equipment for a single BOM."
                          );
                          setOpenAlert(true);
                        }
                      }
                    }}
                    title={"ADD EQUIPMENT"}
                    mt={10}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Dropdown
                    onChange={(e) =>
                      setState({ ...state, REGULATOR: e.target.value })
                    }
                    options={
                      <>
                        <option hidden>SELECT</option>
                        {masterData?.REGULATOR.map((item, index) => (
                          <option value={item?.ID} key={index}>
                            {item?.CAT_DESC}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <Button
                    title={"ADD REGULATOR"}
                    mt={10}
                    onClick={() => {
                      setAddClick({ ...addClick, Regulator: "REGULATOR" });
                      if (state?.REGULATOR === "") {
                        setAllertMess(
                          "Please select regulators and then add again"
                        );
                        setOpenAlert(true);
                      } else {
                        const filtData = masterData?.REGULATOR.filter(
                          (item) => item?.ID == state.REGULATOR
                        );

                        if (bodyData?.productREGU?.length == 0) {
                          setBodyData({
                            ...bodyData,
                            productREGU: [
                              {
                                productcode: filtData[0]?.ID,
                                deposite: "",
                                quantity: 1,
                                productname: filtData[0]?.CAT_DESC,
                                CAT_NAME: filtData[0]?.CAT_NAME,
                                deposite:
                                  filtData[0]?.CAT_DESC == "FTL" ? "NO" : "",
                              },
                            ],
                          });
                        } else {
                          setAllertMess(
                            "You can add only One Regulators for a single BOM."
                          );
                          setOpenAlert(true);
                        }
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Dropdown
                    onChange={(e) => {
                      const data = service_master_list?.filter(
                        (v) => v?.ID == e.target.value
                      );
                      setState({
                        ...state,
                        SERVICE:
                          data[0]?.default == "true" ? data[0]?.PROD_CODE : "",
                        ser_id: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option hidden>SELECT</option>
                        {service_master_list?.map((item, index) => (
                          <option value={item?.ID} key={index}>
                            {item?.CAT_DESC}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <Button
                    title={"ADD SERVICE"}
                    mt={10}
                    onClick={() => {
                      if (bodyData?.bomtype !== "") {
                        setAddClick({ ...addClick, Service: "SERVICE" });
                        if (state?.SERVICE === "" && state?.ser_id == "") {
                          setAllertMess(
                            "Please select Services and then add again"
                          );
                          setOpenAlert(true);
                          return;
                        } else if (
                          (bodyData?.bomtype == 1 || bodyData?.bomtype == 2) &&
                          (state.SERVICE == 26 ||
                            state.SERVICE == 27 ||
                            state.SERVICE == 30 ||
                            state.SERVICE == 31)
                        ) {
                          setAllertMess(
                            "BPL AND Ujjwala CONNECTION Charges are not allowed for NC and DBC type Connections."
                          );
                          setOpenAlert(true);
                          return;
                        } else if (
                          bodyData?.bomtype == 2 &&
                          (state.SERVICE == 23 || state.SERVICE == 27)
                        ) {
                          setAllertMess(
                            "DGCC BOOK Charges are not allowed for DBC type of Connection."
                          );
                          setOpenAlert(true);
                          return;
                        } else if (
                          (bodyData?.bomtype == 3 || bodyData?.bomtype == 5) &&
                          !(state.SERVICE == 30 || state.SERVICE == 31)
                        ) {
                          setAllertMess(
                            "Please select only Ujjwala Charges for Ujjwala type of connection."
                          );
                          setOpenAlert(true);
                          return;
                        } else if (
                          bodyData?.bomtype == 4 &&
                          !(state.SERVICE == 26 || state.SERVICE == 27)
                        ) {
                          setAllertMess(
                            "Please select only BPL Charges for BPL type of connection."
                          );
                          setOpenAlert(true);

                          return;
                        }
                        else {
                          const filtData = service_master_list?.filter(
                            (item) =>
                              item?.ID == state?.ser_id ||
                              item?.PROD_CODE == state?.SERVICE
                          );
                          const serName = filtData[0]?.CAT_DESC;
                          const filtService = bodyData?.productSERV?.some(
                            (item) => item?.productname == serName
                          );
                          if (!filtService) {
                            const newItem = {
                              productcode:
                                filtData[0]?.PROD_CODE || filtData[0]?.ID,
                              deposite: "NA",
                              quantity: 1,
                              productname: serName,
                            };
                            setBodyData((prevData) => ({
                              ...prevData,
                              productSERV: [...prevData.productSERV, newItem],
                            }));
                          } else {
                            setAllertMess(
                              "Please select Services and then add again"
                            );
                            setOpenAlert(true);
                          }
                        }
                      } else {
                        setAllertMess("Select Connection Type");
                        setOpenAlert(true);
                      }
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Dropdown
                    onChange={(e) => {
                      const data = arb_list?.filter(
                        (v) => v?.ID == e.target.value
                      );
                      setState({
                        ...state,
                        ARB: data[0]?.PROD_CODE,
                        arb_id: e.target.value,
                      });
                    }}
                    options={
                      <>
                        <option hidden>SELECT</option>
                        {arb_list.map((item, index) => (
                          <option value={item?.ID} key={index}>
                            {item?.category_description} - {item?.PROD_BRAND} -{" "}
                            {item?.PROD_NAME}
                          </option>
                        ))}
                      </>
                    }
                  />
                  <Button
                    title={"ADD ARB ITEM"}
                    mt={10}
                    onClick={() => {
                      setAddClick({ ...addClick, ARB: "ARB" });
                      if (state?.ARB === "") {
                        setAllertMess(
                          "Please select BLPG/ARB/NFR Products and then add again"
                        );
                        setOpenAlert(true);
                      } else {
                        const filtData = arb_list?.filter(
                          (item) =>
                            item?.ID == state?.arb_id &&
                            item?.PROD_CODE == state?.ARB
                        );

                        const serName = `${filtData[0]?.category_description} - ${filtData[0]?.PROD_BRAND} - ${filtData[0]?.PROD_NAME}`;
                        const filtService = bodyData?.productARB?.some(
                          (item) => item?.productname === serName
                        );
                        const sr_name = filtData[0]?.category_description;

                        if (!filtService) {
                          const newItem = {
                            productcode: filtData[0]?.ID,
                            deposite: "NA",
                            quantity: 1,
                            productname: serName,
                            sr_name: sr_name,
                          };
                          setBodyData((prevData) => ({
                            ...prevData,
                            productARB: [...prevData.productARB, newItem],
                          }));
                        } else {
                          setAllertMess("Product already selected");
                          setOpenAlert(true);
                        }
                      }
                    }}
                  />
                </div>
              </div>
              <div className="df aic jcsb rdio-item" style={{ marginTop: 20 }}>
                <RowInput
                  value={bodyData?.bomname}
                  onChange={(e) => {
                    if (e.target.value.length <= 25) {
                      setBodyData({
                        ...bodyData,
                        bomname: alfaNumeric.test(e.target.value)
                          ? e.target.value
                          : "",
                      });
                    }
                  }}
                  placeholder="NC/DBC PACKAGING NAME"
                  label={"PACKAGING NAME :"}
                />
                <div className="df aic ">
                  <div>
                    <Text color={"#6f6f6f"} fs={"13px"} fw={500} flex={1}>
                      CONNECTION TYPE :
                    </Text>
                  </div>
                  <RadioNP
                    setShowValue={setShowValue}
                    ShowValue={ShowValue}
                    title={"GEN NC"}
                    onClick={() => setBodyData({ ...bodyData, bomtype: 1 })}
                  />
                  <RadioNP
                    setShowValue={setShowValue}
                    ShowValue={ShowValue}
                    title={"DBC"}
                    onClick={() => setBodyData({ ...bodyData, bomtype: 2 })}
                  />
                  <RadioNP
                    setShowValue={setShowValue}
                    ShowValue={ShowValue}
                    title={"BPL"}
                    onClick={() => setBodyData({ ...bodyData, bomtype: 4 })}
                  />
                </div>
                <div className="df aic ">
                  <div>
                    <Text color={"#6f6f6f"} fs={"13px"} fw={500} flex={1}>
                      UJJWALA :
                    </Text>
                  </div>
                  <RadioNP
                    setShowValue={setShowValue}
                    ShowValue={ShowValue}
                    title={"LOAN"}
                    onChange={() => setBodyData({ ...bodyData, bomtype: 3 })}
                  />
                  <RadioNP
                    setShowValue={setShowValue}
                    ShowValue={ShowValue}
                    title={"CASH"}
                    onChange={() => setBodyData({ ...bodyData, bomtype: 5 })}
                  />
                </div>
              </div>
              <div>
                <Table
                  headData={[
                    "PRODUCT",
                    "QUANTITY",
                    "DEPOSIT REQUIRED",
                    "ACTIONS",
                  ]}
                  body={
                    <>
                      {state.Equipment !== "" &&
                      addClick.Equipment === "Equipment" ? (
                        <tr className="MD-tr">
                          <td className="MD-td">
                            <input
                              className="MD-td-div MD-input MD-td-div"
                              value={`${bodyData?.productEqu[0]?.CAT_NAME} - ${bodyData?.productEqu[0]?.productname}`}
                              disabled={true}
                            />
                          </td>
                          <td className="MD-td">
                            <input
                              className="MD-td-div MD-input"
                              value={bodyData?.productEqu[0]?.quantity}
                              onChange={(e) => {
                                if (e.target.value?.length < 6) {
                                  setBodyData((prevBodyData) => ({
                                    ...prevBodyData,
                                    productEqu: [
                                      {
                                        ...prevBodyData?.productEqu[0],
                                        quantity:
                                          /^\d+$/.test(e.target.value) ||
                                          e.target.value == ""
                                            ? Number(e.target.value)
                                            : "",
                                      },
                                    ],
                                  }));
                                }
                              }}
                            />
                          </td>
                          <td className="MD-td">
                            <select
                              className="MD-td-div"
                              style={{ fontSize: "14px", fontWeight: "350" }}
                              onChange={(e) =>
                                setBodyData((prevBodyData) => ({
                                  ...prevBodyData,
                                  productEqu: [
                                    {
                                      ...prevBodyData?.productEqu[0],
                                      deposite: e.target.value,
                                    },
                                  ],
                                }))
                              }
                            >
                              <option hidden>SELECT</option>
                              <option value="YES">YES</option>
                              <option value="NO">NO</option>
                            </select>
                          </td>
                          <td className="MD-td">
                            <DeleteIcon
                              onClick={() => {
                                setAddClick({ ...addClick, Equipment: "" });
                                setBodyData((prevBodyData) => ({
                                  ...prevBodyData,
                                  productEqu: [],
                                }));
                              }}
                            />
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {state?.REGULATOR !== "" &&
                      addClick?.Regulator == "REGULATOR" ? (
                        <tr className="MD-tr">
                          <td className="MD-td">
                            <input
                              className="MD-td-div MD-input MD-td-div"
                              // value={bodyData?.productREGU[0]?.productname}
                              value={`${bodyData?.productREGU[0]?.CAT_NAME} - ${bodyData?.productREGU[0]?.productname}`}
                              disabled={true}
                            />
                          </td>
                          <td className="MD-td">
                            <input
                              className="MD-td-div MD-input"
                              value={bodyData?.productREGU[0]?.quantity}
                              disabled={true}
                              onChange={(e) => {
                                if (e.target.value?.length < 6) {
                                  setBodyData((prevBodyData) => ({
                                    ...prevBodyData,
                                    productREGU: [
                                      {
                                        ...prevBodyData?.productREGU[0],
                                        quantity: /^\d+$/.test(e.target.value)
                                          ? Number(e.target.value)
                                          : "",
                                      },
                                    ],
                                  }));
                                }
                              }}
                            />
                          </td>
                          <td className="MD-td">
                            <select
                              className="MD-td-div"
                              value={bodyData?.productREGU[0]?.deposite}
                              style={{ fontSize: "14px", fontWeight: "350" }}
                              disabled={
                                bodyData?.productREGU[0]?.productname == "FTL"
                              }
                              onChange={(e) =>
                                setBodyData((prevBodyData) => ({
                                  ...prevBodyData,
                                  productREGU: [
                                    {
                                      ...prevBodyData?.productREGU[0],
                                      deposite: e.target.value,
                                    },
                                  ],
                                }))
                              }
                            >
                              <option hidden>SELECT</option>
                              <option value="YES">YES</option>
                              <option value="NO">NO</option>
                            </select>
                          </td>
                          <td className="MD-td">
                            <DeleteIcon
                              onClick={() => {
                                setAddClick({ ...addClick, Regulator: "" });
                                setBodyData((prevBodyData) => ({
                                  ...prevBodyData,
                                  productREGU: [],
                                }));
                              }}
                            />
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {(state?.SERVICE != "" || state?.ser_id != "") &&
                      addClick?.Service == "SERVICE" &&
                      bodyData?.productSERV?.length > 0 ? (
                        <>
                          {bodyData?.productSERV.map((item, i) => (
                            <tr className="MD-tr" key={i}>
                              <td className="MD-td">
                                <input
                                  className="MD-td-div MD-input MD-td-div"
                                  value={item?.productname}
                                  disabled={true}
                                />
                              </td>
                              <td className="MD-td">
                                <input
                                  className="MD-td-div MD-input"
                                  value={item?.quantity}
                                  disabled={true}
                                  onChange={(e) => {
                                    if (e.target.value?.length < 6) {
                                      setBodyData((prevBodyData) => ({
                                        ...prevBodyData,
                                        productSERV:
                                          prevBodyData?.productSERV.map(
                                            (product, index) =>
                                              index === i
                                                ? {
                                                    ...product,
                                                    quantity: /^\d+$/.test(
                                                      e.target.value
                                                    )
                                                      ? Number(e.target.value)
                                                      : "",
                                                  }
                                                : product
                                          ),
                                      }));
                                    }
                                  }}
                                />
                              </td>
                              <td className="MD-td">
                                <select
                                  className="MD-td-div"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "350",
                                  }}
                                  onChange={(e) => {
                                    setBodyData((prevBodyData) => ({
                                      ...prevBodyData,
                                      productSERV:
                                        prevBodyData?.productSERV.map(
                                          (product, index) =>
                                            index === i
                                              ? {
                                                  ...product,
                                                  deposite: e.target.value,
                                                }
                                              : product
                                        ),
                                    }));
                                  }}
                                >
                                  <option value="NA">NOT APPLICABLE</option>
                                </select>
                              </td>
                              <td className="MD-td">
                                <DeleteIcon
                                  onClick={() => {
                                    const filtData =
                                      bodyData?.productSERV.filter(
                                        (i) =>
                                          i.productcode != item?.productcode
                                      );
                                    setBodyData((prevBodyData) => ({
                                      ...prevBodyData,
                                      productSERV: filtData,
                                    }));
                                    if (bodyData?.productSERV.length == 0) {
                                      setAddClick({ ...addClick, Service: "" });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                      {state.ARB !== "" &&
                      addClick.ARB == "ARB" &&
                      bodyData?.productARB.length > 0 ? (
                        <>
                          {bodyData?.productARB.map((item, i) => (
                            <tr className="MD-tr" key={i}>
                              <td className="MD-td">
                                <input
                                  className="MD-td-div MD-input MD-td-div"
                                  value={item?.productname}
                                  disabled={true}
                                />
                              </td>
                              <td className="MD-td">
                                <input
                                  className="MD-td-div MD-input"
                                  value={item?.quantity}
                                  disabled={true}
                                  onChange={(e) => {
                                    if (e.target.value?.length < 6) {
                                      setBodyData((prevBodyData) => ({
                                        ...prevBodyData,
                                        productARB:
                                          prevBodyData?.productARB.map(
                                            (product, index) =>
                                              index === i
                                                ? {
                                                    ...product,
                                                    quantity: /^\d+$/.test(
                                                      e.target.value
                                                    )
                                                      ? Number(e.target.value)
                                                      : "",
                                                  }
                                                : product
                                          ),
                                      }));
                                    }
                                  }}
                                />
                              </td>
                              <td className="MD-td">
                                <select
                                  className="MD-td-div"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "350",
                                  }}
                                  onChange={(e) => {
                                    setBodyData((prevBodyData) => ({
                                      ...prevBodyData,
                                      productARB: prevBodyData?.productARB.map(
                                        (product, index) =>
                                          index === i
                                            ? {
                                                ...product,
                                                deposite: e.target.value,
                                              }
                                            : product
                                      ),
                                    }));
                                  }}
                                >
                                  <option value="NA">NOT APPLICABLE</option>
                                </select>
                              </td>
                              <td className="MD-td">
                                <DeleteIcon
                                  onClick={() => {
                                    const filtData =
                                      bodyData?.productARB.filter(
                                        (i) =>
                                          i.productcode != item?.productcode
                                      );
                                    setBodyData((prevBodyData) => ({
                                      ...prevBodyData,
                                      productARB: filtData,
                                    }));
                                    if (bodyData?.productARB.length == 0) {
                                      setAddClick({ ...addClick, ARB: "" });
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  }
                />
                <div style={{ width: "180px", margin: "10px 0" }}>
                  <SaveButton onClick={handleSubmit} class1="save-btn" />
                </div>
              </div>
            </div>
          }
        />
      )}

      {openAlert && (
        <SmallModal
          isOpen={openAlert}
          setIsOpen={setOpenAlert}
          title={"alert"}
          mess={alertMess}
          okbtn={setOpenAlert}
        />
      )}

      {openViewModal && (
        <Modal
          isOpen={openViewModal}
          setIsOpen={setOpenViewModal}
          title={viewItem?.Bom_name}
          body={
            <Table
              mt={0.1}
              headData={["PRODUCT DETAILS", "QUANTITY", "DEPOSIT REQUIRED"]}
              body={viewItem?.items.map((item, i) => (
                <tr key={i}>
                  <td>
                    {item?.prod_name == "DOMESTIC" ||
                    item?.prod_name == "REGULATOR" ||
                    item?.prod_name == "COMMERCIAL"
                      ? `${item?.prod_name} - ${item?.prod_desc}`
                      : item?.prod_name == "ARB"
                      ? `${item?.prod_desc} - ${item?.prod_brand} - ${item?.prod_brand}`
                      : item?.prod_name == "SERVICE"
                      ? `${item?.prod_name} - ${item?.prod_desc}`
                      : `SERVICES ${item?.service_name} `}
                  </td>
                  <td>{item?.qty}</td>
                  <td>{item?.deposit_req}</td>
                </tr>
              ))}
            ></Table>
          }
        />
      )}
      {deleteAlert && (
        <SmallModal
          isOpen={deleteAlert}
          setIsOpen={setDeleteAlert}
          onClick={() => handleDeleteProduct(DeleteId)}
          title={"Confirm"}
          mess={"Are you sure? You want to delete"}
        />
      )}
      {showLoader && <Loader />}
    </div>
  );
};

export default NDPackaging;
